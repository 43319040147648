import { TMDB_KEY } from '../EnvironmentConfig';
import { tmdbAPI } from './axiosConfigs';
import { defineCancelApiObject } from './axiosUtils';

export const getMovieById = {
  async get(id, cancel = false) {
    const response = await tmdbAPI.request({
      url: `/movie/${id}?api_key=${TMDB_KEY}&language=en-US`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getTrendingMovieDaily = {
  async get(cancel = false) {
    const response = await tmdbAPI.request({
      url: `/trending/movie/day?api_key=${TMDB_KEY}&language=en-US`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getTrendingMovieWeekly = {
  async get(cancel = false) {
    const response = await tmdbAPI.request({
      url: `/trending/movie/week?api_key=${TMDB_KEY}&language=en-US`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getNowPlayingMovie = {
  async get(cancel = false) {
    const response = await tmdbAPI.request({
      url: `/movie/now_playing?api_key=${TMDB_KEY}&language=en-US`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getMovieRecommendation = {
  async get(id, cancel = false) {
    const response = await tmdbAPI.request({
      url: `/movie/${id}/recommendations?api_key=${TMDB_KEY}&language=en-US&page=1&page_size=8`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getMovieTrailer = {
  async get(id, cancel = false) {
    const response = await tmdbAPI.request({
      url: `/movie/${id}/videos?api_key=${TMDB_KEY}&language=en-US`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(getMovieById);
