import React, { useEffect } from 'react';
import './home.css';

export default function Home() {
  useEffect(() => {
    if (
      window.location.pathname === '/admin' &&
      !window.location.search.includes('reload=true')
    ) {
      window.location.search = 'reload=true'; // Add a query parameter to the URL to indicate the reload
    }
  }, []);

  return (
    <div className="home">
      <h1>WELCOME TO ADMIN DASHBOARD</h1>
    </div>
  );
}
