import { useEffect, useState } from 'react';
import { getNowPlayingMovie } from '../../../util/apis/MovieAPI';
import { getPlayingThisWeekTV } from '../../../util/apis/TvAPI';
import Button from '../../Button';

export default function WatchTab() {
  const [content, setContent] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let promises = [
          getNowPlayingMovie.get(true),
          getPlayingThisWeekTV.get(true),
        ];
        const [movieData, tvData] = await Promise.all(promises);
        const shows = tvData.results.splice(0, 5);
        const movies = movieData.results.splice(0, 5);
        const mergedArray = movies.concat(shows);
        setContent(mergedArray);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="grid place-items-center lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-3 gap-y-8 gap-x-3 px-3 md:px-20 lg:px-40 sm:max-h-hidden overflow-hidden">
        {content.map((item, id) => (
          <a
            href={item.title ? `/rate/movie/${item.id}` : `/rate/tv/${item.id}`}
            key={id}
            className="relative mb-10 lg:w-64"
          >
            {item.title ? (
              <span className="absolute top-5 left-0 -rotate-45 bg-secondary text-white py-0.5 px-4 rounded-lg text-xs">
                Movie
              </span>
            ) : (
              <span className="absolute top-4 left-0 -rotate-45 bg-secondary text-white py-0.5 px-4 rounded-lg text-xs">
                TV
              </span>
            )}
            <img
              src={`https://image.tmdb.org/t/p/original/${item.poster_path}`}
              className="rounded-t-xl border-2 aspect-poster w-full"
              loading="lazy"
            />
            <div className="bg-white rounded-b-xl absolute w-full p-1 text-nowrap overflow-hidden whitespace-nowrap text-ellipsis">
              {item.title ? item.title : item.name}
            </div>
          </a>
        ))}
      </div>
      <div className="flex justify-center py-5">
        <a href="/advance-search/movies">
          <Button variant="button" type="button">
            Find More
          </Button>
        </a>
      </div>
    </>
  );
}
