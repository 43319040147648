import { RAWGIO_KEY } from '../EnvironmentConfig';
import { gameAPI } from './axiosConfigs';
import { defineCancelApiObject } from './axiosUtils';

export const getGameById = {
  async get(id, cancel = false) {
    const response = await gameAPI.request({
      url: `/games/${id}?&key=${RAWGIO_KEY}`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getXboxGames = {
  async get(cancel = false) {
    const response = await gameAPI.request({
      url: `/games?&key=${RAWGIO_KEY}&parent_platforms=3&ordering=-released&dates=2019-01-01,2023-12-01&metacritic=70,100`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getPlaystationGames = {
  async get(cancel = false) {
    const response = await gameAPI.request({
      url: `/games?&key=${RAWGIO_KEY}&parent_platforms=2&ordering=-released&dates=2020-01-01,2024-01-01&metacritic=70,100`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getRecentGames = {
  async get(cancel = false) {
    const response = await gameAPI.request({
      url: `/games?ordering=-released,metacritic&dates=2024-01-01,2024-05-01&key=${RAWGIO_KEY}`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getSimilarGamesByPublisher = {
  async get(publisher, cancel = false) {
    const response = await gameAPI.request({
      url: `/games?&publishers=${publisher}&page=1&page_size=4&key=${RAWGIO_KEY}`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getGameScreenshotById = {
  async get(id, cancel = false) {
    const response = await gameAPI.request({
      url: `/games/${id}/screenshots?&key=${RAWGIO_KEY}`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(getGameById);
