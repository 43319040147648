import React from 'react';
import '../../assets/stylesheets/partners.css';

function Partners() {
  return (
    <div className="background1">
      <div className="float-container">
        <div className="container1">
          <div className="one">
            <h1>Partners</h1>
          </div>

          <div className="info_div">
            <div className="partner_text">
              <p>
                With the support of our esteemed partners, KnowQuest is on a
                mission to transform the consumer feedback landscape by
                introducing groundbreaking solutions.
              </p>
              <p>
                Our upcoming portfolio includes the world's first-ever Academic
                Feedback Platform, an advanced Movie and TV Show Rating System,
                and an unparalleled Video Game Rating System. Together with our
                partners, we are setting a new benchmark in feedback
                experiences. We remain dedicated to expanding our feedback
                categories and safeguarding user privacy by ensuring no personal
                data is collected.
              </p>
              <p>
                We are committed to constantly expanding our feedback categories
                and prioritizing user privacy by ensuring no personal
                information is collected. Join us as we pave the way for a
                better, more comprehensive, and user-centric consumer feedback
                platform.
              </p>
            </div>
          </div>
        </div>

        <div className="partner_div">
          <h2 className="course_title">
            <a style={{ 'font-size': '20px' }} href="https://thebigleaf.com/">
              The Big Leaf
            </a>
          </h2>
          <hr />
          <div className="logo_image">
            <img
              src="https://knowquest.net/storage/files/TBL%20LOGO%20Med-Small.png"
              alt="Red leaf"
            />
          </div>
          <div className="p">
            <div className="comp_desc">
              <p>
                Big Leaf's goal is to enhance client value, market position, and
                competitive advantage through effective and timely strategies.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="contributors">
        <h1 className="contributor_title">Contributors</h1>
        <div className="grid-container">
          {/* 1 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/York-University-150x150.jpg"
                  alt="York University"
                />
                <p className="uni_name">York Univeristy</p>
              </div>
            </div>
          </div>
          {/* 2 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Western-University-150x150.jpg"
                  alt="Western University"
                />
                <p className="uni_name">York Univeristy</p>
              </div>
            </div>
          </div>

          {/* 3 */}

          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/University-of-Toronto-150x150.jpg"
                  alt="Univeristy of Toronto"
                />
                <p className="uni_name">Univeristy of Toronto</p>
              </div>
            </div>
          </div>

          {/* 4 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/University-of-Southern-California-150x150.jpg"
                  alt="University of Southern California"
                />
                <p className="uni_name">University of Southern California</p>
              </div>
            </div>
          </div>

          {/* 5 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/University-of-Southampton-150x150.jpg"
                  alt="University of Southampton"
                />
                <p className="uni_name">University of Southampton</p>
              </div>
            </div>
          </div>

          {/* 6 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/University-of-Guelph-150x150.jpg"
                  alt="University of Guelph"
                />
                <p className="uni_name">University of Guelph</p>
              </div>
            </div>
          </div>

          {/* 6 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/University-Canada-West-150x150.jpg"
                  alt="University Canada West"
                />
                <p className="uni_name">University Canada West</p>
              </div>
            </div>
          </div>

          {/* 6 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/University-at-Albany-150x150.jpg"
                  alt="University at Albany"
                />
                <p className="uni_name">University at Albany</p>
              </div>
            </div>
          </div>

          {/* 7 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/TMU-150x150.jpg"
                  alt="Toronto Metropolitan University"
                />
                <p className="uni_name">Toronto Metropolitan University</p>
              </div>
            </div>
          </div>

          {/* 8 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Seneca-College-150x150.jpg"
                  alt="Seneca College"
                />
                <p className="uni_name">Seneca College</p>
              </div>
            </div>
          </div>

          {/* 9 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Southern-Alberta-Institute-of-Technology-150x150.jpg"
                  alt="SAIT"
                />
                <p className="uni_name">SAIT</p>
              </div>
            </div>
          </div>

          {/* 10 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/RMIT-University-150x150.jpg"
                  alt="RMIT"
                />
                <p className="uni_name">RMIT</p>
              </div>
            </div>
          </div>

          {/* 11 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Rice-University-150x150.jpg"
                  alt="Rice University"
                />
                <p className="uni_name">Rice University</p>
              </div>
            </div>
          </div>

          {/* 12 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Purdue-150x150.jpg"
                  alt="Purdue University"
                />
                <p className="uni_name">Purdue University</p>
              </div>
            </div>
          </div>

          {/* 13 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Palm-Beach-Atlantic-University-150x150.jpg"
                  alt="Palm Beack Atlantic"
                />
                <p className="uni_name">Palm Beack Atlantic</p>
              </div>
            </div>
          </div>

          {/* 14 */}

          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Mohawk-College-150x150.jpg"
                  alt="Mohawk College"
                />
                <p className="uni_name">Mohawk College</p>
              </div>
            </div>
          </div>

          {/* 15 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Miami-University-150x150.jpg"
                  alt="Miami University"
                />
                <p className="uni_name">Miami University</p>
              </div>
            </div>
          </div>

          {/* 16 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/McMaster-University-150x150.jpg"
                  alt="McMaster University"
                />
                <p className="uni_name">McMaster University</p>
              </div>
            </div>
          </div>

          {/* 17 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/McGill-University-150x150.jpg"
                  alt="Universite McGill"
                />
                <p className="uni_name">Universite McGill</p>
              </div>
            </div>
          </div>

          {/* 18 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Maskwacis-Cultural-College-150x150.jpg"
                  alt="Maskwacis Cultural College"
                />
                <p className="uni_name">Maskwacis Cultural College</p>
              </div>
            </div>
          </div>

          {/* 19 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Humber-College-150x150.jpg"
                  alt="Humber College"
                />
                <p className="uni_name">Humber College</p>
              </div>
            </div>
          </div>

          {/* 20 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Georgian-College-150x150.jpg"
                  alt="Georgian College"
                />
                <p className="uni_name">Georgian College</p>
              </div>
            </div>
          </div>

          {/* 21 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/George-Brown-150x150.jpg"
                  alt="George Brown College"
                />
                <p className="uni_name">George Brown College</p>
              </div>
            </div>
          </div>

          {/* 22 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/FDU-150x150.jpg"
                  alt="Fairleigh Dickinson University"
                />
                <p className="uni_name">Fairleigh Dickinson University</p>
              </div>
            </div>
          </div>

          {/* 23 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Fanshawe-College-150x150.jpg"
                  alt="Fanshawe College"
                />
                <p className="uni_name">Fanshawe College</p>
              </div>
            </div>
          </div>

          {/* 24 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/ECPI-University-150x150.jpg"
                  alt="ECPI University"
                />
                <p className="uni_name">ECPI University</p>
              </div>
            </div>
          </div>

          {/* 25 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Conestoga-College-150x150.jpg"
                  alt="Conestoga College"
                />
                <p className="uni_name">Conestoga College</p>
              </div>
            </div>
          </div>

          {/* 26 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/CUE-150x150.jpg"
                  alt="Concordia University of Edmonton"
                />
                <p className="uni_name">Concordia University of Edmonton</p>
              </div>
            </div>
          </div>

          {/* 27 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Concordia-University-150x150.jpg"
                  alt="Concordia University"
                />
                <p className="uni_name">Concordia University</p>
              </div>
            </div>
          </div>

          {/* 28 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/College-of-New-Caledonia-150x150.jpg"
                  alt="College of New Caledonia"
                />
                <p className="uni_name">College of New Caledonia</p>
              </div>
            </div>
          </div>

          {/* 29 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Capella-University-150x150.jpg"
                  alt="Capella University"
                />
                <p className="uni_name">Capella University</p>
              </div>
            </div>
          </div>

          {/* 30 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Cambrian-College-150x150.jpg"
                  alt="Cambrian College"
                />
                <p className="uni_name">Cambrian College</p>
              </div>
            </div>
          </div>

          {/* 31 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/BVC-150x150.jpg"
                  alt="Bow Valey College"
                />
                <p className="uni_name">Bow Valey College</p>
              </div>
            </div>
          </div>

          {/* 32 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Brock-U-150x150.jpg"
                  alt="Brock University"
                />
                <p className="uni_name">Brock University</p>
              </div>
            </div>
          </div>

          {/* 33 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/iCube-for-KQ-150x150.jpg"
                  alt="iCube"
                />
                <p className="uni_name">iCube</p>
              </div>
            </div>
          </div>

          {/* 34 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Riipen-150x150.jpg"
                  alt="Riipen.com"
                />
                <p className="uni_name">Riipen.com</p>
              </div>
            </div>
          </div>

          {/* 35 */}
          <div className="grid-item">
            <div className="uni_container">
              <div className="uni_image">
                <img
                  className="uni_logo"
                  src="https://knowquest.net/main/wp-content/uploads/2023/04/Spark-150x150.jpg"
                  alt="Spark IECI"
                />
                <p className="uni_name">Spark IECI</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
