import axios from 'axios';

export const gameAPI = axios.create({
  baseURL: 'https://api.rawg.io/api',
});

export const tmdbAPI = axios.create({
  baseURL: 'https://api.themoviedb.org/3',
});

const errorHandler = (error) => {
  const statusCode = error.response?.status;

  if (error.code === 'ERR_CANCELLED') {
    console.log(error);

    return Promise.resolve();
  }

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

// registering the custom error handler to the
// "api" axios instance
gameAPI.interceptors.response.use(undefined, (error) => errorHandler(error));
