import $ from 'jquery';
import { useState } from 'react';
import Button from '../Button.jsx';
import EatTab from './home/EatTab';
import LearnTab from './home/LearnTab';
import LifeTab from './home/LifeTab';
import PlayTab from './home/PlayTab';
import RandomTab from './home/RandomTab';
import WatchTab from './home/WatchTab';
export default function HomePageTabs() {
  const [selectedButton, setSelectedButton] = useState();
  const [activeTab, setActiveTab] = useState('');

  const handleContent = (value) => {
    setActiveTab(value);
  };

  return (
    <>
      <div className="px-8 md:px-28 sm:mt-10">
        <ul className="flex items-center justify-evenly text-white font-DM border-b-2 border-border rounded-sm text-lg">
          <li className="p-3 md:p-5">
            <Button
              type="button"
              variant={'tab'}
              isActive={activeTab === 'watch'}
              onClick={(e) => handleContent('watch')}
            >
              Watch
            </Button>
          </li>
          <li className="p-3 md:p-5">
            <Button
              type="button"
              variant={'tab'}
              isActive={activeTab === 'eat'}
              onClick={(e) => handleContent('eat')}
            >
              Eat
            </Button>
          </li>
          <li className="p-3 md:p-5">
            <Button
              type="button"
              variant={'tab'}
              isActive={activeTab === 'life'}
              onClick={(e) => handleContent('life')}
            >
              Life
            </Button>
          </li>
          <li className="p-3 md:p-5">
            <Button
              type="button"
              variant={'tab'}
              isActive={activeTab === 'learn'}
              onClick={(e) => handleContent('learn')}
            >
              Learn
            </Button>
          </li>
          <li className="p-3 md:p-5">
            <Button
              type="button"
              variant={'tab'}
              isActive={activeTab === 'play'}
              onClick={(e) => handleContent('play')}
            >
              Play
            </Button>
          </li>
        </ul>
      </div>
      <div className="bg-darkblue text-center">
        <div className="pt-8">
          {activeTab === 'watch' ? (
            <WatchTab />
          ) : activeTab === 'eat' ? (
            <EatTab />
          ) : activeTab === 'learn' ? (
            <LearnTab />
          ) : activeTab === 'life' ? (
            <LifeTab />
          ) : activeTab === 'play' ? (
            <PlayTab />
          ) : (
            <RandomTab />
          )}
        </div>
      </div>
    </>
  );
}
