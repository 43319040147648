import { useEffect, useState } from 'react';
import {
  getAllAppMethod,
  getAllCollisionCompanies,
  getAllLevel,
  getAllSemester,
  getAllSemesterYear,
  getAllSubject,
} from '../../util/DatabaseUtil';

// TODO: File needs to be reworked, very hard to read and calls DB too much

export function ContextQuestions({
  cat,
  updateRating,
  checkContext,
  submitButton,
  formData,
}) {
  const [subject, setSubject] = useState([]);
  const [semester, setSemester] = useState([]);
  const [semesterYear, setSemesterYear] = useState([]);
  const [level, setLevel] = useState([]);
  const [appmethod, setAppMethod] = useState([]);
  const [companies, setCompanies] = useState([]);

  // TODO: Possibly use Promise All instead of this
  useEffect(() => {
    const fetchAllContextQuestions = async () => {
      try {
        const semesterData = await getAllSemester();
        const yearData = await getAllSemesterYear();
        const subjectData = await getAllSubject();
        const levelData = await getAllLevel();
        const appMethodData = await getAllAppMethod();
        const collisionData = await getAllCollisionCompanies();

        semesterData.forEach((index) => {
          setSemester((semester) => [...semester, index.semesterName]);
        });
        yearData.forEach((index) => {
          setSemesterYear((semesterYear) => [...semesterYear, index.year]);
        });
        subjectData.forEach((index) => {
          setSubject((subjects) => [...subjects, index.description]);
        });
        levelData.forEach((index) => {
          setLevel((level) => [...level, index.descA]);
        });
        appMethodData.forEach((index) => {
          setAppMethod((appmethod) => [...appmethod, index.am_type]);
        });

        // TODO:Uncomment when ready
        collisionData.forEach((index) => {
          setCompanies((appmethod) => [...appmethod, index.company]);
        });
      } catch (err) {
        console.log('Error fetching All Data', err);
      }
    };

    fetchAllContextQuestions();
  }, []);

  const handleSearchBarFocus = () => {
    return 'block';
  };

  const handleSearchBarBlur = () => {
    return 'hidden';
  };

  // TODO: Find a better way of doing
  const categoryContextQuestions = {
    school: [
      {
        type: 'select',
        label: 'Subject',
        options: subject,
        databaseName: 'subject',
      },
      {
        type: 'select',
        label: 'Year',
        options: semesterYear,
        databaseName: 'year',
      },
      {
        type: 'select',
        label: 'Semester',
        options: semester,
        databaseName: 'semester',
      },
    ],
    class: [
      {
        type: 'select',
        label: 'Subject',
        options: subject,
        databaseName: 'subject',
      },
      {
        type: 'select',
        label: 'Year',
        options: semesterYear,
        databaseName: 'year',
      },
      {
        type: 'select',
        label: 'Level',
        options: level,
        databaseName: 'level',
      },
      {
        type: 'select',
        label: 'Semester',
        options: semester,
        databaseName: 'semester',
      },
    ],
    textbook: [
      { type: 'input', label: 'ISBN', databaseName: 'resourceISBN' },
      {
        type: 'select',
        label: 'Subject',
        options: subject,
        databaseName: 'subject',
      },
      {
        type: 'select',
        label: 'Level',
        options: level,
        databaseName: 'level',
      },
      {
        type: 'select',
        label: 'Year',
        options: semesterYear,
        databaseName: 'year',
      },
      {
        type: 'select',
        label: 'Semester',
        options: semester,
        databaseName: 'semester',
      },
      {
        type: 'select',
        label: 'Cost',
        options: [
          'Cost Not Available',
          '$1 - $9.99',
          '$10 - $29.99',
          '$30 - $49.99',
          '$50 - $99.99',
          '$100 - $149',
          '$150 - $199',
          '$200+',
          'Free',
        ],
        databaseName: 'cost',
      },
    ],
    onlineresource: [
      { type: 'input', label: 'URL', databaseName: 'resourceUrl' },
      {
        type: 'select',
        label: 'Subject',
        options: subject,
        databaseName: 'subject',
      },
      {
        type: 'select',
        label: 'Level',
        options: level,
        databaseName: 'level',
      },
      {
        type: 'select',
        label: 'Year',
        options: semesterYear,
        databaseName: 'year',
      },
      {
        type: 'select',
        label: 'Semester',
        options: semester,
        databaseName: 'semester',
      },
    ],
    onlinelearning: [
      {
        type: 'select',
        label: 'Subject',
        options: subject,
        databaseName: 'subject',
      },
      {
        type: 'select',
        label: 'Year',
        options: semesterYear,
        databaseName: 'year',
      },
      {
        type: 'select',
        label: 'Semester',
        options: semester,
        databaseName: 'semester',
      },
    ],
    misdis: [
      { type: 'input', label: 'URL', databaseName: 'resourceUrl' },
      {
        type: 'select',
        label: 'Subject',
        options: subject,
        databaseName: 'subject',
      },
      {
        type: 'select',
        label: 'Level',
        options: level,
        databaseName: 'level',
      },
      {
        type: 'select',
        label: 'Year',
        options: semesterYear,
        databaseName: 'year',
      },
      {
        type: 'select',
        label: 'Semester',
        options: semester,
        databaseName: 'semester',
      },
    ],
    jobprospect: [
      {
        type: 'select',
        label: 'Year',
        options: semesterYear,
        databaseName: 'year',
      },
      {
        type: 'select',
        label: 'Semester',
        options: semester,
        databaseName: 'semester',
      },
    ],
    applicationprocess: [
      { type: 'select', label: 'Application Method', options: appmethod },
      {
        type: 'select',
        label: 'Year',
        options: semesterYear,
        databaseName: 'year',
      },
      {
        type: 'select',
        label: 'Cost',
        options: [
          'Cost Not Available',
          '$1 - $9.99',
          '$10 - $29.99',
          '$30 - $49.99',
          '$50 - $99.99',
          '$100 - $149',
          '$150 - $199',
          '$200+',
          'Free',
        ],
        databaseName: 'cost',
      },
    ],
    mentalhealth: [
      {
        type: 'select',
        label: 'Subject',
        options: subject,
        databaseName: 'subject',
      },
      {
        type: 'select',
        label: 'Year',
        options: semesterYear,
        databaseName: 'year',
      },
      {
        type: 'select',
        label: 'Semester',
        options: semester,
        databaseName: 'semester',
      },
    ],
    article: [
      { type: 'input', label: 'URL', databaseName: 'resourceUrl' },
      {
        type: 'select',
        label: 'Subject',
        options: subject,
        databaseName: 'subject',
      },
      {
        type: 'select',
        label: 'Level',
        options: level,
        databaseName: 'level',
      },
      {
        type: 'select',
        label: 'Year',
        options: semesterYear,
        databaseName: 'year',
      },
      {
        type: 'select',
        label: 'Semester',
        options: semester,
        databaseName: 'semester',
      },
    ],
    movie: [
      {
        type: 'select',
        label: 'Platform',
        options: [
          'YouTube',
          'Tubi',
          'Hulu',
          'Disney+',
          'Apple TV+',
          'Netflix',
          'Amazon Prime',
          'HBO Max',
          'Starz',
          'Other',
        ],
        databaseName: 'platform',
      },
    ],
    tv: [
      {
        type: 'select',
        label: 'Platform',
        options: [
          'YouTube',
          'Tubi',
          'Hulu',
          'Disney+',
          'Netflix',
          'Amazon Prime',
          'HBO Max',
          'Starz',
          'Other',
        ],
        databaseName: 'platform',
      },
    ],
    game: [
      {
        type: 'select',
        label: 'Platform',
        options: [
          'PC',
          'Xbox',
          'PlayStation',
          'Nintendo',
          'Apple Macintosh',
          'Linux',
        ],
        databaseName: 'platform',
      },
    ],
    collision: [
      {
        type: 'search',
        label: 'Company',
        options: companies, //pull from database
        databaseName: 'company',
      },
    ],
  };

  const contextQuestions = categoryContextQuestions[cat] || [];

  // Determines if all context questions have been filled
  if (submitButton) {
    submitButton.addEventListener('click', () => {
      const contextQuestionNodeList = [];
      contextQuestions.forEach((question, idx) => {
        contextQuestionNodeList.push(document.getElementById(idx));
      });
      let hasEmptyValue = false;
      for (let i = 0; i < contextQuestionNodeList.length; i++) {
        if (contextQuestionNodeList[i].value === '') {
          hasEmptyValue = true;
          contextQuestionNodeList[i].style.border = '2px solid red';
        }
      }

      if (hasEmptyValue) {
        checkContext(false);
      } else {
        checkContext(true);
      }
    });
  }

  return (
    <>
      {companies && (
        <>
          {contextQuestions.map((question, idx) => (
            <div
              className="flex flex-col text-center justify-center items-center p-10 gap-4"
              key={idx}
            >
              <label className="">
                <h2>
                  {question.label} <span className="text-red-900">*</span>
                </h2>
              </label>
              {question.type === 'select' && (
                <div className="text-black">
                  <select
                    className="w-56 rounded-lg"
                    defaultValue=""
                    id={idx}
                    onChange={(e) =>
                      updateRating({ [question.databaseName]: e.target.value })
                    }
                  >
                    <option value="" disabled>
                      Select...
                    </option>

                    {question.options.map((options, idx) => (
                      <option key={idx} value={options.description}>
                        {options}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {question.type === 'input' && (
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control rating-form-input-value input-lg"
                    id="Url"
                    onChange={(e) =>
                      updateRating({ [question.databaseName]: e.target.value })
                    }
                  />
                </div>
              )}
              {question.type === 'search' && (
                <div className="relative self-start w-full sm:w-1/2 mb-4">
                  <input
                    type="text"
                    className="rounded-lg text-black w-full sm:w-96 p-2"
                    id={idx}
                    onChange={(e) =>
                      updateRating({ [question.databaseName]: e.target.value })
                    }
                    value={formData.company}
                    placeholder="Enter Company name..."
                    // onFocus={() => handleSearchBarFocus()}
                    // onBlur={() => handleSearchBarBlur()}
                  />
                  <div className="bg-white absolute max-h-96 overflow-y-scroll rounded-lg w-full sm:w-96 z-50">
                    {question.options
                      .filter((item) => {
                        if (!formData.company) {
                          return;
                        } else {
                          const searchTerm = formData.company.toLowerCase();
                          const company = item.toLowerCase();
                          return (
                            searchTerm &&
                            company.startsWith(searchTerm) &&
                            company !== searchTerm
                          );
                        }
                      })
                      .map((option, idx) => (
                        <div
                          onClick={() =>
                            updateRating({
                              [question.databaseName]: option,
                            })
                          }
                          className="flex flex-col items-center justify-center text-black cursor-pointer p-2 hover:bg-gray-100"
                          key={idx}
                        >
                          {option}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
}
