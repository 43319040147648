import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/stylesheets/about.css';

const TITLE = 'KnowQuest';

function About(props) {
  window.location.href = 'https://knowquest.net/about-us/';
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="container-fluid  about-container">
        <div className="row about-info-row">
          <div className="col-lg-5  about-info-col">
            <h1 className="knowquest-intro">
              About KnowQuest
              <br />
            </h1>
            <span className="text-muted sub-text-intro ">
              KnowQuest was born when a Ryerson University Professor (Dr.
              Agostino Menna PhD) and graduate student (Mr. Rahi Tajzadeh CMC
              MScM) put their heads together to find opportunities in higher
              education to add value to students. Working together, and getting
              input from students and professors from across Canada, they
              recognized that there is a large gap in higher education that
              costs publishers billions in lost savings, and professors hours of
              time: Academic Feedback. Students and Professors did not have a
              third-party, unbiased platform to give their feedback on
              textbooks, resources, classes, online learning experience and
              school. Dr. Menna and Mr. Tajzadeh, along with their amazing team
              of talented professionals, designed KnowQuest to meet the needs of
              students and professors thanks to more than 25 projects and 100
              interactions with students via the Riipen.com platform! .
            </span>
          </div>
        </div>
        <div className="row cards-about-data-info">
          <div className="card-group col-lg-12">
            <div className="row main-card-about">
              <div className="card col-lg-3 aboutcard">
                <img
                  className="card-img-top img-class"
                  src="https://www.knowquest.net/images/Vision.jpg"
                  alt=""
                />
                <div className="card-body">
                  <h3 className="card-title card-vision-intro"> Our Vision</h3>
                  <span className="text-muted sub-text-intro">
                    To be the default academic feedback platform in the world.
                  </span>
                </div>
              </div>
              <div className="card col-lg-3 aboutcard">
                <img
                  className="card-img-top img-class"
                  src="https://www.knowquest.net/images/Mission.jpg"
                  alt=""
                />
                <div className="card-body">
                  <h3 className="card-title card-vision-intro"> Our Mission</h3>
                  <span className="text-muted sub-text-intro">
                    We bridge the gap between professors, students, schools and
                    publishers.
                  </span>
                </div>
              </div>
              <div className="card col-lg-3 aboutcard">
                <img
                  className="card-img-top img-class"
                  src="https://www.knowquest.net/images/Values.jpg"
                  alt=""
                />
                <div className="card-body">
                  <h3 className="card-title card-vision-intro"> Our Values</h3>
                  <span className="text-muted sub-text-intro">
                    Passion: Loving what we do and doing what we love.
                    Integrity: We’re committed to honest, morale business
                    practices. Diversity: We celebrate our differences, as they
                    give us strength and perspective.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row our-team-row">
          <div className="col">
            <div className="text-center">
              <h3 className="team-title">OUR TEAM</h3>
            </div>
          </div>
        </div>
        <div className="row team-row">
          <div className="col-lg-4 col-md-4 team-col ">
            <div className="about-team-member-card card">
              <div className="team-img-class">
                <img
                  className="card-img-top-team"
                  src="https://www.knowquest.net/images/RST2.jpg"
                  alt=""
                />
              </div>
              <div className="card-body team-member-body ">
                <h3 className="card-title card-vision-intro">
                  {' '}
                  Mr. Rahi Tajzadeh
                </h3>
                <div className="text-muted team-text-role">CEO</div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/tajzadeh/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 team-col ">
            <div className="about-team-member-card card">
              <div className="team-img-class">
                <img
                  className="card-img-top-team"
                  src="https://www.knowquest.net/images/AgostinoMenna1.jpg"
                  alt=""
                />
              </div>
              <div className="card-body team-member-body ">
                <h3 className="card-title card-vision-intro">
                  {' '}
                  Dr. Agostino Menna PhD
                </h3>
                <div className="text-muted team-text-role">
                  Founder, Board Director
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/agostinomenna/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 team-col ">
            <div className="about-team-member-card card">
              <div className="team-img-class">
                <img
                  className="card-img-top-team"
                  src="https://www.knowquest.net/images/bahareh.jpg"
                  alt=""
                />
              </div>
              <div className="card-body team-member-body ">
                <h3 className="card-title card-vision-intro">Bahareh Attari</h3>
                <div className="text-muted team-text-role">Web Developer</div>
                <div className="text-muted team-text-role">
                  <a href="" className="text-black linkedIn-logo mx-4">
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 team-col ">
            <div className="about-team-member-card card">
              <div className="team-img-class">
                <img
                  className="card-img-top-team"
                  src="https://www.knowquest.net/images/Ayoob.jpg"
                  alt=""
                />
              </div>
              <div className="card-body team-member-body ">
                <h3 className="card-title card-vision-intro">Ayoob Faraji</h3>
                <div className="text-muted team-text-role">
                  Lead Web Developer
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/ayoob-faraji-291a8ba4/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 team-col ">
            <div className="about-team-member-card card">
              <div className="team-img-class">
                <img
                  className="card-img-top-team"
                  src="https://www.knowquest.net/images/Luke.jpg"
                  alt=""
                />
              </div>
              <div className="card-body team-member-body ">
                <h3 className="card-title card-vision-intro">Luke Guevara</h3>
                <div className="text-muted team-text-role">
                  Marketing Cordinator
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/luke-guevara-7999b4170/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 team-col ">
            <div className="about-team-member-card card">
              <div className="team-img-class">
                <img
                  className="card-img-top-team"
                  src="https://www.knowquest.net/images/lou.jpeg"
                  alt=""
                />
              </div>
              <div className="card-body team-member-body ">
                <h3 className="card-title card-vision-intro"> Lou Vescio</h3>
                <div className="text-muted team-text-role">
                  Education Expert
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/louvescio/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 team-col ">
            <div className="about-team-member-card card">
              <div className="team-img-class">
                <img
                  className="card-img-top-team"
                  src="https://www.knowquest.net/images/Jiri.jpg"
                  alt=""
                />
              </div>
              <div className="card-body team-member-body ">
                <h3 className="card-title card-vision-intro">Jiri Fiali</h3>
                <div className="text-muted team-text-role">Data Security</div>
                <div className="text-muted team-text-role">
                  <a href="" className="text-black linkedIn-logo mx-4">
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 team-col ">
            <div className="about-team-member-card card">
              <div className="team-img-class">
                <img
                  className="card-img-top-team"
                  src="https://www.knowquest.net/images/Amrish.jpg"
                  alt=""
                />
              </div>
              <div className="card-body team-member-body ">
                <h3 className="card-title card-vision-intro">Amrish Manna</h3>
                <div className="text-muted team-text-role">Operations</div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/amrish-manna/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 team-col ">
            <div className="about-team-member-card card">
              <div className="team-img-class">
                <img
                  className="card-img-top-team"
                  src="https://www.knowquest.net/images/Neil.jpg"
                  alt=""
                />
              </div>
              <div className="card-body team-member-body ">
                <h3 className="card-title card-vision-intro"> Neil Cosby</h3>
                <div className="text-muted team-text-role">
                  Business Development
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/neil-cosby-chrl-mba-08130310/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 team-col ">
            <div className="about-team-member-card card">
              <div className="team-img-class">
                <img
                  className="card-img-top-team"
                  src="https://www.knowquest.net/images/Ron.jpg"
                  alt=""
                />
              </div>
              <div className="card-body team-member-body ">
                <h3 className="card-title card-vision-intro"> Ron McMeeken</h3>
                <div className="text-muted team-text-role">
                  Accounting, Finance
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/ron-mcmeeken-89369526/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row our-team-row">
          <div className="col">
            <div className="text-center">
              <h3 className="team-title">Developed By</h3>
              Jan 2023 - May 2023 Teams
            </div>
          </div>
        </div>
        <div className="row team-row">
          <div className="col-lg">
            <div className="about-team-member-card card">
              <div className="card-body team-developer-body ">
                <h3 className="card-title card-vision-intro"> Harman Mann</h3>
                <div className="text-muted team-text-role">
                  Team Lead - Full Stack Developer
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/harman-s-mann/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row team-row">
          <div className="col-lg-3 col-md-3 team-devloper-col ">
            <div className="about-team-member-card card">
              <div className="card-body team-developer-body ">
                <h3 className="card-title card-vision-intro">
                  {' '}
                  Murtuza Barodawala{' '}
                </h3>
                <div className="text-muted team-text-role">
                  Software Developer
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/murtuzab786/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 team-devloper-col ">
            <div className="about-team-member-card card">
              <div className="card-body team-developer-body ">
                <h3 className="card-title card-vision-intro">
                  {' '}
                  Rhea Christian{' '}
                </h3>
                <div className="text-muted team-text-role">
                  Software Developer
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/rheachristian/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 team-devloper-col ">
            <div className="about-team-member-card card">
              <div className="card-body team-developer-body ">
                <h3 className="card-title card-vision-intro">
                  {' '}
                  Tushar Chavan{' '}
                </h3>
                <div className="text-muted team-text-role">
                  Full Stack Developer
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/tushar-chavan-6b310385/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 team-devloper-col ">
            <div className="about-team-member-card card">
              <div className="card-body team-developer-body ">
                <h3 className="card-title card-vision-intro"> Abid Khawaja</h3>
                <div className="text-muted team-text-role">
                  Front End Developer
                </div>
                {/* <div className="text-muted team-text-role">
                  <a
                    href=""
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 team-devloper-col ">
            <div className="about-team-member-card card">
              <div className="card-body team-developer-body ">
                <h3 className="card-title card-vision-intro">
                  {' '}
                  Mason DiGaudio
                </h3>
                <div className="text-muted team-text-role">
                  Full Stack Developer
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/mason-digaudio/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 team-devloper-col ">
            <div className="about-team-member-card card">
              <div className="card-body team-developer-body ">
                <h3 className="card-title card-vision-intro"> Zach Weller</h3>
                <div className="text-muted team-text-role">
                  Full Stack Developer
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/zachary-weller-3070a0257/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 team-devloper-col ">
            <div className="about-team-member-card card">
              <div className="card-body team-developer-body ">
                <h3 className="card-title card-vision-intro">
                  {' '}
                  Jagjyot Singh{' '}
                </h3>
                <div className="text-muted team-text-role">
                  Software Developer
                </div>
                {/* <div className="text-muted team-text-role">
                  <a
                    href=""
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 team-devloper-col ">
            <div className="about-team-member-card card">
              <div className="card-body team-developer-body ">
                <h3 className="card-title card-vision-intro"> Victor </h3>
                <div className="text-muted team-text-role">
                  Software Developer
                </div>
                {/* <div className="text-muted team-text-role">
                  <a
                    href=""
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 team-devloper-col ">
            <div className="about-team-member-card card">
              <div className="card-body team-developer-body ">
                <h3 className="card-title card-vision-intro">
                  {' '}
                  Jordan Tapiero{' '}
                </h3>
                <div className="text-muted team-text-role" />
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/jordantapiero/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row our-team-row">
          <div className="col">
            <div className="text-center">
              <h3 className="team-title">Previous Team:</h3>
            </div>
          </div>
        </div>
        <div className="row team-row">
          <div className="col-lg-3 col-md-3 team-devloper-col ">
            <div className="about-team-member-card card">
              <div className="card-body team-developer-body ">
                <h3 className="card-title card-vision-intro"> Yash Mehta</h3>
                <div className="text-muted team-text-role">
                  Full Stack Developer
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/yashmehta76/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 team-devloper-col ">
            <div className="about-team-member-card card">
              <div className="card-body team-developer-body ">
                <h3 className="card-title card-vision-intro"> Vishal Gami</h3>
                <div className="text-muted team-text-role">
                  Full Stack Developer
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/vishalgami/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 team-devloper-col ">
            <div className="about-team-member-card card">
              <div className="card-body team-developer-body ">
                <h3 className="card-title card-vision-intro"> Vrushank Amin</h3>
                <div className="text-muted team-text-role">
                  Full Stack Developer
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/vrushank-amin/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 team-devloper-col ">
            <div className="about-team-member-card card">
              <div className="card-body team-developer-body ">
                <h3 className="card-title card-vision-intro"> Namrata Kaur</h3>
                <div className="text-muted team-text-role">
                  Database Developer
                </div>
                <div className="text-muted team-text-role">
                  <a
                    href="https://www.linkedin.com/in/namratakaur/"
                    className="text-black linkedIn-logo mx-4"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
