import React from 'react';
import { twMerge } from 'tailwind-merge';

export default function Button({
  variant,
  className,
  isActive,
  children,
  ...props
}) {
  const classNames = (...classes) => {
    return classes.join(' ');
  };

  return (
    <button
      className={twMerge(
        classNames(
          'flex items-center justify-center py-2 font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed',
          variant === 'tab' && 'text-faded',
          variant === 'tab' &&
            isActive &&
            'border-b-4 border-secondary text-white',
          variant === 'button' && 'bg-secondary rounded-lg px-4 text-white',
        ),
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
}
