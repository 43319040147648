import Button from '../../components/Button.jsx';
import Searchbar from '../../components/Searchbar.jsx';
import Tabs from '../../components/tabs/HomePageTabs.jsx';
export default function Home() {
  return (
    <div className="">
      <div className="grid gap-10 font-montserrat sm:grid-cols-1">
        <div className="grid place-items-center p-8 md:p-24 gap-10">
          <div className="font-bold text-white text-4xl md:text-6xl text-center">
            <span className="text-secondary">Trusted</span> Reviews made Simple
          </div>
          <Searchbar />
          <a href="/advance-search/movies">
            <Button type="button" variant="button">
              Advanced Search
            </Button>
          </a>
        </div>
        <div className="text-center text-white grid gap-4">
          <div className="text-2xl md:text-4xl font-medium">
            Discover Trending
          </div>
          <div className="text-faded text-sm md:text-xl font-hind">
            Explore a mix of trending reviews from each category below
          </div>
        </div>
      </div>
      <Tabs />
    </div>
  );
}
