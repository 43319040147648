import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { getAvgRating } from '../../util/EntertainmentUtil';

function RatingProgressBar({ kqRating, questions }) {
  const percentage =
    getAvgRating(kqRating, questions) !== 0
      ? Math.abs(getAvgRating(kqRating, questions) / 2).toFixed(1)
      : 0;

  return (
    <>
      {percentage && (
        <div style={{ width: 125, height: 125 }}>
          <CircularProgressbar
            value={`${percentage}`}
            text={`${percentage}`}
            maxValue={10}
            minValue={0}
            background={true}
            styles={{
              path: {
                stroke: 'var(--secondary)',
              },
              trail: {
                stroke: 'var(--faded)',
              },
              text: {
                fill: 'var(--secondary)',
                fontSize: '2rem',
                fontWeight: 'normal',
                fontFamily: 'montserrat',
              },
              background: {
                fill: 'var(--primary)',
              },
            }}
          />
        </div>
      )}
    </>
  );
}

export default RatingProgressBar;
