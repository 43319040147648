import axios from 'axios';
import $ from 'jquery';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { isValidUrl } from '../util/SearchUtil';

// TODO: Completely remake this component, it is too messy and unreadable
function HeaderSearchbar() {
  const [MovieSearchResults, setMovieSearchResults] = useState(true);
  const [tvSearchResults, setTvSearchResults] = useState(true);
  const [gameSearchResults, setGameSearchResults] = useState(true);
  const [urlSearchResults, setUrlSearchResults] = useState(false);
  const [movieMappedArr, setMovieMappedArr] = useState([]);
  const [tvMappedArr, setTvMappedArr] = useState([]);
  const [gameMappedArr, setGameMappedArr] = useState([]);
  const [urlMappedArr, setUrlMappedArr] = useState([]);
  const [userSearchText, setUserSearchText] = useState('');
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const tmdbApiKey = process.env.REACT_APP_TMDB_API_KEY;
  const videoGamesApiKey = process.env.REACT_APP_RAWGIO_API_KEY;
  const currentPage = 1;
  const videoGameOrderingCriteria = '-playtime, -added'; // Default is to sort by most added by players
  // Changes what data is being saved in our "search" text useState
  const handleUserSearchTextChange = (incomingUserData) => {
    setUserSearchText(incomingUserData.target.value);
    handleSearch(incomingUserData.target.value);

    if (incomingUserData.target.value.length > 0) {
      $('#searchbar-dropdown').css('visibility', 'visible');
    } else {
      $('#searchbar-dropdown').css('visibility', 'hidden');
    }
  };

  // Searches several APIs with keywords user inserts
  const handleSearch = (userSearchText) => {
    const videoGamesSearchURL = `https://api.rawg.io/api/games?token&key=${videoGamesApiKey}&ordering=${videoGameOrderingCriteria}&exclude_stores=9&search=${userSearchText}&page_size=50`;
    const movieSearchUrl = `https://api.themoviedb.org/3/search/movie?api_key=${tmdbApiKey}&query=${userSearchText}&include_adult=false&language=enUS&page=${currentPage}`;
    const tvSearchUrl = `https://api.themoviedb.org/3/search/tv?api_key=${tmdbApiKey}&query=${userSearchText}&include_adult=false&language=enUS&page=${currentPage}`;

    if (typeof currentTimeout === 'number') {
      clearTimeout(currentTimeout);
      setCurrentTimeout(null);
    }

    if (isValidUrl(userSearchText)) {
      setMovieSearchResults(false);
      setTvSearchResults(false);
      setGameSearchResults(false);
      setUrlSearchResults(true);

      const urlArr = [
        { url: userSearchText, ratingCategory: 'Article' },
        { url: userSearchText, ratingCategory: 'Online Resource' },
        {
          url: userSearchText,
          ratingCategory: 'Misinformation/Disinformation',
        },
      ];
      setUrlMappedArr(urlArr);
    } else {
      // TODO: what is this used for
      setMovieSearchResults(true);
      setTvSearchResults(true);
      setGameSearchResults(true);
      setUrlSearchResults(false);
      setCurrentTimeout(
        setTimeout(async () => {
          // Requests will return empty array is response is not found in certain timeout (current is 2 seconds) as some APIs go down occasionally
          await axios
            .get(videoGamesSearchURL, {
              timeout: 2000,
            })
            .then((response) => {
              setGameMappedArr(response.data.results.splice(0, 5));
            })
            .catch((error) => {
              if (axios.isCancel(error)) {
                console.log(
                  '[ERROR] Video games took too long to respond (2 sec)',
                );
                setGameMappedArr([]);
              }
            });

          await axios
            .get(movieSearchUrl, {
              timeout: 2000,
            })
            .then((response) => {
              setMovieMappedArr(response.data.results.splice(0, 5));
            })
            .catch((error) => {
              if (axios.isCancel(error)) {
                console.log('[ERROR] Movies took too long to respond (2 sec)');
                setMovieMappedArr([]);
              }
            });

          await axios
            .get(tvSearchUrl, {
              timeout: 2000,
            })
            .then((response) => {
              setTvMappedArr(response.data.results.splice(0, 5));
            })
            .catch((error) => {
              console.log('[ERROR] TV took too long to respond (2 sec)');
              setTvMappedArr([]);
            });
        }, 800),
      );
    }
  };

  document.addEventListener('click', (e) => {
    const isSearchbarMenu = e.target.closest('[data-searchbar-dropdown]');
    const isSearchbar = e.target.closest('[data-searchbar]');
    if (!isSearchbar && !isSearchbarMenu) {
      $('#searchbar-dropdown').css('display', 'none');
    } else return;
  });

  // If searchbar loses focus, it will not display search menu
  const handleFocus = () => {
    $('#searchbar-dropdown').css('display', 'block');
  };

  return (
    <div className="relative">
      <input
        type="search"
        className="rounded-3xl h-12 min-w-80 w-full md:w-input text-center"
        placeholder="Search Textbook, Movie, TV Show, Game..."
        value={userSearchText}
        onChange={handleUserSearchTextChange}
        onFocus={() => handleFocus()}
        id="searchbar"
        data-searchbar
      />
      <div
        className="absolute p-5 bg-white w-full md:w-input rounded-xl max-h-72 overflow-y-auto hidden"
        id="searchbar-dropdown"
        data-searchbar-dropdown
      >
        {urlSearchResults &&
          urlMappedArr.map((value, idx) => (
            <NavLink
              to={
                value.ratingCategory === 'Article'
                  ? '/rate/articles'
                  : value.ratingCategory === 'Online Resource'
                    ? '/rate/online-resources'
                    : value.ratingCategory === 'Misinformation/Disinformation'
                      ? '/rate/misinfo-disinfo'
                      : console.log('err')
              }
            >
              <div
                className="searchbar-url-item"
                key={value.id}
                data-searchbar-item
              >
                <div
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  {value.url}
                </div>
                <div
                  style={{
                    fontSize: '.9rem',
                    color: 'rgba(68, 68, 68, .5)',
                  }}
                >
                  {value.ratingCategory}
                </div>
              </div>
            </NavLink>
          ))}
        {MovieSearchResults &&
          movieMappedArr.map((value, idx) => (
            <NavLink to={`/rate/movie/${value.id}`}>
              <div
                className="searchbar-item"
                key={value.id}
                data-searchbar-item
              >
                <div className="search-image-and-title">
                  <img
                    src={`https://image.tmdb.org/t/p/original/${value.backdrop_path}`}
                    className="searchbar-image"
                    alt={value.title}
                  />
                  <div>
                    <b>{value.title}</b> <br />
                    <b>Movie</b> /{value.release_date.substring(0, 4)}
                  </div>
                </div>
              </div>
            </NavLink>
          ))}
        {tvSearchResults &&
          tvMappedArr.map((value, idx) => (
            <NavLink to={`/rate/tv/${value.id}`}>
              <div
                className="searchbar-item"
                key={value.id}
                data-searchbar-item
              >
                <div className="search-image-and-title">
                  <img
                    src={`https://image.tmdb.org/t/p/original/${value.backdrop_path}`}
                    className="searchbar-image"
                    alt="Not Found"
                  />
                  <div>
                    <b>{value.name}</b> <br />
                    <b>TV</b> /{value.first_air_date.substring(0, 4)}
                  </div>
                </div>
              </div>
            </NavLink>
          ))}
        {gameSearchResults &&
          gameMappedArr.map((value, idx) => (
            <NavLink to={`/rate/game/${value.id}`}>
              <div className="searchbar-item" key={value.id}>
                <div className="search-image-and-title">
                  <img
                    src={value.background_image}
                    className="searchbar-image"
                    alt="Not Found"
                  />
                  <div>
                    <b>{value.name}</b> <br />
                    <b>Video Game</b> /{value.released}
                  </div>
                </div>
              </div>
            </NavLink>
          ))}
      </div>
    </div>
  );
}

export default HeaderSearchbar;
