import { jwtDecode } from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Circles } from 'react-loading-icons';
import { Rating } from 'react-simple-star-rating';
import { ReactComponent as StarsEmpty } from '../../../assets/images/StarSVGEmpty.svg';
import { ReactComponent as StarsFilled } from '../../../assets/images/StarSVGFilled.svg';
import {
  handleEducationAddition,
  handleEntertainmentAddition,
  handleMyProductAddition,
} from '../../../util/DatabaseUtil';
import { visualRating } from '../../../util/EntertainmentUtil';
import Button from '../../Button.jsx';
import { ContextQuestions } from '../../rating/ContextQuestions';
import RatingModal from '../../rating/Modal';
import { RatingContext } from '../RatingPageTabs';

function UserRating({ reviewSubmitted, refresh, setRefresh }) {
  const submitButton = document.getElementById('submit-button');
  const [tagsComment, settagsComment] = useState('');
  const [show, setShow] = useState(false);
  const [contextQuestionsVerified, setContextQuestionsVerified] =
    useState(false);
  const [recommend, setRecommend] = useState(null);
  const token = localStorage.getItem('loginToken');
  const userData = token ? jwtDecode(token) : '';
  const { formData, setFormData, content, categoryInformation, id, cat } =
    useContext(RatingContext);

  useEffect(() => {
    updateFormData({
      userId: userData.id,
      isRecommended: recommend,
      tags: tagsComment,
      contentName: content.title ? content.title : content.name,
      contentId: id ? id : '',
      catId: categoryInformation.ID,
      timestamp: new Date(),
      backdrop_path: content.backdrop_path
        ? content.backdrop_path
        : content.background_image,
    });
  }, [tagsComment, refresh, recommend, window.location.refresh]);
  // Checks to make sure that the context questions are filled in, if not, show contextMessage

  const SendRatingtoDatabase = async (e) => {
    // by default it will submit the form, so prevent it using preventDefault method
    e.preventDefault();

    // Makes sure that required context question is filled before sending
    if (contextQuestionsVerified) {
      const sendToRatings = formData;
      try {
        if (id) {
          handleEntertainmentAddition(sendToRatings);
        } else {
          handleEducationAddition(sendToRatings);
        }
      } catch (err) {
        console.log('Error Sending to Ratings', err);
      }
      try {
        await handleMyProductAddition(sendToRatings);
      } catch (err) {
        console.log('Error Send My Products Data', err);
      }
      setShow(true);
    }
  };

  const handleCommentChange = (event) => {
    updateFormData({ tags: event.target.value });
  };

  const handleClose = () => {
    setShow(false);
    reviewSubmitted();
    setRefresh(true);
  };

  const checkContextFill = (result) => {
    setContextQuestionsVerified(result);
  };

  const openDescription = (id) => {
    const parentNode = document.getElementById(id);
    const questionDescription = parentNode.children[1];
    if (questionDescription.style.display === 'block') {
      questionDescription.style.display = 'none';
    } else {
      questionDescription.style.display = 'block';
    }
  };

  const handleRecommend = (value) => {
    if (recommend === value) {
      setRecommend('');
    } else {
      setRecommend(value);
    }
  };

  const updateFormData = (recievedRating) => {
    setFormData((prev) => ({ ...prev, ...recievedRating }));
  };

  const updateStarData = (qId, value) => {
    setFormData((prev) => ({
      ...prev,
      ratings: prev.ratings.map((x) =>
        x.questionId === qId ? { ...x, rating: value * 2 } : x,
      ),
    }));
  };

  return (
    <>
      {formData ? (
        <div className="bg-darkblue mx-4 md:mx-10 lg:mx-20 my-10 rounded-xl p-4 text-white">
          <Form
            onSubmit={SendRatingtoDatabase}
            className="grid place-items-center"
          >
            <>
              <ContextQuestions
                cat={cat}
                updateRating={updateFormData}
                checkContext={checkContextFill}
                submitButton={submitButton}
              />
              <div className="grid grid-cols-1 gap-y-5 lg:gap-x-10 xl:gap-x-32 mx-4 md:mx-10 lg:mx-20">
                {formData.ratings.map((ratingQue, idx) => (
                  <div
                    className="grid w-full max-w-md md:max-w-lg lg:max-w-xl rounded-lg bg-primary p-2"
                    key={idx}
                    id={ratingQue.questionId}
                  >
                    <div className="flex items-center justify-between p-2 flex-col gap-2 lg:flex-row">
                      <div
                        className={`bg-gray-400 aspect-square w-8 rounded grid place-items-center ${visualRating(ratingQue.rating / 2)}`}
                        id={ratingQue.questionId}
                      >
                        {ratingQue.rating / 2}
                      </div>
                      <label className="lg:pl-2">
                        {ratingQue.questionTitle}
                      </label>
                      <button
                        onClick={() => openDescription(ratingQue.questionId)}
                        className="text-faded lg:ml-6 hidden md:block text-xs hover:text-white"
                      >
                        Show more
                      </button>
                      <div>
                        <Rating
                          onClick={(rate) =>
                            updateStarData(ratingQue.questionId, rate)
                          }
                          ratingValue={ratingQue.rating * 20}
                          allowFraction
                          emptyIcon={<StarsEmpty />}
                          fillIcon={<StarsFilled />}
                        />
                      </div>
                    </div>
                    <div className="hidden text-left ml-7 text-faded text-sm p-2">
                      {ratingQue.description}
                    </div>
                  </div>
                ))}
              </div>
              <div className="text-center pt-10">
                <label className="text-white">Do you recommend?</label>
                <div className="flex justify-center gap-4 mt-2">
                  <button
                    className={`rounded-xl w-24 h-10 border border-white ${formData.isRecommended === true ? 'border-none bg-secondary text-white' : 'bg-white text-black'}`}
                    type="button"
                    onClick={() => handleRecommend(true)}
                    data-recommend-btn
                    id="isRecommended"
                  >
                    Yes
                  </button>
                  <button
                    className={`rounded-xl w-24 h-10 border border-white ${formData.isRecommended === false ? 'border-none bg-secondary text-white' : 'bg-white text-black'}`}
                    type="button"
                    onClick={() => handleRecommend(false)}
                    data-recommend-btn
                    id="notRecommended"
                  >
                    No
                  </button>
                </div>
              </div>
              <div>
                <div className="p-10">
                  <label className="text-white text-center">Tags</label>
                  <div className="mt-2">
                    <textarea
                      id="tagsCommentBox"
                      value={formData.tags}
                      placeholder="enter tag"
                      onChange={(e) => handleCommentChange(e)}
                      rows="3"
                      cols="40"
                      className="w-full rounded-xl text-black"
                    />
                  </div>
                </div>
              </div>
            </>
            <Button type="submit" variant="button" id="submit-button">
              Submit
            </Button>
          </Form>
          <RatingModal show={show} handleClose={() => handleClose()} />
        </div>
      ) : (
        <Circles />
      )}
    </>
  );
}

export default UserRating;
