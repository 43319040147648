import React, { useContext, useEffect, useState } from 'react';
import { RatingContext } from '../RatingPageTabs';
import AccessProductSummary from './AccessProductSummary';
import ProductSummary from './ProductSummary';

export default function KnowQuestTab() {
  const { rated, formData } = useContext(RatingContext);
  const [display, setDisplay] = useState();
  useEffect(() => {
    rated || formData.peeked
      ? setDisplay(<ProductSummary />)
      : setDisplay(<AccessProductSummary peeked={() => peekAtRating()} />);
  }, [rated]);

  const peekAtRating = (value) => {
    setDisplay(<ProductSummary />);
  };

  return <>{display}</>;
}
