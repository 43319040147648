import axios from 'axios';
import 'flowbite';
import { jwtDecode } from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import KnowQuestHomeLogo from '../../assets/images/logo.png';
import '../../assets/stylesheets/header.css';
import { AuthenticationContext } from '../../context/Authentication';
import useAuth from '../../hooks/useAuth';
import { SERVER_URL } from '../../util/EnvironmentConfig';
function Header() {
  const { auth, setAuth } = useAuth();
  const authentication = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  const [randomBackgroundColor, setRandomBackgroundColor] = useState('#FFFFFF');

  let userData = '';

  const token = localStorage.getItem('loginToken');
  if (token) userData = jwtDecode(token);

  window.addEventListener('popstate', () => {
    window.location.reload();
  });

  // random hexadecimal colors

  // Create a function to generate and save a random background color for the user
  const generateRandomBackgroundColor = () => {
    const getRandomHexValue = () => {
      const min = 128; // Minimum value to make it lighter
      const max = 255; // Maximum value for a full color
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const r = getRandomHexValue().toString(16).padStart(2, '0');
    const g = getRandomHexValue().toString(16).padStart(2, '0');
    const b = getRandomHexValue().toString(16).padStart(2, '0');

    return `#${r}${g}${b}`;
  };

  // Fetch the user's background color preference from the backend API
  useEffect(() => {
    const fetchUserBackgroundColor = async () => {
      try {
        const response = await axios.post(
          `${SERVER_URL}/user/get-user-background-color`,
          {
            userId: userData.id,
          },
        );
        if (response.data && response.data.backgroundColor) {
          setRandomBackgroundColor(response.data.backgroundColor);
        } else {
          // If no preference is found, generate and save a new random color
          const newRandomColor = generateRandomBackgroundColor();
          setRandomBackgroundColor(newRandomColor);
          await axios
            .post(`${SERVER_URL}/user/save-user-background-color`, {
              userId: userData.id,
              backgroundColor: newRandomColor,
            })
            .catch((error) => {
              console.error('Failed to save user background color:', error);
            });
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchUserBackgroundColor();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const adminChecker = () => {
      if (userData.role === 1) {
        setIsAdmin(true);
      }
    };
    adminChecker();
  }, [userData]);

  const logout = () => {
    setAuth({});
    authentication.logout();
    navigate('/');
    localStorage.removeItem('loginToken');
    window.location.reload();
  };

  return (
    <nav className="">
      <div className=" max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-8">
        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={KnowQuestHomeLogo} className="h-16" alt="KnowQuest" />
        </a>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="font-medium flex items-center p-4 md:p-0 mt-4 border md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 ">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => [
                  isActive
                    ? 'block py-2 px-3 border-b-4 text-white border-secondary hover:text-secondary md:p-0'
                    : 'block py-2 px-3 text-white hover:text-secondary md:p-0',
                ]}
                end
              >
                Home
              </NavLink>
            </li>
            {/* TODO: Uncomment when landing pages are finished */}
            {/* <li>
              <button
                id="dropdownNavbarLink"
                data-dropdown-toggle="dropdownNavbar"
                className="flex items-center py-2 px-3 text-white hover:text-secondary md:p-0"
              >
                Categories
                <svg
                  className="w-2.5 h-2.5 ms-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <div
                id="dropdownNavbar"
                className="z-10 hidden font-normal bg-white divide-y divide-gray-100 w-28 dark:bg-gray-700 rounded-md dark:divide-gray-600"
              >
                <ul
                  className="p-2 text-sm text-blue-800 font-hind font-semibold"
                  aria-labelledby="dropdownLargeButton"
                >
                  <li>
                    <a
                      href="#"
                      className="block px-4 py-2  hover:bg-gray-100 hover:text-blue-500"
                    >
                      Watch
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Eat
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Learn
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Life
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Play
                    </a>
                  </li>
                </ul>
              </div>
            </li> */}
            <li>
              <NavLink
                to="https://knowquest.net/contact-us/"
                className={({ isActive }) => [
                  isActive
                    ? 'block py-2 px-3 border-b-4 text-white border-secondary hover:text-secondary md:p-0'
                    : 'block py-2 px-3 text-white hover:text-secondary md:p-0',
                ]}
                end
              >
                Contact
              </NavLink>
            </li>
            {isAdmin && (
              <li>
                <NavLink
                  to="/admin"
                  className={({ isActive }) => [
                    isActive
                      ? 'block py-2 px-3 border-b-4 text-white border-secondary hover:text-secondary md:p-0'
                      : 'block py-2 px-3 text-white hover:text-secondary md:p-0',
                  ]}
                  end
                >
                  Admin
                </NavLink>
              </li>
            )}
            <li>
              {auth?.email || userData?.email ? (
                <NavLink to="/" className="nav-links" onClick={logout}>
                  <div className="block py-2 px-3 text-white bg-secondary rounded-3xl md:border-0 md:p-2 dark:text-white dark:hover:text-white">
                    Logout
                  </div>
                </NavLink>
              ) : (
                <>
                  <NavLink to="/login" className="nav-links">
                    <div className="block py-2 px-3 text-white bg-secondary rounded-3xl md:border-0 md:p-2 dark:text-white dark:hover:text-white">
                      Login
                    </div>
                  </NavLink>
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div className="border-b border-solid border-border mx-5 lg:mx-40 md:mx-30 sm:mx-11"></div>
    </nav>
  );
}

export default Header;
