import React from 'react';
import '../assets/stylesheets/privacy.css';
import { NavLink } from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <div id="content">
      <div
        className="container"
        style={{ padding: '20px', marginBottom: '40px' }}
      >
        <div>
          <h3>KnowQuest Inc. Privacy Policy</h3>
          <br />
          <p>Effective date: October 21, 2019</p>
          <br />
          <p>
            KnowQuest Inc. ("us", "we", or "our") operates the www.knowquest.net
            website (hereinafter referred to as the "Service").
          </p>
          <p>
            This page informs you of our policies regarding the collection, use
            and disclosure of personal data when you use our Service and the
            choices you have associated with that data.
          </p>
          <p>
            We use your data to provide and improve the Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, the terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions, accessible from
            www.knowquest.net/privacy
          </p>
        </div>
        <div>
          <br />
          <h4>Definitions</h4>
          <br />
          <h5>Service</h5>
          <p>
            Service is the www.knowquest.net website operated by KnowQuest Inc.
          </p>
          <h5>Personal Data</h5>
          <p>
            Personal Data means data about a living individual who can be
            identified from those data (or from those and other information
            either in our possession or likely to come into our possession).
          </p>
          <h5>Usage Data</h5>
          <p>
            Usage Data is data collected automatically either generated by the
            use of the Service or from the Service infrastructure itself (for
            example, the duration of a page visit).
          </p>
          <h5>Cookies</h5>
          <p>
            Cookies are small files stored on your device (computer or mobile
            device).
          </p>
          <h5>Data Controller</h5>
          <p>
            Data Controller means the natural or legal person who (either alone
            or jointly or in common with other persons) determines the purposes
            for which and the manner in which any personal information are, or
            are to be, processed. For the purpose of this Privacy Policy, we are
            a Data Controller of your Personal Data.
          </p>
          <h5>Data Processors (or Service Providers)</h5>
          <p>
            Data Processor (or Service Provider) means any natural or legal
            person who processes the data on behalf of the Data Controller. We
            may use the services of various Service Providers in order to
            process your data more effectively.
          </p>
          <h5>Data Subject (or User)</h5>
          <p>
            Data Subject is any living individual who is using our Service and
            is the subject of Personal Data.
          </p>

          <h4>Information Collection and Use</h4>
          <p>
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>
          <h4>Types of Data Collected</h4>
          <br />
          <h5>Personal Data</h5>
          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to:
          </p>
          <ul>
            <li>1. Email address</li>
            <li>2. Cookies and Usage Data</li>
          </ul>
          <p>
            We only use your Personal Data to confirm your status as a student,
            professor, publisher, or researcher. You are automatically opted out
            of receiving any and all communications from us, following the email
            confirmation link or instructions provided in any email we send
            during the sign up process, except for the purposes of lost password
            recovery.
          </p>
          <br />
          <h5>Ratings Data</h5>
          <p>
            Ratings are anonymous and not associated with your email address to
            any external users. The number, complexity, and types of ratings may
            change over time.
          </p>
          <br />
          <h5>Usage Data</h5>
          <p>
            We may also collect information on how the Service is accessed and
            used ("Usage Data"). This Usage Data may include information such as
            your computer's Internet Protocol address (e.g. IP address),
            br/owser type, br/owser version, the pages of our Service that you
            visit, the time and date of your visit, the time spent on those
            pages, unique device identifiers and other diagnostic data.
          </p>
          <br />
          <h5>Tracking Cookies Data</h5>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>
          <p>
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your br/owser from
            a website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyse our Service.
          </p>
          <p>
            You can instruct your br/owser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>
          <p>Examples of Cookies we use:</p>
          <ul>
            <li>
              1. Session Cookies. We use Session Cookies to operate our Service.
            </li>
            <li>
              2. Preference Cookies. We use Preference Cookies to remember your
              preferences and various settings.
            </li>
            <li>
              3. Security Cookies. We use Security Cookies for security
              purposes.
            </li>
          </ul>
          <br />
          <h5>Use of Data</h5>
          <p>
            KnowQuest Inc. uses the collected ratings and usage data for various
            purposes:
          </p>

          <ul style={{ marginLeft: '10px' }}>
            <li>1. To provide and maintain our Service</li>
            <li>
              2. To allow you to participate in interactive features of our
              Service when you choose to do so
            </li>
            <li>
              3. To gather analysis or valuable information so that we can
              improve our Service
            </li>
            <li>4. To monitor the usage of our Service</li>
            <li>
              5. To detect, prevent and address technical issues or malicious
              use/attacks
            </li>
            <li>
              6. To help textbook publishers, OER content creators, educational
              resource content creators, educators, institutions including
              schools, ministries of education and their associated ministers,
              and researchers better understand student and professor needs and
              insights, and spot opportunities to improve their products,
              services, and value.
            </li>
          </ul>
          <br />
          <h5>Communications</h5>
          <p>KnowQuest Inc. uses each user’s User Profile page to:</p>

          <ul>
            <li>1. To notify you about changes to our Service</li>
            <li>
              2. To provide customer support – such as contest notifications
            </li>
          </ul>
          <br />
          <h5>
            Legal Basis for Processing Personal Data under the General Data
            Protection Regulation (GDPR)
          </h5>
          <p>
            If you are from the European Economic Area (EEA), KnowQuest Inc.
            legal basis for collecting and using the personal information
            described in this Privacy Policy depends on the Personal Data we
            collect and the specific context in which we collect it.
          </p>
          <p>KnowQuest Inc. may process your Personal Data because:</p>

          <ul style={{ marginLeft: '10px' }}>
            <li>1. We need to perform a contract with you</li>
            <li>2. You have given us permission to do so</li>
            <li>
              3. The processing is in our legitimate interests and it is not
              overridden by your rights
            </li>
            <li>4. For payment processing purposes</li>
            <li>5. To comply with the law</li>
          </ul>
          <br />
          <h5>Retention of Data</h5>
          <p>
            KnowQuest Inc. will retain your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes
            and enforce our legal agreements and policies.
          </p>
          <p>
            KnowQuest Inc. will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of our Service, or we are legally
            obligated to retain this data for longer periods.
          </p>
          <br />
          <h5>Transfer of Data</h5>
          <p>
            Your information, including Personal Data, may be transferred to -
            and maintained on - computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>
          <p>
            If you are located outside Canada and choose to provide information
            to us, please note that we transfer the data, including Personal
            Data, to Canada and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            KnowQuest Inc. will take all the steps reasonably necessary to
            ensure that your data is treated securely and in accordance with
            this Privacy Policy and no transfer of your Personal Data will take
            place to an organisation or a country unless there are adequate
            controls in place including the security of your data and other
            personal information.
          </p>
          <br />
          <h4>Disclosure of Data</h4>
          <br />
          <h5>Business Transaction</h5>
          <p>
            If KnowQuest Inc. is involved in a merger, acquisition or asset
            sale, your Personal Data will not be transferred. We will destroy
            your Personal Data (email) via deletion prior to any other forms of
            data transfer.
          </p>
          <br />
          <h5>Legal Requirements</h5>
          <p>
            KnowQuest Inc. may disclose your Personal Data in the good faith
            belief that such action is necessary to:
          </p>
          <ul style={{ marginLeft: '10px' }}>
            <li>1. To comply with a legal obligation</li>
            <li>
              2. To protect and defend the rights or property of KnowQuest Inc.
            </li>
            <li>
              3. To prevent or investigate possible wrongdoing in connection
              with the Service
            </li>
            <li>
              4. To protect the personal safety of users of the Service or the
              public
            </li>
            <li>5. To protect against legal liability</li>
          </ul>
          <br />
          <h5>Security of Data</h5>
          <p>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
          <br />
          <h5>
            Our Policy on "Do Not Track" Signals under the California Online
            Protection Act (CalOPPA)
          </h5>
          <p>
            We do not support Do Not Track ("DNT") natively in our website. Do
            Not Track is a preference you can set in your web br/owser to inform
            websites that you do not want to be tracked.
          </p>
          <p>
            You can enable or disable Do Not Track by visiting the Preferences
            or Settings page of your web br/owser.
          </p>
          <br />
          <h5>
            Your Data Protection Rights under the General Data Protection
            Regulation (GDPR)
          </h5>
          <p>
            If you are a resident of the European Economic Area (EEA), you have
            certain data protection rights. KnowQuest Inc. aims to take
            reasonable steps to allow you to correct, amend, delete or limit the
            use of your Personal Data.
            <br />
            If you wish to be informed about what Personal Data we hold about
            you and if you want it to be removed from our systems, please
            contact us.
            <br />
            In certain circumstances, you have the following data protection
            rights:
          </p>
          <div style={{ marginLeft: '20px', marginTop: '0px' }}>
            <p>
              <strong>
                The right to access, update or delete the information we have on
                you.
              </strong>{' '}
              Whenever made possible, you can access, update or request deletion
              of your Personal Data directly within your account settings
              section. If you are unable to perform these actions yourself,
              please contact us to assist you.
              <br />
              <strong>The right of rectification.</strong> You have the right to
              have your information rectified if that information is inaccurate
              or incomplete.
              <br />
              <strong>The right to object.</strong> You have the right to object
              to our processing of your Personal Data.
              <br />
              <strong>The right of restriction.</strong> You have the right to
              request that we restrict the processing of your personal
              information.
              <br />
              <strong>The right to data portability. </strong> You have the
              right to be provided with a copy of the information we have on you
              in a structured, machine-readable and commonly used format.
              <br />
              <strong>The right to withdraw consent.</strong> You also have the
              right to withdraw your consent at any time where KnowQuest Inc.
              relied on your consent to process your personal information.
            </p>
          </div>

          <p>
            Please note that we may ask you to verify your identity before
            responding to such requests.
            <br />
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Data. For more information,
            please contact your local data protection authority in the European
            Economic Area (EEA).
          </p>
          <br />
          <h5>Service Providers</h5>
          <p>
            We may employ third party companies and individuals to facilitate
            our Service ("Service Providers"), provide the Service on our
            behalf, perform Service-related services or assist us in analysing
            how our Service is used.
            <br />
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
          <br />
          <h5>Analytics</h5>
          <p>
            We may use third-party Service Providers to monitor and analyse the
            use of our Service.
          </p>
          <div style={{ marginLeft: '20px', marginTop: '0px' }}>
            <p>
              <strong>Clicky</strong> <br />
              Clicky is a web analytics service. Read the Privacy Policy for
              Clicky here:{' '}
              <a href="https://clicky.com/terms">https://clicky.com/terms</a>
            </p>
          </div>
          <br />
          <h5>Links to Other Sites</h5>
          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party's site. We strongly advise you to review the Privacy
            Policy of every site you visit.
            <br />
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <br />
          <h5>Children's Privacy</h5>
          <p>
            Our Service does not address anyone under the age of 18
            ("Children").
            <br />
            We do not knowingly collect personally identifiable information from
            anyone under the age of 18. If you are a parent or guardian and you
            are aware that your Child has provided us with Personal Data, please
            contact us. If we become aware that we have collected Personal Data
            from children without verification of parental consent, we take
            steps to remove that information from our servers.
          </p>
          <br />
          <h5>Changes to This Privacy Policy</h5>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            <br />
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the
            "effective date" at the top of this Privacy Policy.
            <br />
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <br />
          <h5>Contact Us</h5>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us:
            <br />
            By visiting this page on our website:{' '}
            <NavLink to="/contact-us">
              https://www.knowquest.net/contact
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
