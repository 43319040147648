import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './newCategory.css';

function NewCategory() {
  const server_url = process.env.REACT_APP_API_URI; // Development
  const navigate = useNavigate();
  const [categoryTitle, setCategoryTitle] = useState('');
  const [categoryRoute, setCategoryRoute] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (categoryTitle !== '' && categoryRoute !== '') {
      const newCategoryData = {
        title: categoryTitle,
        route: categoryRoute,
      };

      try {
        const response = await axios.post(
          `${server_url}/addCategory`,
          JSON.stringify(newCategoryData),
          {
            headers: { 'Content-Type': 'application/json' },
          },
        );

        if (response) {
          setCategoryTitle('');
          setCategoryRoute('');
          alert('New category added successfully!');
          navigate('/admin/category');
        }
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
  };

  return (
    <div className="add-category">
      <h1>Add Category</h1>
      <form onSubmit={handleFormSubmit}>
        <div className="form-group">
          <label htmlFor="categoryTitle">
            <b>Category Title:</b>
          </label>
          <input
            id="categoryTitle"
            type="text"
            title="Category Title"
            value={categoryTitle}
            placeholder="Category Title"
            className="form-control"
            onChange={(e) => setCategoryTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="categoryRoute">
            <b>Category Route:</b>
          </label>
          <input
            id="categoryRoute"
            type="text"
            title="Category Route"
            value={categoryRoute}
            placeholder="Category Route"
            className="form-control"
            onChange={(e) => setCategoryRoute(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Add new category
        </button>
      </form>
    </div>
  );
}

export default NewCategory;
