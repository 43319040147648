import React from 'react';
import '../assets/stylesheets/terms.css';
import { NavLink } from 'react-router-dom';

function Terms() {
  return (
    <div id="content">
      <div
        className="container"
        style={{ padding: '20px', marginBottom: '40px' }}
      >
        <div className="col-lg">
          <div className="blog-post-text">
            <h3>KnowQuest.net Terms of Use</h3>
            <br />

            <p>Effective date: October 21, 2019</p>
            <p>
              KnowQuest Inc. ("us", "we", “KnowQuest.net”, or "our") operates
              the www.KnowQuest.net website (hereinafter referred to as the
              "Service").
            </p>
            <p>
              This page informs you of our Terms and Conditions regarding the
              use of our Service.
            </p>
            <p>
              YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING OR USING THE SITE OR
              SERVICES OR BY DOWNLOADING OR POSTING ANY CONTENT FROM OR ON THE
              SITE OR THROUGH THE SERVICES, YOU ARE INDICATING THAT YOU HAVE
              READ, AND THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY, THESE
              TERMS, WHETHER OR NOT YOU HAVE REGISTERED ON OR THROUGH THE SITE.
              IF YOU DO NOT AGREE TO THESE TERMS, THEN YOU HAVE NO RIGHT TO
              ACCESS OR USE THE SITE, SERVICES OR COLLECTIVE CONTENT.
            </p>
            <p>
              If you accept or agree to these Terms on behalf of a company,
              college or other legal entity, you represent and warrant that you
              have the authority to bind that company, university or other legal
              entity to these Terms and, in such event, “you” and “your” will
              refer and apply to that company or other legal entity.
            </p>

            <h3>Definitions</h3>
            <br />
            <h4>Site</h4>
            <p>
              Site refers to the website
              <a href="https://KnowQuest.net">www.KnowQuest.net</a> operated by
              KnowQuest Inc.
            </p>

            <h4>Service</h4>
            <p>
              Service is the Site’s features, including but not limited to:
              ratings, search, sign-up, sign-in, etc.
            </p>
            <h4>Account</h4>
            <p>
              In order to access certain Services on the Site, an eligible
              person must setup an Account, which requires an email
              confirmation, user name, password, and security questions.
            </p>
            <h4>Account Type</h4>
            <p>
              Account Types range from students and professors, to publishers
              and content creators, researchers and institutions, as well as
              administrators. Account Types control which Services are available
              to each Member.
            </p>
            <h4>Content</h4>
            <p>
              Content means text, graphics, images, music, software, audio,
              video, information or other works of authorship.
            </p>
            <h4>KnowQuest.net Content</h4>
            <p>
              KnowQuest.net Content means Content that the Service makes
              available including any Content licensed from a third party, but
              excluding Member Content.
            </p>
            <h4>Member</h4>
            <p>
              Member means a person who completes the account registration
              process, as described under the “Account Registration” section
              below.
            </p>

            <h4>Member Content</h4>
            <p>
              Member Content means Content that a Member posts, uploads,
              publishes, submits or transmits to be made available through the
              Site or Services, including Ratings Data.
            </p>
            <h4>Collecting Content</h4>
            <p>
              Collective Content means, collectively, Site Content and Member
              Content.
            </p>
            <h4>Ratings Data</h4>
            <p>
              Ratings Data means any and all data resulting from any and all
              Members.
            </p>

            <h3>Modification</h3>
            <br />
            <p>
              We reserve the right, at its sole discretion, to modify the Site,
              Services and these
              <strong>Terms</strong>, at any time and without prior notice. If
              we modify these Terms, we will post the modification on the Site.
              We will also update the
              <strong>“Effective date”</strong> at the top of these Terms. By
              continuing to access or use the Site or Services after we have
              posted a modification on the Site, you are indicating that you
              agree to be bound by the modified Terms. If the modified Terms are
              not acceptable to you, your only recourse is to cease using the
              Site and Services.
            </p>

            <h3>Eligibility</h3>
            <br />
            <p>
              The Site and Services are intended solely for persons who are 18
              years of age or older. Any access to or use of the Site or
              Services by anyone under the age of 18 is expressly prohibited. By
              accessing or using the Site or Services you represent and warrant
              that you are 18 years of age or older.
            </p>
            <h3>Account Registration</h3>
            <br />
            <p>
              In order to access certain features of the Site and Services and
              to post any Member Content on the Site or through the Services you
              must register to create an Account and become a Member.
            </p>
            <p>
              {' '}
              You agree to provide accurate, current and complete information
              during the registration process and to update such information to
              keep it accurate, current and complete.
            </p>
            <p>
              We reserve the right to suspend or terminate your Account if any
              information provided during the registration process or thereafter
              proves to be inaccurate, not current or incomplete. You are
              responsible for safeguarding your password. You agree that you
              will not disclose your password to any third party and that you
              will take sole responsibility for any activities or actions under
              your Account, whether or not you have authorized such activities
              or actions. You will immediately notify us of any unauthorized use
              of your Account.
            </p>

            <h3>Privacy</h3>
            <br />
            <p>
              See our
              <NavLink to="/privacy">Privacy Policy</NavLink> for information
              and notices concerning the Site’s collection and use of your
              personal information.
            </p>

            <h3>Ownership</h3>
            <br />
            <p>
              The Site, Services and Collective Content are protected by
              copyright, trademark, and other laws of Canada and foreign
              countries. You acknowledge and agree that the Site, Services and
              Collective Content, including all associated intellectual property
              rights, are the exclusive property of KnowQuest.net and its
              licensors. You will not remove, alter or obscure any copyright,
              trademark, service mark or other proprietary rights notices
              incorporated in or accompanying the Site, Services or Collective
              Content.
            </p>
            <p>
              Licenses Granted by KnowQuest.net to Content and Member Content
              Subject to your compliance with the terms and conditions of these
              Terms, we authorize you to download, view and print any Site
              Content, solely for your personal and non-commercial purposes, and
              to access and use the Site and Services, including to download,
              view and print any Member Content solely for your personal and
              non-commercial purposes, and subject to the restrictions set forth
              in these Terms. You have no right to sublicense the license rights
              granted in this section.
            </p>
            <p>
              You will not use, copy, adapt, modify, prepare derivative works
              based upon, distribute, license, sell, transfer, publicly display,
              publicly perform, transmit, stream, br/oadcast or otherwise
              exploit the Site, Services or Collective Content, except as
              expressly permitted in these Terms. No licenses or rights are
              granted to you by implication or otherwise under any intellectual
              property rights owned or controlled by KnowQuest.net or its
              licensors, except for the licenses and rights expressly granted in
              these Terms.
            </p>

            <h3>License granted by Member</h3>
            <br />
            <p>
              We may, in our sole discretion, permit Members to post, upload,
              publish, submit or transmit Member Content. By making any Member
              Content available through the Site or Services, you hereby grant
              us a worldwide, non-exclusive, transferable, sublicensable,
              perpetual, royalty-free license to reproduce, modify for
              formatting purposes, prepare derivative works based upon, publicly
              display, publicly perform, distribute, and otherwise use your
              Member Content in connection with operating and providing the
              Services and Content to you and to other Members. We do not claim
              any ownership rights in any Member Content and nothing in these
              Terms will be deemed to restrict any rights that you may have to
              use and exploit any Member Content.
            </p>
            <p>
              You acknowledge and agree that you are solely responsible for all
              Member Content that you make available through the Site or
              Services. Accordingly, you represent and warrant that: (i) you
              either are the sole and exclusive owner of all Member Content that
              you make available through the Site or Services or you have all
              rights, licenses, consents and releases that are necessary to
              grant to KnowQuest.net the rights in such Member Content, as
              contemplated under these Terms; and (ii) neither the Member
              Content nor your posting, uploading, publication, submission or
              transmittal of the Member Content or our use of the Member Content
              (or any portion thereof) on, through or by means of the Site or
              Services will infringe, misappropriate or violate a third party’s
              patent, copyright, trademark, trade secret, moral rights or other
              intellectual property rights, or rights of publicity or privacy,
              or result in the violation of any applicable law or regulation.
            </p>
            <p />
            <h3>Feedback</h3>
            <br />
            <p>
              We welcome and encourage you to provide feedback, comments and
              suggestions for improvements to the Site or Services (“Feedback”).
              You may submit Feedback by emailing us at
              <a href="mailto:info@KnowQuest.net">info@KnowQuest.net</a>. You
              acknowledge and agree that if you submit any Feedback to us, you
              hereby grant to us a non-exclusive, worldwide, perpetual,
              irrevocable, fully-paid, royalty-free, sub-licensable and
              transferable license under any and all intellectual property
              rights that you own or control to use, copy, modify, create
              derivative works based upon and otherwise exploit the Feedback for
              any purpose.
            </p>

            <h3>General Prohibitions</h3>
            <br />
            <p>You agree not to do any of the following:</p>
            <p>
              1. Post, upload, publish, submit or transmit any Content that:
              (i)&nbsp;infringes, misappropriates or violates a third party’s
              patent, copyright, trademark, trade secret, moral rights or other
              intellectual property rights, or rights of publicity or privacy;
              (ii) violates, or encourages any conduct that would violate, any
              applicable law or regulation or would give rise to civil
              liability; (iii) is fraudulent, knowingly false, misleading or
              deceptive; (iv) is defamatory, obscene, pornographic, vulgar or
              offensive; (v)&nbsp;promotes discrimination, bigotry, racism,
              hatred, harassment or harm against any individual or group; (vi)
              is violent or threatening or promotes violence or actions that are
              threatening to any person or entity; or (vii)&nbsp;promotes
              illegal or harmful activities or substances.
            </p>
            <p>
              2. Use, display, mirror or frame the Site, or any individual
              element within the Site or Services, KnowQuest.net’s name, any
              KnowQuest.net trademark, logo or other proprietary information, or
              the layout and design of any page or form contained on a page,
              without KnowQuest.net’s express written consent;
            </p>
            <p>
              3. Access, tamper with, or use non-public areas of the Site,
              KnowQuest.net’s computer systems, or the technical delivery
              systems of KnowQuest.net’s providers;
            </p>
            <p>
              4. Attempt to probe, scan, or test the vulnerability of any
              KnowQuest.net system or network or br/each any security or
              authentication measures;
            </p>
            <p>
              5. Avoid, bypass, remove, deactivate, impair, descramble or
              otherwise circumvent any technological measure implemented by
              KnowQuest.net or any of KnowQuest.net’s providers or any other
              third party (including another user) to protect the Site, Services
              or Collective Content;
            </p>
            <p>
              6. Attempt to access or search the Site, Services or Collective
              Content or download Collective Content from the Site or Services
              through the use of any engine, software, tool, agent, device or
              mechanism (including spiders, robots, crawlers, data mining tools
              or the like) other than the software and/or search agents provided
              by KnowQuest.net or other generally available third party web
              br/owsers;
            </p>
            <p>
              7. Send any unsolicited or unauthorized advertising, promotional
              materials, email, junk mail, spam, chain letters or other form of
              solicitation;
            </p>
            <p>
              8. Use any meta tags or other hidden text or metadata utilizing a
              KnowQuest.net trademark, logo URL or product name without
              KnowQuest Inc.’s express written consent;
            </p>
            <p>
              9. Use the Site, Services or Collective Content for any commercial
              purpose or the benefit of any third party or in any manner not
              permitted by these Terms;
            </p>
            <p>
              10.Forge any TCP/IP packet header or any part of the header
              information in any email or newsgroup posting, or in any way use
              the Site, Services or Collective Content to send altered,
              deceptive or false source-identifying information;
            </p>
            <p>
              11. Attempt to decipher, decompile, disassemble or reverse
              engineer any of the software used to provide the Site, Services or
              Collective Content;
            </p>
            <p>
              12. Interfere with, or attempt to interfere with, the access of
              any user, host or network, including, without limitation, sending
              a virus, overloading, flooding, spamming, or mail-bombing the Site
              or Services;
            </p>
            <p>
              13. Collect or store any personally identifiable information from
              the Site or Services from other users of the Site or Services
              without their express permission;
            </p>
            <p>
              14. Impersonate or misrepresent your affiliation with any person
              or entity;
            </p>
            <p>15. Violate any applicable law or regulation; or</p>
            <p>
              16. Encourage or enable any other individual to do any of the
              foregoing.
            </p>
            <p>
              We will have the right to investigate and prosecute violations of
              any of the above to the fullest extent of the law. We may involve
              and cooperate with law enforcement authorities in prosecuting
              users who violate these Terms.
            </p>
            <p>
              You acknowledge that we have no obligation to monitor your access
              to or use of the Site, Services or Collective Content or to review
              or edit any Collective Content, but has the right to do so for the
              purpose of operating the Site and Services, to ensure your
              compliance with these Terms, or to comply with applicable law or
              the order or requirement of a court, administrative agency or
              other governmental body.
            </p>
            <p>
              We reserve the right, at any time and without prior notice, to
              remove or disable access to any Collective Content, including, but
              not limited to, Collective Content that we, at our sole
              discretion, consider to be in violation of these Terms or
              otherwise harmful to the Site or Services.
            </p>
            <h3>Copyright Policy</h3>
            <br />
            <p>
              We respect copyright law and expects its users to do the same. It
              is our policy to terminate, in appropriate circumstances, Members
              or other account holders who repeatedly infringe or are believed
              to be repeatedly infringing the rights of copyright holders.
              Please see our Copyright Policy at
              <a href="https://www.knowquest.net/copyright">
                https://www.knowquest.net/copyright
              </a>
              , for further information.
            </p>
            <h3>Links</h3>
            <br />
            <p>
              The Site or Services may contain links to third-party websites or
              resources. You acknowledge and agree that we are not responsible
              or liable for: (i) the availability or accuracy of such websites
              or resources; or (ii) the content, products, or services on or
              available from such websites or resources. Links to such websites
              or resources do not imply any endorsement by knowquest.net of such
              websites or resources or the content, products, or services
              available from such websites or resources. You acknowledge sole
              responsibility for and assume all risk arising from your use of
              any such websites or resources or the content, products, or
              services on or available from such websites or resources.
            </p>
            <h3>Termination and Account Cancellation</h3>
            <br />
            <p>
              We reserve the right, at our sole discretion, to discontinue or
              terminate the Site and Services and to terminate these Terms, at
              any time and without prior notice. If you br/each any of these
              Terms, we will have the right to suspend or disable your Account
              or terminate these Terms, at its sole discretion and without prior
              notice to you. We reserve the right to revoke your access to and
              use of the Site, Services and Collective Content at any time, with
              or without cause. You may cancel your Account at any time by
              visiting your Profile Settings page and clicking on “Delete
              Account”.
            </p>
            <h3>Disclaimers</h3>
            <br />
            <p>
              THE SITE, SERVICES AND COLLECTIVE CONTENT ARE PROVIDED “AS IS”,
              WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT
              LIMITING THE FOREGOING, KNOWQUEST.NET EXPLICITLY DISCLAIMS ANY
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING
              OUT OF COURSE OF DEALING OR USAGE OF TRADE. KNOWQUEST.NET MAKES NO
              WARRANTY THAT THE SITE, SERVICES OR COLLECTIVE CONTENT WILL MEET
              YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR
              ERROR-FREE BASIS. KNOWQUEST.NET MAKES NO WARRANTY REGARDING THE
              QUALITY OF ANY PRODUCTS, SERVICES OR COLLECTIVE CONTENT PURCHASED
              OR OBTAINED THROUGH THE SITE OR SERVICES OR THE ACCURACY,
              TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY
              CONTENT OBTAINED THROUGH THE SITE OR SERVICES. NO ADVICE OR
              INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM KNOWQUEST.NET
              OR THROUGH THE SITE, SERVICES OR COLLECTIVE CONTENT, WILL CREATE
              ANY WARRANTY NOT EXPRESSLY MADE HEREIN. YOU ARE SOLELY RESPONSIBLE
              FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS
              OF THE SITE AND SERVICES AND WITH OTHER PERSONS WITH WHOM YOU
              COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE OR
              SERVICES. YOU UNDERSTAND THAT KNOWQUEST.NET DOES NOT SCREEN OR
              INQUIRE INTO THE BACKGROUND OF ANY USERS OF THE SITE OR SERVICES,
              NOR DOES KNOWQUEST.NET MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS
              OF USERS OF THE SITE OR SERVICES. KNOWQUEST.NET MAKES NO
              REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS OF THE
              SITE OR SERVICES OR THEIR COMPATIBILITY WITH ANY CURRENT OR FUTURE
              USERS OF THE SITE OR SERVICES. YOU AGREE TO TAKE REASONABLE
              PRECAUTIONS IN ALL COMMUNICATIONS AND INTERACTIONS WITH OTHER
              USERS OF THE SITE AND SERVICES AND WITH OTHER PERSONS WITH WHOM
              YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE OR
              SERVICES, PARTICULARLY IF YOU DECIDE TO MEET OFFLINE OR IN PERSON.
            </p>

            <h3>Indemnity</h3>
            <br />
            <p>
              You agree to defend, indemnify, and hold knowquest.net, its
              officers, directors, employees and agents, harmless from and
              against any claims, liabilities, damages, losses, and expenses,
              including, without limitation, reasonable legal and accounting
              fees, arising out of or in any way connected with your access to
              or use of the Site, Services or Collective Content, or your
              violation of these Terms.
            </p>
            <h3>Limitation of Liability</h3>
            <br />
            <p>
              YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY
              LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE
              SITE, SERVICES AND COLLECTIVE CONTENT REMAINS WITH YOU. NEITHER
              KNOWQUEST.NET NOR ANY OTHER PERSON OR ENTITY INVOLVED IN CREATING,
              PRODUCING, OR DELIVERING THE SITE, SERVICES OR COLLECTIVE CONTENT
              WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR
              CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR
              LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM
              FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY
              DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS
              ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE
              OF OR INABILITY TO USE THE SITE, SERVICES OR COLLECTIVE CONTENT,
              OR FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER
              USERS OF THE SITE OR SERVICES OR OTHER PERSONS WITH WHOM YOU
              COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE OR
              SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
              NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND
              WHETHER OR NOT KNOWQUEST.NET HAS BEEN INFORMED OF THE POSSIBILITY
              OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND
              TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. IN NO EVENT WILL
              KNOWQUEST.NET’S AGGREGATE LIABILITY ARISING OUT OF OR IN
              CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE
              THE SITE, SERVICES OR COLLECTIVE CONTENT EXCEED FIFTY DOLLARS
              ($50). THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
              ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN KNOWQUEST.NET AND
              YOU. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION
              OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE
              LIMITATION MAY NOT APPLY TO YOU.
            </p>

            <h3>Proprietary Rights Notices</h3>
            <br />
            <p>
              All trademarks, service marks, logos, trade names and any other
              proprietary designations of knowquest.net used herein are
              trademarks or registered trademarks of knowquest.net. Any other
              trademarks, service marks, logos, trade names and any other
              proprietary designations are the trademarks or registered
              trademarks of their respective parties.
            </p>
            <h3>Controlling Law</h3>
            <br />
            <p>
              These Terms and any action related thereto will be governed by the
              laws of the province of Ontario without regard to its conflict of
              laws provisions.
            </p>
            <h3>Entire Agreement</h3>
            <br />
            <p>
              These Terms constitute the entire and exclusive understanding and
              agreement between knowquest.net and you regarding the Site,
              Services and Collective Content, and these Terms supersede and
              replace any and all prior oral or written understandings or
              agreements between knowquest.net and you regarding the Site,
              Services and Collective Content.
            </p>
            <h3>Assignment</h3>
            <br />
            <p>
              You may not assign or transfer these Terms, by operation of law or
              otherwise, without our prior written consent. Any attempt by you
              to assign or transfer these Terms, without such consent, will be
              null and of no effect. We may assign or transfer these Terms, at
              its sole discretion, without restriction. Subject to the
              foregoing, these Terms will bind and inure to the benefit of the
              parties, their successors and permitted assigns.
            </p>
            <h3>Notices</h3>
            <br />
            <p>
              Any notices or other communications permitted or required
              hereunder, including those regarding modifications to these Terms,
              will be in writing and given by knowquest.net (i) via email (in
              each case to the address that you provide); or (ii) by posting to
              the Site. For notices made by e-mail, the date of receipt will be
              deemed the date on which such notice is transmitted.
            </p>
            <h3>Dispute Resolution</h3>
            <br />
            <p>
              You and KnowQuest.net agree that any dispute, claim or controversy
              arising out of or relating to these Terms or the br/each,
              termination, enforcement, interpretation or validity thereof or
              the use of the Site or Services (collectively, “Disputes”) will be
              settled by binding arbitration, except that each party retains the
              right: (i) to br/ing an individual action in small claims court
              and (ii) to seek injunctive or other equitable relief in a court
              of competent jurisdiction to prevent the actual or threatened
              infringement, misappropriation or violation of a party’s
              copyrights, trademarks, trade secrets, patents or other
              intellectual property rights (the action described in the
              foregoing clause (ii), an “IP Protection Action”). The exclusive
              jurisdiction and venue of any IP Protection Action will be the
              state and federal courts located in the province of Ontario and
              each of the parties hereto waives any objection to jurisdiction
              and venue in such courts. You acknowledge and agree that you and
              KnowQuest.net are each waiving the right to a trial by jury or to
              participate as a plaintiff or className member in any purported
              className action or representative proceeding. Further, unless
              both you and KnowQuest.net otherwise agree in writing, the
              arbitrator may not consolidate more than one person's claims, and
              may not otherwise preside over any form of any className or
              representative proceeding.{' '}
            </p>
            <h3>Contact Us</h3>
            <br />
            <p>
              If you have any questions about these Terms of use, please contact
              us:
            </p>
            <p>
              By visiting this page on our website:
              <a href="/contact-us">www.knowquest.net/contact</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
