import './userList.css';
import { DataGrid } from '@mui/x-data-grid';
import { DeleteOutline, InfoOutlined } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';

function UserList() {
  // const server_url = 'http://localhost:4000'; //development
  const server_url = process.env.REACT_APP_API_URI; // production
  const navigate = useNavigate();
  const [data, setUserData] = useState([]);

  const getAllUser = async () => {
    try {
      const url = `${server_url}/get-all-user`;
      const response = await axios.get(url);
      setUserData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllUser();
  }, []);

  const handleUserDelete = async (id) => {
    try {
      const url = `${server_url}/user/delete-user`;
      const response = await axios.post(url, {
        id,
      });
      if (response.data.status) {
        alert(response.data.msg);
        await getAllUser();
        navigate('/admin/users');
      } else {
        alert(response.data.msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      field: '_id',
      headerName: 'ID',
      width: 300,
    },
    { field: 'email', headerName: 'Email', width: 250 },
    {
      field: 'subject',
      headerName: 'Subject',
      width: 250,
    },
    { field: 'school', headerName: 'School', width: 250 },
    {
      field: 'role',
      headerName: 'User Role',
      width: 200,
      renderCell: (params) => (
        <>
          {params.row.role === 1
            ? 'Admin'
            : params.row.role === '2'
              ? 'Student'
              : params.row.role === '3'
                ? 'Faculty'
                : params.row.role === '4'
                  ? 'Guest User'
                  : ''}
        </>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <>
          <Link to={`/admin/user/${params.row._id}`}>
            <InfoOutlined className="userListEdit" />
          </Link>
          <DeleteOutline
            className="userListDelete"
            onClick={() => handleUserDelete(params.row._id)}
          />
        </>
      ),
    },
  ];

  return (
    <div className="userList">
      <h1>Users</h1>
      <DataGrid
        rows={data}
        getRowId={(row) => row._id}
        disableSelectionOnClick
        columns={columns}
        pageSize={20}
      />
    </div>
  );
}

export default UserList;
