import { Category, HelpOutline, Movie, Person } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import './sidebar.css';

export default function Sidebar() {
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <NavLink to="/admin/users" className="link">
              <li className="sidebarListItem">
                <Person className="sidebarIcon" />
                Users
              </li>
            </NavLink>
            <NavLink to="/admin/ratings" className="link">
              <li className="sidebarListItem">
                <Movie className="sidebarIcon" />
                Ratings
              </li>
            </NavLink>
            <NavLink to="/admin/questions" className="link">
              <li className="sidebarListItem">
                <HelpOutline className="sidebarIcon" />
                Questions
              </li>
            </NavLink>
            <NavLink to="/admin/category" className="link">
              <li className="sidebarListItem">
                <Category className="sidebarIcon" />
                Rating Category
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
    </div>
  );
}
