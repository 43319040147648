import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/stylesheets/advancesearchmovies.css';

export default function AdvanceSearchMovies() {
  const apiKey = process.env.REACT_APP_TMDB_API_KEY;
  const baseURL = `https://api.themoviedb.org/3/discover/movie?api_key=${apiKey}`;

  const [genres, setGenres] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [releaseDateURL, setReleaseDateURL] = useState();
  const [languageURL, setLanguageURL] = useState();
  const [genreURL, setGenreURL] = useState([]);
  const [movieList, setMovieList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        let api = baseURL;

        if (releaseDateURL) {
          api += `&primary_release_date.gte=${releaseDateURL}&primary_release_date.lte=${releaseDateURL}`;
        }
        if (languageURL) {
          api += `&language=${languageURL}`;
        }
        if (genreURL.length > 0) {
          api += `&with_genres=${genreURL.toString()}`;
        }
        api += `&page=${currentPage}`;

        console.log(api);
        const response = await axios.get(api);

        if (response && response.data.results.length > 0) {
          setMovieList(response.data.results);
          setTotalPages(response.data['total_pages']);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, [releaseDateURL, languageURL, genreURL, currentPage]);

  useEffect(() => {
    async function fetchGenreAndLanguage() {
      try {
        const moviesList = await axios.get(baseURL);
        if (moviesList && moviesList.data.results.length > 0) {
          setMovieList(moviesList.data.results);
        }

        const responseLanguage = await axios.get(
          'https://api.themoviedb.org/3/configuration/languages?api_key=5885fe9dd784d8e0af439f0d75b6dda5',
        );

        const responseGenre = await axios.get(
          'https://api.themoviedb.org/3/genre/movie/list?api_key=5885fe9dd784d8e0af439f0d75b6dda5',
        );

        if (responseGenre.data && responseGenre.data.genres) {
          setGenres(responseGenre.data.genres);
        }
        if (responseLanguage.data) {
          setLanguages(responseLanguage.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchGenreAndLanguage();
  }, []);

  function handleDateChange(e) {
    setReleaseDateURL(e.target.value);
  }

  function handleLanguageChange(e) {
    setLanguageURL(e.target.value);
  }

  function handleGenreChange(e) {
    const genreId = e.target.value;
    if (e.target.checked) {
      setGenreURL((prev) => [...prev, genreId]);
    } else {
      setGenreURL((prev) => prev.filter((item) => item !== genreId));
    }
    setCurrentPage(1);
  }
  function handleDateChange(e) {
    setReleaseDateURL(e.target.value);
    setCurrentPage(1);
  }

  function handleLanguageChange(e) {
    setLanguageURL(e.target.value);
    setCurrentPage(1);
  }

  function handleSearchCategoryChange(e) {
    if (e.target.value === 'Movies') {
      navigate('/advance-search/movies');
    }
    if (e.target.value === 'TV') {
      navigate('/advance-search/tv');
    }
  }

  function handlePrevious() {
    if (currentPage !== 1) {
      setCurrentPage((prev) => Number(prev) - 1);
    }
  }
  function handleNext() {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  }
  return (
    <div>
      <div className="main-container">
        <aside className="advance-search-sidebar">
          <div className="sidebar-section">
            <select
              className="sidebar-caption"
              onChange={(e) => {
                handleSearchCategoryChange(e);
              }}
            >
              <option value="Movies">Movies</option>
              <option value="TV">TV Shows</option>
            </select>
          </div>

          <div className="sidebar-section">
            <h3 className="sidebar-heading">Release Date</h3>

            <ul className="sidebar-list">
              <input
                type="date"
                name="releaseDateURL"
                value={releaseDateURL}
                onChange={(e) => handleDateChange(e)}
              ></input>
            </ul>
          </div>
          <div className="sidebar-section">
            <h3 className="sidebar-heading">Language</h3>
            <ul className="sidebar-list">
              <select
                name="language"
                id="language"
                value={languageURL}
                onChange={(e) => handleLanguageChange(e)}
              >
                <option value="">Select language</option>
                {languages.map((language, index) => (
                  <option value={language['iso_639_1']} key={index}>
                    {' '}
                    {language['english_name']}
                  </option>
                ))}
              </select>
            </ul>
          </div>
          <div className="sidebar-section">
            <h3 className="sidebar-heading">Genres</h3>
            <ul className="sidebar-list">
              {genres.map((genre) => (
                <li key={genre.id}>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      id={genre.id}
                      name={genre.name}
                      value={genre.id}
                      className="styled-checkbox"
                      onChange={(e) => handleGenreChange(e)}
                    />
                    &nbsp;{genre.name}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </aside>
        <div className="movie-list-container">
          <div className="movie-list">
            {movieList.map((movie) => (
              <a href={`/rate/movie/${movie.id}`}>
                <div className="movie-card" key={movie.id}>
                  <img
                    src={`https://image.tmdb.org/t/p/w500/${movie.poster_path}`}
                    alt={movie.title}
                    className="movie-poster"
                  />
                </div>
              </a>
            ))}
          </div>
          <div className="advance-search-pagination">
            <div className="advance-search-components">
              <div
                hidden={totalPages > 1 ? false : true}
                className="advance-search-hidden"
              >
                <div className="advance-search-previous">
                  <button
                    className="advance-search-previous-button"
                    style={{ display: currentPage === 1 ? 'none' : 'block' }}
                    onClick={(e) => handlePrevious(e)}
                  >
                    &lt; Previous{' '}
                  </button>
                </div>

                {/* <span>{currentPage}</span> */}

                <div className="advance-search-next">
                  <button
                    className="advance-search-next-button"
                    disabled={currentPage === totalPages ? true : false}
                    onClick={(e) => handleNext(e)}
                  >
                    Next &gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
