import React from 'react';
import GameRating from './header/GameRating';
import MovieRating from './header/MovieRating';
import TvRating from './header/TvRating';

export default function RatingHeader({ cat, content }) {
  switch (cat) {
    case 'movie':
      return <MovieRating content={content} />;
    case 'tv':
      return <TvRating content={content} />;
    case 'game':
      return <GameRating content={content} />;
  }
}
