import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Circles } from 'react-loading-icons';
import { Rating } from 'react-simple-star-rating';
import { ReactComponent as StarsEmpty } from '../assets/images/StarSVGEmpty.svg';
import { ReactComponent as StarsFilled } from '../assets/images/StarSVGFilled.svg';
import Button from '../components/Button.jsx';
import { ContextQuestions } from '../components/rating/ContextQuestions.jsx';
import RatingModal from '../components/rating/Modal.jsx';
import {
  getAllCategoryQuestions,
  getCategoryInformation,
  handleCollisionAddition,
} from '../util/DatabaseUtil';
import { visualRating } from '../util/EntertainmentUtil';
export default function CollisionRating() {
  const submitButton = document.getElementById('submit-button');
  const [show, setShow] = useState(false);
  const [contextQuestionsVerified, setContextQuestionsVerified] =
    useState(false);
  const [questions, setQuestions] = useState([]);
  const categoryInformation = getCategoryInformation('collision');
  const [formData, setFormData] = useState({
    willInvest: null,
    tags: '',
    company: '',
    catId: categoryInformation.ID,
    timestamp: new Date(),
    ratings: [],
  });

  useEffect(() => {
    const getQuestions = async () => {
      try {
        await getAllCategoryQuestions(categoryInformation.ID).then(
          (questions) => {
            setQuestions(questions);
            questions.forEach((question) => {
              setFormData((prev) => ({
                ...prev,
                ratings: [
                  ...prev.ratings,
                  {
                    questionId: question._id,
                    questionTitle: question.question,
                    rating: 0,
                    description: question.description,
                  },
                ],
              }));
            });
          },
        );
      } catch (err) {
        console.log('Error Fetching Category Questions', err);
      }
    };
    getQuestions();
  }, []);

  const updateFormData = (recievedRating) => {
    setFormData((prev) => ({ ...prev, ...recievedRating }));
  };

  const updateStarData = (qId, value) => {
    setFormData((prev) => ({
      ...prev,
      ratings: prev.ratings.map((x) =>
        x.questionId === qId ? { ...x, rating: value * 2 } : x,
      ),
    }));
  };

  const SendRatingtoDatabase = async (e) => {
    // by default it will submit the form, so prevent it using preventDefault method
    e.preventDefault();

    // Makes sure that required context question is filled before sending
    if (contextQuestionsVerified) {
      const sendToRatings = formData;
      try {
        handleCollisionAddition(sendToRatings);
      } catch (err) {
        console.log('Error Sending to Ratings', err);
      }
      setShow(true);
    }
  };

  const checkContextFill = (result) => {
    setContextQuestionsVerified(result);
  };

  const handleRecommend = (value) => {
    if (formData.willInvest === value) {
      updateFormData({
        willInvest: '',
      });
    } else {
      updateFormData({
        willInvest: value,
      });
    }
  };

  const handleCommentChange = (event) => {
    updateFormData({
      tags: event.target.value,
    });
  };

  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };

  return (
    <>
      <div className="p-5 sm:p-10 md:p-20">
        <h1 className="text-faded text-4xl sm:text-6xl md:text-7xl lg:text-8xl font-montserrat font-bold">
          COLLISION
        </h1>
      </div>
      {formData ? (
        <div className="bg-darkblue mx-4 md:mx-10 lg:mx-20 my-10 rounded-xl p-4 text-white">
          <Form
            onSubmit={SendRatingtoDatabase}
            className="grid place-items-center"
          >
            <>
              <ContextQuestions
                cat={'collision'}
                updateRating={updateFormData}
                checkContext={checkContextFill}
                submitButton={submitButton}
                formData={formData}
              />
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-y-5 lg:gap-x-10 xl:gap-x-32 mx-4 md:mx-10 lg:mx-20">
                {formData.ratings.map((ratingQue, idx) => (
                  <div
                    className="grid w-full max-w-md md:max-w-lg lg:max-w-xl rounded-lg bg-primary p-2"
                    key={idx}
                    id={ratingQue.questionId}
                  >
                    <div className="flex items-center justify-between p-2 flex-col gap-2 lg:flex-row">
                      <div
                        className={`bg-gray-400 aspect-square min-w-8 rounded grid place-items-center ${visualRating(ratingQue.rating / 2)}`}
                        id={ratingQue.questionId}
                      >
                        {ratingQue.rating / 2}
                      </div>
                      <label className="lg:pl-2 lg:text-left text-center">
                        {ratingQue.questionTitle}
                      </label>
                      <div>
                        <Rating
                          onClick={(rate) =>
                            updateStarData(ratingQue.questionId, rate)
                          }
                          ratingValue={ratingQue.rating * 20}
                          allowFraction
                          emptyIcon={<StarsEmpty />}
                          fillIcon={<StarsFilled />}
                        />
                      </div>
                    </div>
                    <div className="hidden text-left ml-7 text-faded text-sm p-2">
                      {ratingQue.description}
                    </div>
                  </div>
                ))}
              </div>
              <div className="text-center pt-10">
                <label className="text-white">
                  Would you invest in this business if you could?
                </label>
                <div className="flex justify-center gap-4 mt-2">
                  <button
                    className={`rounded-xl w-24 h-10 border border-white ${formData.willInvest === true ? 'border-none bg-secondary text-white' : 'bg-white text-black'}`}
                    type="button"
                    onClick={() => handleRecommend(true)}
                    data-recommend-btn
                    id="isRecommended"
                  >
                    Yes
                  </button>
                  <button
                    className={`rounded-xl w-24 h-10 border border-white ${formData.willInvest === false ? 'border-none bg-secondary text-white' : 'bg-white text-black'}`}
                    type="button"
                    onClick={() => handleRecommend(false)}
                    data-recommend-btn
                    id="notRecommended"
                  >
                    No
                  </button>
                </div>
              </div>
              <div>
                <div className="p-5 sm:p-10">
                  <label className="text-white text-center">Tags</label>
                  <div className="mt-2">
                    <textarea
                      id="tagsCommentBox"
                      value={formData.tags}
                      placeholder="Enter Tags"
                      onChange={(e) => handleCommentChange(e)}
                      rows="3"
                      cols="40"
                      className="w-full rounded-xl text-black"
                    />
                  </div>
                </div>
              </div>
            </>
            <Button type="submit" variant="button" id="submit-button">
              Submit
            </Button>
          </Form>
          <RatingModal show={show} handleClose={() => handleClose()} />
        </div>
      ) : (
        <Circles />
      )}
    </>
  );
}
