import React from 'react';
import { NavLink } from 'react-router-dom';
import '../../assets/stylesheets/footer.css';

function Footer() {
  return (
    <div className="w-full border-t-2 border-copyrightbar">
      {/* <!-- Footer --> */}
      <footer>
        <div className="flex flex-wrap items-center justify-around border-b-2 border-copyrightbar">
          <div className="grid gap-y-5 sm:pl-16 sm:pt-16 p-5 self-start text-center sm:text-left">
            <h4 className="text-white text-4xl font-montserrat">KnowQuest</h4>
            <p className="text-faded font-hind w-80 text-xs sm:text-lg mx-auto sm:mx-0">
              Our vision is to provide convenience and help increase your sales
              business.
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-x-36 text-white text-center p-10">
            <div>
              <div className="text-xl sm:text-2xl font-montserrat mb-5">
                About
              </div>
              <div className="mb-4 text-faded hover:text-white">
                <a href="https://www.knowquest.net">How it works</a>
              </div>
              <div className="mb-4 text-faded hover:text-white">
                <a href="https://knowquest.net/contact-us/">
                  Business Relation
                </a>
              </div>
              {/*<div className="mb-5 text-faded">Featured</div>
              <div className="mb-5 text-faded">Partnership</div> */}
            </div>
            {/* <div>
              <div className="text-xl sm:text-2xl font-montserrat mb-5">
                Community
              </div>
              <div className="mb-5 text-faded">Events</div>
              <div className="mb-5 text-faded">Invite a Friend</div>
            </div> */}
            <div>
              <div className="text-xl sm:text-2xl font-montserrat mb-5">
                Socials
              </div>
              {/* <div className="mb-4 text-faded">Discord</div> */}
              <div className="flex flex-col sm:gap-4 justify-center items-center">
                <div className="mb-4 text-faded hover:text-white">
                  <a href="https://www.instagram.com/knowquestinc/">
                    Instagram
                  </a>
                </div>
                <div className="mb-4 text-faded hover:text-white">
                  <a href="https://x.com/knowquestinc">Twitter</a>
                </div>
                <div className="mb-4 text-faded hover:text-white">
                  <a href="https://www.facebook.com/AcademicFeedback/">
                    Facebook
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Copyright --> */}
        <div className="text-center text-white p-10 text-sm sm:text-base">
          Copyright 2022 © KnowQuest Inc. All rights reserved
        </div>
      </footer>
      {/* <!-- Footer --> */}
    </div>
  );
}

export default Footer;
