export const avgRatingsByQuestionId = (ratings, questionId) => {
  let countRating = 0;
  let avgRating;
  let totalRating = 0;
  ratings.map((r) => {
    r.ratings.map((rt) => {
      // Only count ratings of non-zero
      if (rt.questionId === questionId && rt.rating > 0) {
        totalRating += rt.rating;
        countRating += 1;
      }
    });
  });

  // If there are no ratings for this category i.e. a rating of 0, then we do not average it in
  countRating === 0
    ? (avgRating = totalRating)
    : (avgRating = totalRating / countRating);
  return avgRating;
};

export const getAvgRating = (ratings, questions) => {
  let avgRating;
  let totalRating = 0;
  let totAvgRating;
  let count = 0;

  questions.map((q) => {
    totalRating += avgRatingsByQuestionId(ratings, q.questionId);
    if (avgRatingsByQuestionId(ratings, q.questionId) > 0) {
      count++;
    }
    return null;
  });

  // avgRating = totalRating / showQuestions.length; -> goes against ALL ratings and not just ratings that exist
  if (count === 0) {
    avgRating = 0;
  } else {
    avgRating = totalRating / count;
  }

  if (ratings.length !== 0) {
    totAvgRating = avgRating;
  } else {
    totAvgRating = 0;
  }

  return totAvgRating * 2;
};

export function getEducationRatingByQuestionId(questionId, latestRatings) {
  let totalRating = 0;
  if (latestRatings) {
    latestRatings.map((rt) => {
      // Only count ratings of non-zero
      if (rt.questionId === questionId && rt.rating > 0) {
        totalRating = rt.rating;
      }
    });
  }

  return totalRating;
}

export function getAvgRatingByQuestionIdBoolean(ratings, questionId) {
  let countRating = 0;
  let avgRating;
  let totalTrueRating = 0;
  ratings.map((r) => {
    r.ratings.map((rt) => {
      // Only count ratings of non-zero
      if (rt.questionId === questionId && rt.rating !== 0) {
        countRating += 1;
        if (rt.rating === true) {
          totalTrueRating += 1;
        }
      }
    });
  });
  countRating === 0
    ? (avgRating = totalTrueRating)
    : (avgRating = totalTrueRating / countRating);

  return avgRating;
}

export const getAvgRatingBoolean = (ratings, questions) => {
  let avgRating;
  let totalRating = 0;
  let totAvgRating;
  let count = 0;

  questions.map((q) => {
    totalRating += getAvgRatingByQuestionIdBoolean(ratings, q.questionId);
    if (getAvgRatingByQuestionIdBoolean(ratings, q.questionId) > 0) {
      count++;
    }
    return null;
  });

  // avgRating = totalRating / showQuestions.length; -> goes against ALL ratings and not just ratings that exist
  if (count === 0) {
    avgRating = 0;
  } else {
    avgRating = totalRating / count;
  }

  if (ratings.length !== 0) {
    totAvgRating = avgRating;
  } else {
    totAvgRating = 0;
  }

  return totAvgRating * 2;
};

export const getAvgRecommendBoolean = (ratings) => {
  let countRating = 0;
  let avgRating;
  let totalTrueRating = 0;
  ratings.map((r) => {
    countRating += 1;
    if (r.isRecommended === true) {
      totalTrueRating += 1;
    }
  });
  countRating === 0
    ? (avgRating = totalTrueRating)
    : (avgRating = totalTrueRating / countRating);
  return avgRating;
};
