import './newQuestion.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function NewProduct() {
  const server_url = process.env.REACT_APP_API_URI; // development
  const navigate = useNavigate();
  const [category, setCategory] = useState(); // Set all category
  const [selectedCategory, setSelectedCategory] = useState(); // Set selected category
  const [question, setQuestion] = useState(''); // Set question title
  const [description, setDescription] = useState(''); // Set question title

  useEffect(() => {
    const getAllCategory = async () => {
      try {
        const url = `${server_url}/get-all-category`;
        const response = await axios.get(url);
        setCategory(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    getAllCategory();
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (question !== '') {
      const newQuestionData = {
        catId: selectedCategory,
        question,
        description,
      };
      console.log(newQuestionData);
      // const url = ;
      try {
        const resp = await axios.post(
          `${server_url}/addQuestion`,
          JSON.stringify(newQuestionData),
          {
            headers: { 'Content-Type': 'application/json' },
          },
        );
        if (resp) {
          setSelectedCategory();
          setQuestion('');
          setDescription('');
          alert('New question added Successfully! ');
          navigate('/admin/questions');
        }
      } catch (err) {
        alert(err);
        console.log(err);
      }
    }
  };

  return (
    <div className="add-question">
      <div className="addQuestionTitleContainer">
        <h1 className="addQuestionTitle">Add Question</h1>
      </div>
      <div className="addQuestionBottom">
        <form className="addQuestionForm" onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="dropdown-menu">
              <b>Select Category: </b>
            </label>
            <select
              id="dropdown-menu"
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
              }}
            >
              <option value="default" disabled selected>
                Choose an option
              </option>
              {category?.map((category) => (
                <option value={category._id}>{category.title}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="qTitle">
              <b>Question Title:</b>
            </label>
            <input
              id="qTitle"
              type="text"
              title="Question Title"
              value={question}
              placeholder="Question Title"
              className="form-control col-lg-3 col-sm-2"
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="qTitle">
              <b>Question Description:</b>
            </label>
            <input
              id="qDescription"
              type="text"
              title="Question Description"
              value={description}
              placeholder="Question Description"
              className="form-control col-lg-3 col-sm-2"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
          <br />
          <button type="submit" className="btn btn-primary">
            Add new question
          </button>
        </form>
      </div>
    </div>
  );
}
