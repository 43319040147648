import { Circles } from 'react-loading-icons';
export default function GameRatingHeader({ content }) {
  return (
    <>
      {content && content.tags ? (
        <div className="flex justify-center gap-y-8 p-10 flex-wrap">
          <img
            src={content.background_image}
            className="rounded-xl border-2 aspect-video w-80 sm:w-160"
            loading="lazy"
          />
          <div className="grid gap-y-4 text-center">
            <h2 className="text-white font-bold text-4xl sm:text-5xl font-hind text-center pt-6 lg:pt-0">
              {content.name}
            </h2>
            <h3 className="text-faded font-hind text-sm sm:text-lg text-center px-20">
              {content.description_raw}
            </h3>
            <h4 className="text-faded font-hind text-lg text-center">
              <span className="font-bold">Released</span>: {content.released}
            </h4>
            <div className="flex gap-x-6 justify-start overflow-hidden">
              {content.tags.map((genre) => (
                <div className="rounded-3xl flex items-center text-sm font-hind text-faded p-3 bg-blue-950 whitespace-nowrap">
                  {genre.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="grid place-items-center">
          <Circles />
        </div>
      )}
    </>
  );
}
