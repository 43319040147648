import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import useAuth from '../hooks/useAuth';
import { AuthenticationContext } from '../context/Authentication';
import { useNavigate } from 'react-router';

function QRCodeHandler() {
  const { code } = useParams();
  const { route } = jwtDecode(code);
  const { setAuth } = useAuth();
  const authentication = useContext(AuthenticationContext);
  const navigate = useNavigate();

  const server_url = process.env.REACT_APP_API_URI;

  /* Token Format
    userEmail
    userPassword
    route
   */
  useEffect(() => {
    const runQRHandler = async () => {
      try {
        const response = await axios.get(
          `${server_url}/user/jwt-login?code=${code}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        const data = jwtDecode(response.data.token);
        // const data = jwt.verify(code);
        const { token } = response.data;
        const roles = data.role;
        const { email } = data;
        setAuth({ email, roles, token });
        authentication.login();
        localStorage.setItem('loginToken', response.data.token);
        navigate(route || '/');
      } catch (err) {
        console.log(err);
      }
    };
    runQRHandler();
  }, [authentication, code, navigate, server_url, setAuth]);

  return (
    <div className="my-28 text-center">
      <p className="text-white text-[100px]">Redirecting...</p>
    </div>
  );
}

export default QRCodeHandler;
