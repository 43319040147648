export const Category = {
  MOVIE: {
    ID: process.env.REACT_APP_CATID_MOVIE,
    ROUTE: 'movie',
  },
  TV: {
    ID: process.env.REACT_APP_CATID_TV,
    ROUTE: 'tv',
  },
  GAME: {
    ID: process.env.REACT_APP_CATID_VG,
    ROUTE: 'game',
  },
  TEXTBOOK: process.env.REACT_APP_CATID_TEXTBOOK,
  SCHOOL: process.env.REACT_APP_CATID_SCHOOL,
  CLASS: process.env.REACT_APP_CATID_CLASS,
  ONLINE_LEARNING: process.env.REACT_APP_CATID_OL,
  ONLINE_RESOURCE: process.env.REACT_APP_CATID_OR,
  ARTICLE: process.env.REACT_APP_CATID_ARTICLES,
  POLITICAL_POLICY: process.env.REACT_APP_CATID_POLITICAL_POLICY,
  MISDISINFO: process.env.REACT_APP_CATID_MISDIS,
  JOB_PROSPECT: process.env.REACT_APP_CATID_JP,
  APPLICATION_PROCESS: process.env.REACT_APP_CATID_AP,
  MENTAL_HEALTH: process.env.REACT_APP_CATID_MENTALHEALTH,
  BEVERAGE: process.env.REACT_APP_CATID_BEV,
  COLLISION: process.env.REACT_APP_CATID_COLLISION,
};

export const getGeneralCategory = (url) => url.localeCompare('/education');

export const SERVER_URL = process.env.REACT_APP_API_URI;
export const RAWGIO_KEY = process.env.REACT_APP_RAWGIO_API_KEY;
export const TMDB_KEY = process.env.REACT_APP_TMDB_API_KEY;
export const RouteCategory = {};
