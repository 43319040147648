import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import useAuth from '../hooks/useAuth';
import Unauthorized from './Unauthorized';

function RequireAuth({ allowedRoles }) {
  const { auth } = useAuth();
  const location = useLocation();

  let data = '';
  if (localStorage.getItem('loginToken')) {
    data = jwtDecode(localStorage.getItem('loginToken'));
  }
  return (
    <>
      {allowedRoles.includes(data.role) ? (
        <Outlet />
      ) : auth?.email || data?.email ? (
        <Unauthorized />
      ) : (
        // ? <Navigate to="/un" state={{ from: location }} replace />
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </>
  );
}

export default RequireAuth;
