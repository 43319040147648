import React, { useContext, useEffect, useState } from 'react';
import { Circles } from 'react-loading-icons';
import { Rating } from 'react-simple-star-rating';
import { ReactComponent as StarsEmpty } from '../../../assets/images/StarSVGEmpty.svg';
import { ReactComponent as StarsFilled } from '../../../assets/images/StarSVGFilled.svg';
import {
  getAllCategoryQuestions,
  getRatingsById,
} from '../../../util/DatabaseUtil';
import {
  avgRatingsByQuestionId,
  getAvgRecommendBoolean,
  visualRating,
} from '../../../util/EntertainmentUtil';
import RatingProgressBar from '../../rating/ProgressBar';
import { RatingContext } from '../RatingPageTabs';
export default function ProductSummary(props) {
  const [kqRating, setKqRating] = useState([]);
  const [questions, setQuestions] = useState([]);
  const { categoryInformation, id } = useContext(RatingContext);

  useEffect(() => {
    const fetchKqRatingById = async () => {
      try {
        const data = await getRatingsById(id, categoryInformation.ID);
        setKqRating(data);
      } catch (err) {
        console.log('Error Fetching KnowQuest Rating', err);
      }
    };

    const getQuestions = async () => {
      try {
        await getAllCategoryQuestions(categoryInformation.ID).then(
          (questions) => {
            setQuestions(questions);
          },
        );
      } catch (err) {
        console.log('Error Fetching Category Questions', err);
      }
    };

    getQuestions();
    fetchKqRatingById();
  }, []);

  return (
    <>
      {kqRating && questions ? (
        <div className="bg-darkblue rounded-xl p-6 sm:p-10 mx-4 md:mx-10 lg:mx-20 my-10">
          <div className="grid place-items-center pb-5">
            <RatingProgressBar kqRating={kqRating} questions={questions} />
          </div>
          <div className="grid grid-cols-1 place-items-center gap-y-5 gap-x-4 text-white">
            {questions.map((ratingQue, idx) => (
              <div
                className="grid w-full max-w-md md:max-w-lg lg:max-w-xl rounded-lg bg-primary p-4"
                key={idx}
              >
                <div className="flex flex-col lg:flex-row items-center justify-between p-2 gap-2">
                  <div
                    className={`aspect-square w-8 rounded grid place-items-center ${visualRating(
                      (
                        avgRatingsByQuestionId(kqRating, ratingQue._id) / 2
                      ).toFixed(1),
                    )}`}
                  >
                    {(
                      avgRatingsByQuestionId(kqRating, ratingQue._id) / 2
                    ).toFixed(1)}
                  </div>
                  <label className="text-center lg:text-left lg:pl-2">
                    {ratingQue.question}
                  </label>
                  <div>
                    <Rating
                      initialValue={
                        avgRatingsByQuestionId(kqRating, ratingQue._id) / 2
                      }
                      allowFraction
                      readonly
                      emptyIcon={<StarsEmpty />}
                      fillIcon={<StarsFilled />}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-white p-6 sm:p-10 grid gap-y-4 place-items-center">
            <label>Recommended</label>
            <div className="bg-primary w-full max-w-md lg:max-w-lg rounded-full">
              <div
                className="w-0 py-2 rounded-full bg-secondary"
                id="bar"
                style={{
                  width: `${(getAvgRecommendBoolean(kqRating) * 100).toFixed(1)}%`,
                }}
              >
                <span className="block text-center text-white">
                  {(getAvgRecommendBoolean(kqRating) * 100).toFixed(1)}%
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Circles />
      )}
    </>
  );
}
