import { useLocation, useNavigate } from 'react-router-dom';
import './editCategory.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

function EditCategory() {
  const navigate = useNavigate();
  const server_url = process.env.REACT_APP_API_URI;
  const location = useLocation();
  const { catId } = location.state; // Access catId from location.state object
  const [category, setCategoryData] = useState();
  const [updatedCategory, setUpdatedCategory] = useState({
    title: '',
    route: '',
  });

  useEffect(() => {
    const getCategoryById = async () => {
      try {
        const getCategoryUrl = `${server_url}/category/get-by-id`;
        const response = await axios.post(getCategoryUrl, {
          catId,
        });
        setCategoryData(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    getCategoryById();
  }, [catId, server_url]);

  const updateCategoryForm = (value) => {
    setUpdatedCategory({ ...updatedCategory, ...value });
  };

  const handleCategoryFormSubmit = async (e) => {
    e.preventDefault();
    const updatedCategoryDetail = { ...updatedCategory, catId };
    try {
      const response = await axios.put(
        `${server_url}/update-category`, // Update the endpoint
        updatedCategoryDetail, // No need to stringify the object
      );
      if (response.data.status) {
        alert(response.data.msg);
        navigate('/admin/category');
      } else {
        alert(response.data.msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="edit-category">
      <div className="categoryTitleContainer">
        <h1 className="editCategoryTitle">Edit Category</h1>
      </div>
      {category && (
        <div className="categoryTop">
          <div className="categoryInfoTop">
            <span className="categoryTitle">{category?.title}</span>
          </div>
          <div className="categoryInfoBottom">
            <div className="categoryInfoItem">
              <span className="categoryInfoKey">ID:</span>
              <span className="categoryInfoValue">{category?._id}</span>
            </div>
          </div>
        </div>
      )}
      <div className="categoryBottom">
        <form className="categoryForm" onSubmit={handleCategoryFormSubmit}>
          <div className="categoryFormLeft">
            <label>Category Title</label>
            <input
              type="text"
              className="col-lg-2"
              placeholder={category?.title}
              value={updatedCategory.title}
              onChange={(e) => updateCategoryForm({ title: e.target.value })}
              required
              minLength={3}
            />
          </div>
          <div className="categoryFormLeft">
            <label>Category Route</label>
            <input
              type="text"
              className="col-lg-2"
              placeholder={category?.route}
              value={updatedCategory.route}
              onChange={(e) => updateCategoryForm({ route: e.target.value })}
              required
              minLength={0}
            />
          </div>
          <div className="categoryFormRight">
            <button className="categoryButton" type="submit">
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditCategory;
