import React from 'react';

function Unauthorized() {
  return (
    <div className="main-app" style={{ marginTop: '9rem' }}>
      <h4>
        You are not authorized to access the page. This page is meant for
        students. Please contact Admin.
      </h4>
    </div>
  );
}

export default Unauthorized;
