import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
} from '@react-oauth/google';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ROLES } from '../../../App';
import { AuthenticationContext } from '../../../context/Authentication';
import useAuth from '../../../hooks/useAuth';
import './register.css';

const ForwardedGoogleLogin = React.forwardRef((props, ref) => (
  <GoogleLogin ref={ref} {...props} />
));

function RegistrationOptions(props) {
  const navigate = useNavigate();
  const authentication = useContext(AuthenticationContext);
  const { setAuth } = useAuth();
  const [userInfo, setUserInfo] = useState({});
  const [showloginButton, setShowloginButton] = useState(true);
  const server_url = process.env.REACT_APP_API_URI;
  const googleClientID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

  // Google Login check
  const checkLogin = async (user) => {
    try {
      const response = await axios.post(
        `${server_url}/user/google-login`,
        JSON.stringify({ name: user.name, email: user.email, token: user.token }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.isLoggedIn === true) {
        const data = jwtDecode(response.data.token);
        const { token } = response.data;
        const roles = data.role;
        const { email } = data;
        setAuth({ email, roles, token });
        authentication.login();
        localStorage.setItem('loginToken', response.data.token);
        if (roles === ROLES.Admin) {
          navigate('/admin');
        } else {
          navigate('/');
        }
      } else {
        alert(response.data.msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const GoogleLoginCard = () => {
    const login = useGoogleLogin({
      onSuccess: (credentialResponse) => {
        const user = jwtDecode(credentialResponse.credential);
        setUserInfo(user);
        setShowloginButton(false);
        checkLogin(user);
        navigate('/');
      },
    });

    return (
      <Link onClick={() => login()} className="flex flex-col">
        <div className="reg-btns">
          <div className="reg-btn">
            {showloginButton ? (
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  const user = jwtDecode(credentialResponse.credential);
                  setUserInfo(user);
                  setShowloginButton(false);
                  checkLogin(user);
                  navigate('/');
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            ) : null}
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div className="flex lg:flex-row flex-col justify-center items-center gap-12 p-10 lg:p-20">
      <div className="">
        <h1 className="text-5xl font-bold text-secondary">Register With Us</h1>
      </div>
      <div className="flex flex-col gap-4">
        <Link
          onClick={() => {
            props.registerBtn(2);
          }}
          className="flex flex-col gap-2 text-faded rounded-lg bg-darkblue p-10 lg:w-160 hover:text-secondary "
        >
          <h3 className="font-bold">Register as a Student / Faculty</h3>
          <p className="text-sm">
            Students would be able to rate by different categories provided such
            as textbook, class, school, movies, and more, according to the
            rating categories different questions would be presented. Faculty
            would only be able to rate some categories such as textbooks, online
            resources, and articles.
          </p>
        </Link>
        <Link
          onClick={() => props.registerBtn(3)}
          className="flex flex-col gap-2 text-faded rounded-lg bg-darkblue p-10 lg:w-160 hover:text-secondary "
        >
          <h3 className="font-bold">Register as a Regular User</h3>
          <p className="text-sm">
            User would be able to review entertainment categories.
          </p>
        </Link>
        <GoogleOAuthProvider clientId={googleClientID}>
          <GoogleLoginCard />
        </GoogleOAuthProvider>
        <div className="text-faded text-center">
          Already have an account?{' '}
          <Link to="/login" className="text-secondary">
            Login.
          </Link>
        </div>
      </div>
    </div>
  );
}

export default RegistrationOptions;
