import { TMDB_KEY } from '../EnvironmentConfig';
import { tmdbAPI } from './axiosConfigs';
import { defineCancelApiObject } from './axiosUtils';

export const getTVById = {
  async get(id, cancel = false) {
    const response = await tmdbAPI.request({
      url: `/tv/${id}?api_key=${TMDB_KEY}&language=en-US`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getTrendingTVDaily = {
  async get(cancel = false) {
    const response = await tmdbAPI.request({
      url: `/trending/tv/day?api_key=${TMDB_KEY}&language=en-US`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getTrendingTVWeekly = {
  async get(cancel = false) {
    const response = await tmdbAPI.request({
      url: `/trending/tv/week?api_key=${TMDB_KEY}&language=en-US`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getPopularTV = {
  async get(cancel = false) {
    const response = await tmdbAPI.request({
      url: `/tv/popular?api_key=${TMDB_KEY}&language=en-US&page=1`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getPlayingThisWeekTV = {
  async get(cancel = false) {
    const response = await tmdbAPI.request({
      url: `/tv/on_the_air?api_key=${TMDB_KEY}&language=en-US&page=1`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getTVRecommendations = {
  async get(id, cancel = false) {
    const response = await tmdbAPI.request({
      url: `/tv/${id}/recommendations?api_key=${TMDB_KEY}&language=en-US&page=1&page_size=8`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

export const getTVTrailer = {
  async get(id, cancel = false) {
    const response = await tmdbAPI.request({
      url: `/tv/${id}/videos?api_key=${TMDB_KEY}&language=en-US`,
      method: 'GET',
      signals: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(getTVById);
