import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import RatingHeader from '../../components/rating/RatingHeader';
import Tabs from '../../components/tabs/RatingPageTabs';
import { getGameById } from '../../util/apis/GameAPI';
import { getMovieById } from '../../util/apis/MovieAPI';
import { getTVById } from '../../util/apis/TvAPI';

function EntertainmentRating() {
  const { cat, id } = useParams();
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    const fetchAPIData = async () => {
      switch (cat) {
        case 'movie':
          await getMovieById.get(id, true).then((data) => {
            setApiData(data);
          });
          break;
        case 'tv':
          await getTVById.get(id, true).then((data) => {
            setApiData(data);
          });
          break;
        case 'game':
          await getGameById.get(id, true).then((data) => {
            setApiData(data);
          });
          break;
      }
    };

    fetchAPIData();
  }, [window.location.refresh]);

  return (
    <>
      <RatingHeader cat={cat} content={apiData} />
      <Tabs content={apiData} />
    </>
  );
}

export default EntertainmentRating;
