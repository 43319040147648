import { useEffect, useState } from 'react';
import { Circles } from 'react-loading-icons';
import { getRecentGames } from '../../../util/apis/GameAPI';
import Button from '../../Button';

export default function PlayTab() {
  const [content, setContent] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await getRecentGames.get(true).then((data) => {
        setContent(data.results.splice(0, 12));
      });
    };

    fetchData();
  }, []);

  return (
    <>
      {content ? (
        <>
          <div className="grid place-items-center lg:grid-cols-4 sm:grid-cols-2 grid-cols-2 gap-y-8 gap-x-3 px-3 md:px-20 lg:px-28 sm:max-h-hidden overflow-hidden">
            {content.map((item, id) => (
              <a
                key={id}
                href={`/rate/game/${item.id}`}
                className="relative mb-10 md:w-72 lg:w-96"
              >
                <span className="absolute top-4 left-0 -rotate-45 bg-secondary text-white px-2 py-0.5 rounded-lg text-xs">
                  Game
                </span>
                <img
                  src={item.background_image}
                  className="rounded-t-xl border-2 aspect-backdrop w-full max-w-full"
                  loading="eager"
                />
                <div className="bg-white rounded-b-xl absolute w-full p-1 text-nowrap whitespace-nowrap text-ellipsis">
                  {item.name}
                </div>
              </a>
            ))}
          </div>
          <div className="flex justify-center py-5">
            <Button variant="button" type="button" disabled>
              Find More (Coming Soon)
            </Button>
          </div>
        </>
      ) : (
        <div className="text-5xl text-white">
          <Circles />
        </div>
      )}
    </>
  );
}
