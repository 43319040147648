import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import RatingHeader from '../../components/rating/RatingHeader';
import Tabs from '../../components/tabs/RatingPageTabs';
import { getGameById } from '../../util/apis/GameAPI';
import { getMovieById } from '../../util/apis/MovieAPI';
import { getTVById } from '../../util/apis/TvAPI';

export default function EducationRating() {
  const { cat, id } = useParams();
  const [apiData, setApiData] = useState([]);

  return (
    <>
      <RatingHeader cat={cat} content={apiData} />
      <Tabs content={apiData} />
    </>
  );
}
