import './Question.css';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { getAllCategoryQuestions } from '../../../../util/DatabaseUtil';

function Question() {
  const server_url = process.env.REACT_APP_API_URI; // production
  // const navigate = useNavigate();
  const [category, setCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const getAllCategory = async () => {
      try {
        const url = `${server_url}/context/get-all-category`;
        const response = await axios.get(url);
        setCategory(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    getAllCategory();
  }, [server_url]);

  const handleQuestionDelete = async (catId, questionId) => {
    try {
      if (window.confirm('Are you sure you want to delete this question?')) {
        const response = await axios.post(
          `${server_url}/question/delete-question`,
          {
            catId,
            questionId,
          },
        );
        if (response.data.status) {
          alert(response.data.msg);
          await getAllQuestion(catId);
        } else {
          alert(response.data.msg);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllQuestion = async (id) => {
    try {
      const data = await getAllCategoryQuestions(id);
      setQuestions(data);
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      field: '_id',
      headerName: 'QuestionID',
      width: 300,
    },
    { field: 'question', headerName: 'School', width: 250 },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <>
          <Link
            to="/admin/edit-questions/"
            state={{ catId: selectedCategory, questionId: params.row._id }}
          >
            <Edit className="userListEdit" />
          </Link>
          <DeleteOutline
            className="userListDelete"
            onClick={() =>
              handleQuestionDelete(selectedCategory, params.row._id)
            }
          />
        </>
      ),
    },
  ];

  return (
    <div className="question-main-container p-2 ">
      <div className="row question-heading ">
        <h3 className="question-title">Select Category: </h3>
        <select
          id="dropdown-menu"
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
            getAllQuestion(e.target.value);
          }}
        >
          <option value="default" disabled defaultValue>
            Choose an option
          </option>
          {category?.map((category) => (
            <option key={category._id} value={category._id}>
              {category.title}
            </option>
          ))}
        </select>
        <Link to="/admin/newQuestion" className="ml-auto">
          <button className="questionAddButton">Add New Question</button>
        </Link>
      </div>

      <div className="questionList">
        <DataGrid
          rows={questions}
          getRowId={(row) => row._id}
          disableSelectionOnClick
          columns={columns}
          pageSize={20}
        />
      </div>
    </div>
  );
}

export default Question;
