import axios from 'axios';
import { Category, SERVER_URL } from './EnvironmentConfig';

export const getUserSchool = async (userId) => {
  try {
    const url = `${SERVER_URL}/user/get-user-school`;
    const response = await axios.post(url, {
      id: userId,
    });
    return response.data;
  } catch (err) {
    console.log('Error fetching user school from Database', err);
  }
};

export const getAllSubject = async () => {
  try {
    const url = `${SERVER_URL}/context/get-all-subject`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    console.log('Error fetching subjects from Database', err);
  }
};

export const getAllSemesterYear = async () => {
  try {
    const url = `${SERVER_URL}/context/get-all-semester-year`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    console.log('Error fetching Year from Database', err);
  }
};

export const getAllSemester = async () => {
  try {
    const url = `${SERVER_URL}/context/get-all-semester`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    console.log('Error fetching Semester from Database', err);
  }
};

export const getAllLevel = async () => {
  try {
    const url = `${SERVER_URL}/context/get-all-level`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllAppMethod = async () => {
  try {
    const url = `${SERVER_URL}/context/get-all-app-method`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllResource = async () => {
  try {
    const url = `${SERVER_URL}/context/get-all-resource`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllCollisionCompanies = async (catId) => {
  try {
    const url = `${SERVER_URL}/general/get-all-collision-brands`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    console.log('Error fetching Collision Companies', err);
  }
};

export const getLatestRating = async (userId, catId) => {
  try {
    const url = `${SERVER_URL}/ratings/get-user-latest-rating-for-category`;
    const response = await axios.post(url, {
      userId,
      catId,
    });
    return response.data;
  } catch (err) {
    console.log('Error fetching User latest Rating', err);
  }
};

export const getAllCategoryQuestions = async (catId) => {
  try {
    const url = `${SERVER_URL}/context/get-all-rating-questions`;
    const response = await axios.post(url, {
      catId,
    });
    return response.data;
  } catch (err) {
    console.log('Error Fetching all category questions', err);
  }
};

export const getRatingsByUser = async (userId, contentId, catId) => {
  try {
    const url = `${SERVER_URL}/entertainment/get-ratings-by-user`;
    const response = await axios.post(url, {
      userId,
      contentId,
      catId,
    });
    return response.data;
  } catch (err) {
    console.log('Error Fetching Rating By User', err);
  }
};

export const getRatingsBySchool = async (school, catId) => {
  try {
    const url = `${SERVER_URL}/education/get-ratings-by-school`;
    const response = await axios.post(url, {
      school,
      catId,
    });
    return response.data;
  } catch (err) {
    console.log('Error Fetching Rating By User', err);
  }
};

export const getRatingsByURL = async (resourceUrl, catId) => {
  try {
    const url = `${SERVER_URL}/education/get-ratings-by-url`;
    const response = await axios.post(url, {
      resourceUrl,
      catId,
    });
    return response.data;
  } catch (err) {
    console.log('Error Fetching Rating By User', err);
  }
};

export const getRatingsByISBN = async (ISBN, catId) => {
  try {
    const url = `${SERVER_URL}/education/get-ratings-by-isbn`;
    const response = await axios.post(url, {
      resourceISBN: ISBN,
      catId,
    });
    return response.data;
  } catch (err) {
    console.log('Error Fetching Rating By User', err);
  }
};

export const getRatingsBySchoolAndYear = async (school, year, catId) => {
  try {
    const url = `${SERVER_URL}/education/get-ratings-by-school-year`;
    const response = await axios.post(url, {
      school,
      year,
      catId,
    });
    return response.data;
  } catch (err) {
    console.log('Error Fetching Rating By User', err);
  }
};

export const getRatingsBySchoolAndSubject = async (school, subject, catId) => {
  try {
    const url = `${SERVER_URL}/education/get-ratings-by-school-subject`;
    const response = await axios.post(url, {
      school,
      subject,
      catId,
    });
    return response.data;
  } catch (err) {
    console.log('Error Fetching Rating By User', err);
  }
};

export const getRatingsById = async (contentId, catId) => {
  try {
    const url = `${SERVER_URL}/entertainment/get-ratings-by-id`;
    const response = await axios.post(url, {
      contentId,
      catId,
    });
    return response.data;
  } catch (err) {
    console.log('Error Fetching Rating By Id', err);
  }
};

export const getLatestRatingByUser = async (userId, contentId, catId) => {
  try {
    const url = `${SERVER_URL}/entertainment/get-latest-rating-for-user`;
    const response = await axios.post(url, {
      userId,
      contentId,
      catId,
    });
    return response.data;
  } catch (err) {
    console.log('Error Fetching Latest Rating By User', err);
  }
};

export const getMyProductsData = async (userId) => {
  try {
    const url = `${SERVER_URL}/my-products/getMyProductsByUserId`;
    const response = await axios.post(url, {
      userId,
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getCategoryInformation = (cat) => {
  const output = {
    ID: '',
    title: '',
  };
  switch (cat) {
    case 'movie':
      output.ID = Category.MOVIE.ID;
      output.title = 'Movie';
      return output;
    case 'tv':
      output.ID = Category.TV.ID;
      output.title = 'Show';
      return output;
    case 'game':
      output.ID = Category.GAME.ID;
      output.title = 'Game';
      return output;
    case 'school':
      output.ID = Category.SCHOOL;
      output.title = 'School';
      return output;
    case 'class':
      output.ID = Category.CLASS;
      output.title = 'Class';
      return output;
    case 'textbook':
      output.ID = Category.TEXTBOOK;
      output.title = 'Textbook';
      return output;
    case 'onlinelearning':
      output.ID = Category.ONLINE_LEARNING;
      output.title = 'Online Learning';
      return output;
    case 'onlineresource':
      output.ID = Category.ONLINE_RESOURCE;
      output.title = 'Online Resource';
      return output;
    case 'article':
      output.ID = Category.ARTICLE;
      output.title = 'Article';
      return output;
    case 'misdis':
      output.ID = Category.MISDISINFO;
      output.title = 'Misinformation/Disinformation';
      return output;
    case 'jobprospect':
      output.ID = Category.JOB_PROSPECT;
      output.title = 'Job Prospect';
      return output;
    case 'applicationprocess':
      output.ID = Category.APPLICATION_PROCESS;
      output.title = 'Application Process';
      return output;
    case 'mentalhealth':
      output.ID = Category.MENTAL_HEALTH;
      output.title = 'Mental Health';
      return output;
    case 'collision':
      output.ID = Category.COLLISION;
      output.title = 'Collision';
      return output;
    default:
      window.location.replace('/*');
  }
};

export async function handleMyProductAddition(productData) {
  try {
    const addProductUrl = `${SERVER_URL}/my-products`;

    // eslint-disable-next-line no-unused-vars
    await axios.post(addProductUrl, JSON.stringify(productData), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    console.log(err);
  }
}

export async function handleEntertainmentAddition(ratings) {
  try {
    const postEntertainmentRatingUrl = `${SERVER_URL}/entertainment`;
    // eslint-disable-next-line no-unused-vars
    await axios.post(postEntertainmentRatingUrl, JSON.stringify(ratings), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    console.log(err);
  }
}

export async function handleEducationAddition(ratings) {
  try {
    const postEducationRatingUrl = `${SERVER_URL}/education`;

    // eslint-disable-next-line no-unused-vars
    await axios.post(postEducationRatingUrl, JSON.stringify(ratings), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    console.log(err);
  }
}

export async function handleCollisionAddition(ratings) {
  try {
    const postEducationRatingUrl = `${SERVER_URL}/general/submit-collision-rating`;

    // eslint-disable-next-line no-unused-vars
    await axios.post(postEducationRatingUrl, JSON.stringify(ratings), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    console.log('Error adding to collision DB', err);
  }
}
