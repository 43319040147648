// import Modal from 'react-bootstrap/Modal';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
export default function RatingModal({ show, handleClose }) {
  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white text-center rounded-md font-hind">
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="border-b-2 border-faded p-10"
        >
          We appreciate your rating feedback! 🎊
        </Typography>

        <Typography
          id="modal-modal-description"
          className="p-10 border-b-2 border-faded flex items-center"
        >
          Thank you for taking your valuable time to rate on KnowQuest.You can
          also give rating feedback by exploring the other categories and its
          insights.
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <button
            onClick={handleClose}
            className="bg-secondary text-white rounded p-2 m-2 cursor-pointer"
          >
            Close
          </button>
        </Typography>
      </Box>
    </Modal>
  );
}
