import React, { useState } from 'react';
import AcademicUserRegistration from './AcademicUserRegistration';
import RegistrationOptions from './RegistrationOptions';
import RegularUserRegistration from './RegularUserRegistration';

const Register = () => {
  const [registerView, setRegisterView] = useState(1);

  const registerUser = (value) => {
    setRegisterView(value);
  };
  return (() => {
    switch (registerView) {
      case 1:
        return <RegistrationOptions registerBtn={registerUser} />;
      case 2:
        return <AcademicUserRegistration registerBtn={registerUser} />;
      case 3:
        return <RegularUserRegistration registerBtn={registerUser} />;
      default:
        return <RegistrationOptions registerBtn={registerUser} />;
    }
  })();
};

export default Register;
