import $ from 'jquery';
import { jwtDecode } from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';
import { Circles } from 'react-loading-icons';
import { Rating } from 'react-simple-star-rating';
import { ReactComponent as StarsEmpty } from '../../../assets/images/StarSVGEmpty.svg';
import { ReactComponent as StarsFilled } from '../../../assets/images/StarSVGFilled.svg';
import {
  getLatestRating,
  getLatestRatingByUser,
} from '../../../util/DatabaseUtil';
import { getEducationRatingByQuestionId } from '../../../util/EducationUtil';
import {
  getEntertainmentRatingByQuestionId,
  visualRating,
} from '../../../util/EntertainmentUtil';
import Button from '../../Button';
import { RatingContext } from '../RatingPageTabs';

export default function RatingSummary({ changeReview, refresh, setRefresh }) {
  const token = localStorage.getItem('loginToken');
  const userData = token ? jwtDecode(token) : '';
  const { categoryInformation, id, resetForm } = useContext(RatingContext);
  const [latestRating, setLatestRating] = useState([]);

  useEffect(() => {
    const fetchLatestRating = async () => {
      try {
        if (id) {
          const entertainmentData = await getLatestRatingByUser(
            userData.id,
            id,
            categoryInformation.ID,
          );
          setLatestRating(entertainmentData);
        } else {
          const educationData = await getLatestRating(
            userData.id,
            categoryInformation.ID,
          );
          setLatestRating(educationData);
        }
      } catch (err) {
        console.log('Error Fetching Latest User Rating', err);
      }
    };
    fetchLatestRating();
  }, [categoryInformation.ID, userData.id, refresh, id]);

  return (
    <>
      {latestRating.ratings ? (
        <div className="bg-darkblue mx-4 md:mx-10 lg:mx-20 my-10 rounded-xl text-white p-4">
          <div className="grid grid-cols-1 gap-y-5 lg:gap-x-10 xl:gap-x-32 pt-10 mx-4 md:mx-10 lg:mx-20 place-items-center">
            {latestRating.ratings.map((ratingQue, idx) => (
              <div
                className="grid w-full max-w-md md:max-w-lg lg:max-w-xl rounded-lg bg-primary p-2"
                key={idx}
              >
                <div className="flex flex-col lg:flex-row items-center justify-between p-2 gap-2">
                  <div
                    className={`aspect-square w-8 rounded grid place-items-center ${visualRating(ratingQue.rating / 2)}`}
                  >
                    {ratingQue.rating / 2}
                  </div>
                  <label className="text-center lg:text-left lg:pl-2">
                    {ratingQue.questionTitle}
                  </label>
                  <div>
                    <Rating
                      initialValue={
                        id
                          ? getEntertainmentRatingByQuestionId(
                              ratingQue.questionId,
                              latestRating.contentId,
                              latestRating.ratings,
                              id,
                            ) / 2
                          : getEducationRatingByQuestionId(
                              ratingQue._id,
                              latestRating.ratings,
                            ) / 2
                      }
                      allowFraction
                      readonly
                      emptyIcon={<StarsEmpty />}
                      fillIcon={<StarsFilled />}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center pt-10">
            <label className="text-white">Did you recommend?</label>
            <div className="flex gap-2 justify-center mt-2">
              <button
                className={`rounded-xl w-24 h-10 border-2 border-primary ${latestRating.isRecommended === true ? 'border-none bg-secondary text-white' : 'bg-white text-black'}`}
                type="button"
                data-recommend-btn
                id="isRecommended"
                disabled
              >
                Yes
              </button>
              <button
                className={`rounded-xl w-24 h-10 border-2 border-primary ${latestRating.isRecommended === false ? 'border-none bg-secondary text-white' : 'bg-white text-black'}`}
                type="button"
                data-recommend-btn
                id="notRecommended"
                disabled
              >
                No
              </button>
            </div>
          </div>
          <div className="mt-5 flex justify-center gap-7">
            <Button
              type="button"
              variant="button"
              onClick={() => {
                changeReview();
                setRefresh(!refresh);
                resetForm();
              }}
            >
              Change Review
            </Button>

            <a href="/">
              <Button type="button" variant="button" className="px-12">
                Back
              </Button>
            </a>
          </div>
        </div>
      ) : (
        <Circles />
      )}
    </>
  );
}
