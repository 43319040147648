import React from 'react';
import error from '../../assets/images/error404.png';
import '../../assets/stylesheets/contest.css';

function ContestPage() {
  return (
    <div className="container1">
      <img src={error} style={{ width: '50%' }} />
    </div>
  );
}

export default ContestPage;
