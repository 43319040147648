import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './editQuestion.css';

function EditQuestion() {
  const navigate = useNavigate();
  const server_url = process.env.REACT_APP_API_URI;
  const location = useLocation();
  const { catId, questionId } = location.state;
  const [question, setQuestionData] = useState();
  const [updatedQuestion, setUpdatedQuestion] = useState({
    question: '',
    description: '',
  });

  useEffect(() => {
    const getQuestionById = async () => {
      try {
        const postQuestionUrl = `${server_url}/question`;
        const response = await axios.post(
          postQuestionUrl,
          JSON.stringify(location.state),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        setQuestionData(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    getQuestionById();
  }, [catId]);

  const updateQuestionForm = (value) =>
    setUpdatedQuestion((prev) => ({ ...prev, ...value }));

  const handleQuestionFormSubmit = async (e) => {
    e.preventDefault();
    const updatedQuestionDetail = { ...updatedQuestion, catId, questionId };
    try {
      const response = await axios.put(
        `${server_url}/update-question`,
        JSON.stringify(updatedQuestionDetail),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.status) {
        alert(response.data.msg);
        navigate('/admin/questions');
      } else {
        alert(response.data.msg);
      }
    } catch (err) {
      console.log(err);
    }
    setUpdatedQuestion({
      question: '',
    });
  };

  return (
    <div className="edit-question">
      <div className="questionTitleContainer">
        <h1 className="editQuestionTitle">Edit Question</h1>
      </div>
      <div className="questionTop">
        <div className="questionInfoTop">
          <span className="questionName">{question?.question}</span>
        </div>
        <div className="questionInfoBottom">
          <div className="questionInfoItem">
            <span className="questionInfoKey">Id:</span>
            <span className="questionInfoValue">{question?._id}</span>
          </div>
        </div>
      </div>
      <div className="questionBottom">
        <form className="questionForm" onSubmit={handleQuestionFormSubmit}>
          <div className="questionFormLeft">
            <label>Question Name</label>
            <input
              type="text"
              className="col-lg-2"
              placeholder={question?.question}
              onChange={(e) => updateQuestionForm({ question: e.target.value })}
              required
              minLength={3}
            />
          </div>
          <div className="questionFormLeft">
            <label>Question Description</label>
            <input
              type="text"
              className="col-lg-2"
              placeholder={question?.description}
              onChange={(e) =>
                updateQuestionForm({ description: e.target.value })
              }
              required
              minLength={0}
            />
          </div>
          <div className="questionFormRight">
            <button className="questionButton">Update</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditQuestion;
