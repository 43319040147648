import { MeetingRoom } from '@mui/icons-material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../../../../context/Authentication';
import useAuth from '../../../../hooks/useAuth';
import './topbar.css';

export default function Topbar() {
  const { auth, setAuth } = useAuth();
  const authentication = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const logout = () => {
    setAuth({});
    authentication.logout();
    localStorage.removeItem('loginToken');
    navigate('/');
  };
  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
          <a href="/" className="link">
            <span className="logo">KnowQuest</span>
          </a>
        </div>
        <div className="topRight">
          <img
            src="https://www.kindpng.com/picc/m/10-101213_profile-icon-for-the-politics-category-circle-profile.png"
            alt=""
            className="topAvatar"
          />
          {/* <span className="adminUserName">{user.username != undefined ? <span>Hello, {" " + user.username.toUpperCase()}</span> : ''}</span> */}
          <div className="topbarIconContainer">
            <MeetingRoom fontSize="large" onClick={logout} />
          </div>
        </div>
      </div>
    </div>
  );
}
