import './Category.css';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';

function Category() {
  const server_url = process.env.REACT_APP_API_URI;

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const getAllCategories = async () => {
    try {
      const url = `${server_url}/get-all-category`;
      const response = await axios.get(url);
      setCategories(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const handleCategoryDelete = async (catId) => {
    try {
      if (window.confirm('Are you sure you want to delete this category?')) {
        const response = await axios.post(
          `${server_url}/category/delete-category`,
          {
            catId,
          },
        );
        if (response.data.status) {
          alert(response.data.msg);
          await getAllCategories();
        } else {
          alert(response.data.msg);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 200,
    },
    {
      field: 'route',
      headerName: 'Route',
      width: 200,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <>
          <Link to="/admin/update-category/" state={{ catId: params.row._id }}>
            <Edit className="userListEdit" />
          </Link>
          <DeleteOutline
            className="userListDelete"
            onClick={() => handleCategoryDelete(params.row._id)}
          />
        </>
      ),
    },
  ];

  const getRowId = (category) => category._id;

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <div className="question-main-container p-2 ">
      <div className="row question-heading">
        <h3 className="question-title">Categories</h3>
        <Link to="/admin/newCategory" className="ml-auto">
          <button className="questionAddButton">Add New Category</button>
        </Link>
      </div>

      <div className="questionList">
        <DataGrid
          rows={categories}
          columns={columns}
          pageSize={10}
          onRowClick={(params) => {
            setSelectedCategory(params.row._id);
          }}
          getRowId={getRowId}
          autoHeight
        />
      </div>
    </div>
  );
}

export default Category;
