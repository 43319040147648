import axios from 'axios';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import Button from '../../../components/Button.jsx';
import './register.css';

function RegularUserRegistration({ registerBtn }) {
  const [formValues, setFormValues] = useState({
    role: 4,
    userEmail: '',
    userPassword: '',
    userConfPassword: '',
  });

  const navigate = useNavigate();
  const server_url = process.env.REACT_APP_API_URI;

  const updateFormValue = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleRegisterFormSubmit = async (e) => {
    e.preventDefault();

    // Submission logic
    const pwd = formValues.userPassword;
    const cpwd = formValues.userConfPassword;

    if (pwd === cpwd) {
      const newUser = { ...formValues };
      try {
        const response = await axios.post(
          `${server_url}/user/register`,
          newUser,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        alert(response.data);
        navigate('/login');
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Password doesn't match with confirm password.");
    }
  };

  return (
    <div className="flex flex-col gap-4 items-center p-10">
      <Button variant="button" value={1} onClick={registerBtn}>
        Back to User Registration
      </Button>
      <div>
        <h1 className="text-5xl font-bold text-secondary">User Registration</h1>
        <hr />
        <Form onSubmit={handleRegisterFormSubmit}>
          <div className="p-5 flex flex-col gap-2 items-center lg:items-start">
            <Form.Group
              className="text-faded flex flex-col lg:flex-row gap-2 items-center"
              controlId="email"
            >
              <Form.Label>Enter your Email Address</Form.Label>
              <Form.Control
                required
                className="text-black"
                type="email"
                placeholder="Enter email"
                name="userEmail"
                value={formValues.userEmail}
                onChange={updateFormValue}
                autoComplete="true"
              />
              <Form.Text className="text-faded text-xs">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>
            <Form.Group
              className="text-faded flex flex-col lg:flex-row gap-2 items-center"
              controlId="userPassword"
            >
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                className="text-black"
                type="password"
                placeholder="Password"
                autoComplete="true"
                name="userPassword"
                value={formValues.userPassword}
                onChange={updateFormValue}
              />
            </Form.Group>
            <Form.Group
              className="text-faded flex flex-col lg:flex-row gap-2 items-center"
              controlId="userConfPassword"
            >
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                required
                type="password"
                className="text-black"
                placeholder="Confirm Password"
                autoComplete="true"
                name="userConfPassword"
                value={formValues.userConfPassword}
                onChange={updateFormValue}
              />
            </Form.Group>
            <Form.Group
              className="text-faded flex flex-col lg:flex-row gap-2 items-center"
              controlId="formBasicCheckbox"
            >
              <Form.Check
                required
                type="checkbox"
                label=" I accept the Terms of Use and Privacy Policy"
              />
            </Form.Group>
            <br />
            <Button variant="button" type="submit">
              Register
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default RegularUserRegistration;
