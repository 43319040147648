import React, { useContext, useEffect, useState } from 'react';
import { RatingContext } from '../RatingPageTabs';
import RatingForm from './RatingForm';
import RatingSummary from './RatingSummary';

export default function UserTab() {
  const [refresh, setRefresh] = useState(false);
  const { rated } = useContext(RatingContext);
  const [display, setDisplay] = useState();
  useEffect(() => {
    rated
      ? setDisplay(
          <RatingSummary
            changeReview={changeReview}
            refresh={refresh}
            setRefresh={(value) => {
              setRefresh(value);
            }}
          />,
        )
      : setDisplay(
          <RatingForm
            reviewSubmitted={reviewSubmitted}
            refresh={refresh}
            setRefresh={(value) => {
              setRefresh(value);
            }}
          />,
        );
  }, [rated]);

  const changeReview = () => {
    setDisplay(
      <RatingForm
        reviewSubmitted={reviewSubmitted}
        refresh={refresh}
        setRefresh={(value) => {
          setRefresh(value);
        }}
      />,
    );
  };

  const reviewSubmitted = () => {
    setDisplay(
      <RatingSummary
        changeReview={changeReview}
        refresh={refresh}
        setRefresh={(value) => {
          setRefresh(value);
        }}
      />,
    );
  };

  return <>{display}</>;
}
