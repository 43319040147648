import './ratings.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Rating } from 'react-simple-star-rating';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

function Ratings() {
  // const server_url = 'http://localhost:4000'; //development
  const server_url = process.env.REACT_APP_API_URI; // production
  const [category, setCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    const getAllCategory = async () => {
      try {
        const url = `${server_url}/get-all-category`;
        const response = await axios.get(url);
        setCategory(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    getAllCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Using the Individual Models to set things up, maybe try using the master model
  const getAllRatings = async (cat_id) => {
    try {
      let url;
      let response;
      url = `${server_url}/fetchRatingsByCatId/${cat_id}`;
      response = await axios.get(url);
      setRatings(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <select
        defaultValue="default"
        className="cat-dropdown"
        style={{ padding: '10px', margin: '10px' }}
        value={selectedCategory}
        onChange={(e) => {
          setSelectedCategory(e.target.value);
          getAllRatings(e.target.value);
        }}
      >
        <option>Choose a Category</option>
        {category?.map((category) => (
          <option value={category._id}>{category.title}</option>
        ))}
      </select>
      <TableContainer component={Paper}>
        <Table aria-label="Admin Table">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Context</TableCell>
              <TableCell>Ratings</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ratings?.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row.userId}</TableCell>
                <TableCell>
                  {row.context?.map((item, index) => {
                    if (typeof item === 'object') {
                      const entries = Object.entries(item);
                      return (
                        <div key={index}>
                          {entries.map(([key, value]) => (
                            <div key={key}>
                              <strong>{key}: </strong>
                              {value}
                            </div>
                          ))}
                        </div>
                      );
                    }
                    return <div key={index}>{item}</div>;
                  })}
                </TableCell>
                <TableCell>
                  {row.ratings?.map((item, index) => {
                    if (typeof item === 'object') {
                      const entries = Object.entries(item);
                      return (
                        <div key={index}>
                          {entries.map((entry) => (
                            <p style={{ margin: 0 }}>
                              <b>{entry[1]?.questionTitle}</b>:
                              {entry[1]?.rating}
                              <Rating
                                readonly
                                initialValue={1}
                                allowFraction
                                size={20}
                                emptyColor="#A9A9A9"
                                fillColor="#f1a545"
                                iconsCount={1}
                              />
                            </p>
                          ))}
                        </div>
                      );
                    }
                    return <div key={index}>{item}</div>;
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Ratings;
