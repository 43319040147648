export default function AccessProductSummary({ peeked }) {
  return (
    <div className="bg-darkblue rounded-xl p-40 mx-20 my-10">
      <h2 className="font-bold font-montserrat text-6xl mb-10 text-white">
        KnowQuest Rating
      </h2>
      <h3 className="font-bold text-xl mb-3 text-faded">
        Check KnowQuest Rating After You Rate
      </h3>
      <div className="px-52 mb-8 text-faded">
        We want your honest feeback and dont want to change your judgement for
        when you make a rating. Your honesty is what we want. You can always
        come back to make a second review if your opinions change in the future.
      </div>
      <button
        className="bg-secondary py-2 px-3 rounded-lg text-white"
        onClick={() => peeked(true)}
      >
        Take a Peak 👀
      </button>
      <p className="text-faded mt-2 text-xs">
        Taking a peek at the KnowQuest will decrease the amount of points you
        will recieve
      </p>
    </div>
  );
}
