import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import { Button } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import defaultPoster from '../assets/images/kqhome.png';
import star from '../assets/images/star.png';
import { getAllCategoryQuestions } from '../util/DatabaseUtil';

function Search(props) {
  const server_url = process.env.REACT_APP_API_URI;
  const apiKey = process.env.REACT_APP_TMDB_API_KEY;
  const location = useLocation();
  const { catId } = location.state;
  const [search, setSearch] = useState('');
  const [showDiv, setShowDiv] = useState(false);

  const [showMoviesList, setShowMoviesList] = useState(true);
  const [movies, setMovies] = useState([]);
  const [currentMoviePage, setCurrentMoviePage] = useState(1);
  const [movieRatings, setMovieRatings] = useState([]);
  const [movieQuestions, setMovieQuestions] = useState([]);
  const [movieUrl, setMovieUrl] = useState('');
  const [isPushedDown, setIsPushedDown] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [showDetails, setShowDetails] = useState('button1');

  const [showTvShowsList, setShowTvShowsList] = useState(true);
  const [tvShows, setTvShows] = useState([]);
  const [currentTvShowPage, setTvShowCurrentPage] = useState(1);
  // const [TvShowsPerPage, setTvShowsPerPage] = useState(20);
  const [TvShowRatings, setTvShowRatings] = useState([]);
  const [TvShowQuestions, setTvShowQuestions] = useState([]);
  const [TvShowsLength, setTvShowsLength] = useState(1);
  const [tvShowUrl, setTvShowUrl] = useState('');

  const navigate = useNavigate();

  const items = ['All', 'School', 'Movies', 'TV Shows', 'Books', 'Games'];

  const getPosterPath = (path) =>
    path ? `https://image.tmdb.org/t/p/original/${path}` : defaultPoster;

  useEffect(() => {
    const getMovies = async () => {
      try {
        const searchMovieUrl = `https://api.themoviedb.org/3/search/movie?api_key=${apiKey}&query=${search}&page=${currentMoviePage}`;

        const searchTvShowUrl = `https://api.themoviedb.org/3/search/tv?api_key=${apiKey}&query=${search}&page=${currentMoviePage}`;
        // const nowPlayingUrl =
        //   `https://api.themoviedb.org/3/movie/now_playing?api_key=${apiKey}&language=en-US&page=` +
        //   currentMoviePage;
        setMovieQuestions(await getAllCategoryQuestions(catId));
        const fetchRatingsUrl = `${server_url}/fetchMovieRatingsByCatId/${catId}`;
        let responseMovie = '';
        let responseTvShow = '';

        if (search) {
          setMovieUrl(searchMovieUrl);
          responseMovie = await axios.get(searchMovieUrl);
          setTvShowUrl(searchTvShowUrl);
          responseTvShow = await axios.get(searchTvShowUrl);
          setShowMoviesList(true);
          setShowTvShowsList(true);
        } else {
          // setMovieUrl(nowPlayingUrl);
          // responseMovie = await axios.get(nowPlayingUrl);
        }

        // const moviesData = responseMovie.data.results.filter(
        //  (movie) => movie.poster_path !== null
        // );
        const moviesData = responseMovie.data.results;
        setMovies(moviesData);
        const ratingsMovie = await axios.get(fetchRatingsUrl);
        setMovieRatings(ratingsMovie.data);
        const tvShowData = responseTvShow.data.results;
        setTvShows(tvShowData);
        const ratingsTvShow = await axios.get(fetchRatingsUrl);
        setMovieRatings(ratingsTvShow.data);
      } catch (err) {
        console.log(err);
      }
    };
    getMovies();
  }, [currentMoviePage, movieUrl, currentTvShowPage, tvShowUrl, search]);

  const paginateMovie = (pageNumber) => setCurrentMoviePage(pageNumber);
  const paginateTvShow = (pageNumber) => setTvShowCurrentPage(pageNumber);

  const filterMovies = (title) => {
    if (title) {
      setSearch(title);
      setCurrentMoviePage(1);
    } else {
      setSearch('');
    }
  };

  const filterTvShows = (title) => {
    if (title) {
      setSearch(title);
      setTvShowCurrentPage(1);
    } else {
      setSearch('');
    }
  };

  const handleChange = (e) => {
    // setSearchMovie(e.target.value);
    filterMovies(e.target.value);
    filterTvShows(e.target.value);
  };

  // Calculate the average rating for questions
  function getAvgRatingByQuestionId(catID, itemID, questionId) {
    let countRating = 0;
    let avgRating;
    let totalRating = 0;
    switch (catID) {
      case 'Movies':
        movieRatings
          .filter((rat) => itemID === rat.movieId)
          .map((r) => {
            r.ratings.map((rt) => {
              if (rt.questionId === questionId) {
                totalRating += rt.rating;
              }
              return null;
            });
            countRating += 1;
          });
        break;

      case 'Tv Shows':
        TvShowRatings.filter((rat) => itemID === rat.tvId).map((r) => {
          r.ratings.map((rt) => {
            if (rt.questionId === questionId) {
              totalRating += rt.rating;
            }
            return null;
          });
          countRating += 1;
        });
        break;

      default:
        break;
    }

    avgRating = totalRating / countRating;
    return avgRating;
  }

  // Calculate average rating
  function getAvgRating(catId, itemId) {
    let avgRating = 0;
    let totalRating = 0;
    let totAvgRating = 0;
    let count = 0;

    switch (catId) {
      case 'Movies':
        movieQuestions.map((q) => {
          totalRating += getAvgRatingByQuestionId('Movies', itemId, q._id);
          if (getAvgRatingByQuestionId('Movies', itemId, q._id) > 0) {
            count++;
          }
          return null;
        });

        if (count === 0) {
          avgRating = 0;
        } else {
          avgRating = totalRating / count;
        }

        if (movieRatings.filter((rat) => itemId === rat.movieId).length !== 0) {
          totAvgRating = avgRating;
        } else {
          totAvgRating = 0;
        }
        break;

      case 'Tv Shows':
        TvShowQuestions.map((q) => {
          totalRating += getAvgRatingByQuestionId('Tv Show', itemId, q._id);
          if (getAvgRatingByQuestionId(itemId, q._id) > 0) {
            count++;
          }
          return null;
        });

        if (count === 0) {
          avgRating = 0;
        } else {
          avgRating = totalRating / count;
        }

        if (TvShowRatings.filter((rat) => itemId === rat.tvId).length !== 0) {
          totAvgRating = avgRating;
        } else {
          totAvgRating = 0;
        }
        break;
      default:
        break;
    }

    // avgRating = totalRating / movieQuestions.length;
    return totAvgRating;
  }

  function handlePushedButtonClick() {
    setIsPushedDown(!isPushedDown);
    setIsContentVisible(!isContentVisible);
    setShowDiv(!showDiv);
  }

  const handleClick = (buttonName) => {
    switch (buttonName) {
      case 'School':
        setShowMoviesList(false);
        setShowTvShowsList(false);
        break;
      case 'Movies':
        setShowMoviesList(true);
        setShowTvShowsList(false);
        break;
      case 'TV Shows':
        setShowMoviesList(false);
        setShowTvShowsList(true);
        break;

      case 'Books':
        setShowMoviesList(false);
        setShowTvShowsList(false);
        break;
      case 'Games':
        setShowMoviesList(false);
        setShowTvShowsList(false);
        break;

      default:
        setShowMoviesList(true);
        setShowTvShowsList(true);
        break;
    }
  };

  return (
    <div className="container-fluid">
      <div className="row searchDiv">
        <input
          type="search"
          placeholder="Search for Movie or TV"
          value={search}
          className="advanced-searchbar"
          onChange={handleChange}
        />
      </div>
      <div>
        <div>
          {search && (
            <div>
              <button className="filterbtn" onClick={handlePushedButtonClick}>
                {isContentVisible ? 'Filter' : 'Filter'}
              </button>
              {isContentVisible && (
                <div className={`animated-div ${showDiv ? 'visible' : ''}`}>
                  <div className="category-text">Category</div>
                  {items.map((items, idx) => (
                    <button
                      className="items"
                      key={idx}
                      value={items}
                      selected={Search.items === items}
                      onClick={() => handleClick(items)}
                    >
                      {items}
                    </button>
                  ))}
                </div>
              )}
              {showDetails === 'All' && <p>Content for All</p>}
              {showDetails === 'School' && <p>Content for School</p>}
              {showMoviesList && (
                <div className="row tableDisplay">
                  <table className="table">
                    <thead className="thead-color">
                      <tr>
                        <th scope="col">Movie</th>
                        <th scope="col" />
                        <th scope="col">Ratings</th>
                        <th scope="col">Rate</th>
                      </tr>
                    </thead>
                    {movies.map(
                      (
                        movie, // <tbody key={movie.id}>
                      ) => (
                        <tbody>
                          <tr>
                            <NavLink
                              to={`/movie-details/${movie.id}`}
                              state={{
                                catId: process.env.REACT_APP_CATID_MOVIE,
                              }}
                            >
                              <td>
                                <img
                                  className="advanced-img"
                                  // src={`https://image.tmdb.org/t/p/original/${movie.poster_path}`}
                                  src={getPosterPath(movie.poster_path)}
                                  alt=""
                                />
                              </td>
                            </NavLink>
                            <td className="tableBody">
                              <div>
                                <NavLink
                                  to={`/movie-details/${movie.id}`}
                                  state={{
                                    catId: process.env.REACT_APP_CATID_MOVIE,
                                  }}
                                  className="advance-search-title"
                                >
                                  {movie.title}
                                </NavLink>

                                <div className="advanced-overview">
                                  {movie.overview}
                                </div>
                              </div>
                            </td>
                            <td className="tableBody">
                              <img
                                src={star}
                                alt="Not available"
                                className="star-img"
                              />
                              {getAvgRating('Movies', movie?.id) !== 0 ? (
                                <span>
                                  {Math.abs(
                                    getAvgRating('Movies', movie?.id),
                                  ).toFixed(1)}
                                  /10
                                </span>
                              ) : (
                                '-/10'
                              )}
                            </td>
                            <td className="tableBody">
                              <button
                                type="submit"
                                className="advanced-button"
                                onClick={() =>
                                  navigate(`/rate/movie/${movie.id}`, {
                                    state: {
                                      catId: process.env.REACT_APP_CATID_MOVIE,
                                    },
                                  })
                                }
                              >
                                Rate This Movie
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ),
                    )}
                  </table>

                  {/* <Pagination
                    moviesPerPage={moviesPerPage}
                    totalMovies={moviesLength}
                    paginate={paginateMovie}
                    catId={process.env.REACT_APP_CATID_MOVIE}
                  /> */}
                </div>
              )}
              {showTvShowsList && (
                <div className="row tableDisplay">
                  <table className="table">
                    <thead className="thead-color">
                      <tr>
                        <th scope="col">Tv Show</th>
                        <th scope="col" />
                        <th scope="col">Ratings</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    {tvShows.map(
                      (
                        show, // <tbody key={show.id}>
                      ) => (
                        <tbody>
                          <tr>
                            <NavLink
                              to={`/tvshow-details/${show.id}`}
                              state={{ catId: process.env.REACT_APP_CATID_TV }}
                            >
                              <td>
                                <img
                                  className="searchmovieImg"
                                  // src={`https://image.tmdb.org/t/p/original/${show.poster_path}`}
                                  src={getPosterPath(show.poster_path)}
                                  alt=""
                                />
                              </td>
                            </NavLink>
                            <td className="tableBody">
                              <div>
                                <NavLink
                                  to={`/tvshow-details/${show.id}`}
                                  state={{
                                    catId: process.env.REACT_APP_CATID_TV,
                                  }}
                                  className="advance-search-title"
                                >
                                  {show.name}
                                </NavLink>
                                <div className="advanced-overview">
                                  {show.overview}
                                </div>
                              </div>
                            </td>
                            <td className="tableBody">
                              <img
                                src={star}
                                alt="Not available"
                                className="star-img"
                              />
                              {getAvgRating('Tv Show', show?.id) !== 0 ? (
                                <span>
                                  {Math.abs(
                                    getAvgRating('Tv Show', show?.id),
                                  ).toFixed(1)}
                                  /10
                                </span>
                              ) : (
                                '-/10'
                              )}
                            </td>
                            <td className="tableBody">
                              <button
                                type="submit"
                                className="advanced-button"
                                onClick={() =>
                                  navigate(`/rate/tvshow/${show.id}`, {
                                    state: {
                                      catId: process.env.REACT_APP_CATID_TV,
                                    },
                                  })
                                }
                              >
                                Rate This Tv Show
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      ),
                    )}
                  </table>

                  {/* <Pagination
                    moviesPerPage={TvShowsPerPage}
                    totalMovies={TvShowsLength}
                    paginate={paginateTvShow}
                    catId={process.env.REACT_APP_CATID_TV}
                  /> */}
                </div>
              )}
              {showDetails === 'Books' && <p>Content for Books</p>}
              {showDetails === 'Games' && <p>Content for Games</p>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Search;
