import { useEffect, useState } from 'react';
import { getRecentGames } from '../../../util/apis/GameAPI';
import { getNowPlayingMovie } from '../../../util/apis/MovieAPI';
import { getPlayingThisWeekTV } from '../../../util/apis/TvAPI';
export default function RandomTab() {
  const [content, setContent] = useState({
    movie: [],
    tv: [],
    game: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('start');
        const promises = [
          getNowPlayingMovie.get(true),
          getPlayingThisWeekTV.get(true),
          getRecentGames.get(true),
        ];
        console.log(promises);
        const [movieData, tvData, gameData] = await Promise.all(promises);

        const shows = tvData.results.splice(0, 5);
        const movies = movieData.results.splice(0, 5);
        const games = gameData.results.splice(0, 4);
        setContent({
          movie: movies,
          tv: shows,
          game: games,
        });
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);

  console.log(content);

  return (
    <>
      <div className="flex flex-col gap-8 p-4 md:p-10">
        <h2 className="text-faded text-2xl md:text-3xl">Trending Movies</h2>
        <div className="flex justify-start xl:justify-center gap-4 md:gap-8 p-4 overflow-x-auto overflow-y-clip">
          {content.movie.map((item, idx) => (
            <a
              href={`/rate/movie/${item.id}`}
              key={idx}
              className="relative mb-6 lg:mb-10 w-32 md:w-48 lg:w-64 flex-shrink-0"
            >
              <span className="absolute top-2 md:top-4 left-0 -rotate-45 bg-secondary text-white py-0.5 px-2 md:px-4 rounded-lg text-xs">
                Movie
              </span>
              <img
                src={`https://image.tmdb.org/t/p/original/${item.poster_path}`}
                className="rounded-t-xl border-2 aspect-poster w-full"
                loading="lazy"
              />
              <div className="bg-white rounded-b-xl absolute w-full p-1 text-nowrap overflow-hidden whitespace-nowrap text-ellipsis">
                {item.title}
              </div>
            </a>
          ))}
        </div>
        <h2 className="text-faded text-2xl md:text-3xl">Trending Shows</h2>
        <div className="flex justify-start xl:justify-center gap-4 md:gap-8 p-4 overflow-x-auto overflow-y-clip">
          {content.tv.map((item, idx) => (
            <a
              href={`/rate/tv/${item.id}`}
              key={idx}
              className="relative mb-6 lg:mb-10 w-32 md:w-48 lg:w-64 flex-shrink-0"
            >
              <span className="absolute top-2 md:top-4 left-0 -rotate-45 bg-secondary text-white py-0.5 px-2 md:px-4 rounded-lg text-xs">
                TV
              </span>
              <img
                src={`https://image.tmdb.org/t/p/original/${item.poster_path}`}
                className="rounded-t-xl border-2 aspect-poster w-full"
                loading="lazy"
              />
              <div className="bg-white rounded-b-xl absolute w-full p-1 text-nowrap overflow-hidden whitespace-nowrap text-ellipsis">
                {item.name}
              </div>
            </a>
          ))}
        </div>
        <h2 className="text-faded text-2xl md:text-3xl">Recent Games</h2>
        <div className="flex justify-start xl:justify-center gap-4 md:gap-8 p-4 overflow-x-auto overflow-y-clip">
          {content.game.map((item, idx) => (
            <a
              href={`/rate/game/${item.id}`}
              key={idx}
              className="relative mb-6 lg:mb-10 w-56 md:w-72 lg:w-96 flex-shrink-0"
            >
              <span className="absolute top-2 md:top-4 left-0 -rotate-45 bg-secondary text-white py-0.5 px-2 md:px-4 rounded-lg text-xs">
                Game
              </span>
              <img
                src={item.background_image}
                className="rounded-t-xl border-2 aspect-video w-full"
                loading="lazy"
              />
              <div className="bg-white rounded-b-xl absolute w-full p-1 text-nowrap overflow-hidden whitespace-nowrap text-ellipsis">
                {item.name}
              </div>
            </a>
          ))}
        </div>
      </div>
    </>
  );
}
