import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useLocation } from 'react-router';
import { Rating } from 'react-simple-star-rating';
import { ReactComponent as StarsEmpty } from '../../assets/images/StarSVGEmpty.svg';
import { ReactComponent as StarsFilled } from '../../assets/images/StarSVGFilled.svg';
import { SERVER_URL } from '../../util/EnvironmentConfig';

function Survey() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const surveyId = params.get('s');
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [showDescription, setShowDescription] = useState(false);
  const [show, setShow] = useState(false);
  const [userAnswers, setUserAnswer] = useState({
    answers: [],
  });

  useEffect(() => {
    fetchSurvey();
  }, [surveyId]);

  const fetchSurvey = async () => {
    try {
      const url = `${SERVER_URL}/survey`;
      await axios
        .get(url, {
          params: {
            s: surveyId, // survey id
          },
        })
        .then((response) => {
          setSurveyQuestions(response.data.questions);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleMouseEnter = (questionId) => {
    setShowDescription((prevState) => ({ ...prevState, [questionId]: true }));
  };

  const handleMouseLeave = (questionId) => {
    setShowDescription((prevState) => ({ ...prevState, [questionId]: false }));
  };

  // Updates Star ratings on each question
  const handleRating = (qId, value) =>
    setUserAnswer((prev) => ({
      ...prev,
      answers: userAnswers.answers.map((x) =>
        x.questionId === qId ? { ...x, answer: value * 2 } : x,
      ),
    }));

  const handleRatingSubmit = async (e) => {
    e.preventDefault();
    setShow(true);
  };

  const handleClose = async () => {
    // checkRated(true);
    setShow(false);
  };

  return (
    <div className="container" style={{ padding: '30px', marginTop: '8rem' }}>
      <Form onSubmit={handleRatingSubmit}>
        {surveyQuestions.map((question, idx) => (
          <div className="question-container" key={idx}>
            <label
              className="rating-form-input-label"
              data-tip={question.desc}
              data-place="right"
              data-effect="solid"
            >
              {question.title}{' '}
              <span
                style={{
                  textDecoration: 'underline',
                  cursor: 'help',
                }}
                onMouseEnter={() => handleMouseEnter(question._id)}
                onMouseLeave={() => handleMouseLeave(question._id)}
              >
                [?]
              </span>
              {showDescription[question._id] && (
                <span
                  style={{
                    color: '#44444',
                    backgroundColor: 'white',
                    fontSize: '.7rem',
                    padding: '5px',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.2)',
                    zIndex: 1,
                    width: 'auto',
                    position: 'absolute',
                  }}
                >
                  {question.desc}
                </span>
              )}
            </label>
            <div className="ratingsbar-title-label">
              <Rating
                onClick={(rate) => handleRating(question._id, rate)}
                // transition
                ratingValue={question.question * 20}
                allowFraction
                emptyIcon={<StarsEmpty />}
                fillIcon={<StarsFilled />}
              />
            </div>
          </div>
        ))}
        <button className="rating-submit-btn" type="submit" id="submit-button">
          Submit
        </button>
      </Form>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>We appreciate your rating feedback! 🎊</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Thank you for taking your valuable time to rate on KnowQuest.You can
          also give rating feedback by exploring the other categories and its
          insights.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Survey;
