import React from 'react';

function Contact() {
  return (
    <div
      className="container"
      style={{ padding: '30px', marginBottom: '40px' }}
    >
      <div className="row">
        <div className="col-lg">
          <div className="contact_form">
            <div className="contact_info_title">
              <h1 style={{ textAlign: 'left' }}>Contact Us</h1>
            </div>
            <strong>Reach out to us to get more information, or if:</strong>
            <br />
            <ul>
              <li>
                You are a textbook publisher, online content creator, or author,
                and want your products represented in our growing database
                <br />
              </li>
              <li>
                You have any questions about our platform or services
                <br />
              </li>
              <li>
                You want to join our growing list of Partners offering users a
                discounts
                <br />
              </li>
              <li>
                You want to suggest changes or report an error (attach a
                screenshot if possible!)
                <br />
              </li>
              <li>
                Your school email is not working on the registration page
                <br />
              </li>
              <li>Your school isn’t listed on the registration page</li>
              <li>
                You want to learn more about our contests and partnership
                program
              </li>
              Any other Questions, Comments, or Concerns!
            </ul>
            <form
              method="post"
              action="https://knowquest.net/contact"
              encType="multipart/form-data"
              className="comment_form"
            >
              <div className="form-group">
                <div className="form_title">Name</div>
                <input
                  type="text"
                  name="name"
                  className="comment_input form-control"
                  required="required"
                />
              </div>
              <div className="form-group">
                <div className="form_title">Email</div>
                <input
                  type="email"
                  name="email"
                  className="comment_input form-control"
                  required="required"
                />
              </div>
              <div className="form-group">
                <div className="form_title">Photo</div>
                <input
                  type="file"
                  name="photo"
                  className="comment_input form-control"
                />
              </div>
              <div className="form-group">
                <div className="form_title">Message</div>
                <textarea
                  name="message"
                  className="comment_input comment_textarea form-control"
                  required="required"
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="comment_button trans_200 btn px-5"
                  style={{
                    backgroundColor: '#FF9933',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
