import $ from 'jquery';
import { jwtDecode } from 'jwt-decode';
import { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  getAllCategoryQuestions,
  getCategoryInformation,
  getRatingsByUser,
} from '../../util/DatabaseUtil';
import Button from '../Button';
import KnowQuestTab from './rating/KnowQuestTab';
import UserTab from './rating/UserTab';
export const RatingContext = createContext();

export default function RatingPageTabs({ content }) {
  const token = localStorage.getItem('loginToken');
  const userData = token ? jwtDecode(token) : '';
  const [activeTab, setActiveTab] = useState('user');
  const { cat, id, title } = useParams();
  const [rated, setRated] = useState();
  const [questions, setQuestions] = useState([]);
  const [peek, setPeek] = useState(false);
  const categoryInformation = getCategoryInformation(cat);
  // Initialize Form
  const [formData, setFormData] = useState({
    userId: '',
    contentName: '',
    contentId: '',
    backdrop_path: '',
    catId: '',
    timestamp: '',
    ratings: [],
    peeked: peek,
  });

  // Check if user has already rated the product
  useEffect(() => {
    const fetchRatingByUser = async () => {
      try {
        await getRatingsByUser(userData.id, id, categoryInformation.ID).then(
          (data) => {
            setRated(data);
          },
        );
      } catch (err) {
        console.log('Error Fetching User Rating', err);
      }
    };

    fetchRatingByUser();
  }, [activeTab]);

  useEffect(() => {
    const getQuestions = async () => {
      try {
        await getAllCategoryQuestions(categoryInformation.ID).then(
          (questions) => {
            setQuestions(questions);
            questions.forEach((question) => {
              setFormData((prev) => ({
                ...prev,
                ratings: [
                  ...prev.ratings,
                  {
                    questionId: question._id,
                    questionTitle: question.question,
                    rating: 0,
                    description: question.description,
                  },
                ],
              }));
            });
          },
        );
      } catch (err) {
        console.log('Error Fetching Category Questions', err);
      }
    };
    getQuestions();
  }, []);

  const resetForm = () => {
    setFormData((prev) => ({
      ...prev,
      ratings: [],
    }));
    questions.forEach((question) => {
      setFormData((prev) => ({
        ...prev,
        ratings: [
          ...prev.ratings,
          {
            questionId: question._id,
            questionTitle: question.question,
            rating: 0,
            description: question.description,
          },
        ],
      }));
    });
  };

  const handleTabs = (value) => {
    setActiveTab(value);
  };

  return (
    <>
      <div className="px-8 md:px-28 sm:mt-10">
        <ul className="flex items-center justify-center lg:justify-start gap-20 text-white font-hind border-b-2 font-bold border-border rounded-sm text-2xl">
          <li>
            <Button
              type="button"
              variant="tab"
              isActive={activeTab === 'user'}
              onClick={(e) => handleTabs('user')}
            >
              Your Rating
            </Button>
          </li>
          <li className="p-3 md:p-5" id="knowquest">
            <Button
              type="button"
              variant="tab"
              isActive={activeTab === 'knowquest'}
              onClick={(e) => handleTabs('knowquest')}
            >
              KnowQuest Rating
            </Button>
          </li>
        </ul>
      </div>
      <div className="text-center">
        <RatingContext.Provider
          value={{
            formData,
            setFormData,
            resetForm,
            categoryInformation,
            id,
            title,
            content,
            cat,
            rated,
          }}
        >
          {activeTab === 'user' ? (
            <UserTab />
          ) : activeTab === 'knowquest' ? (
            <KnowQuestTab />
          ) : (
            <UserTab />
          )}
        </RatingContext.Provider>
      </div>
    </>
  );
}
