import { useEffect, useState } from 'react';
import { Circles } from 'react-loading-icons';
import { useParams } from 'react-router';
import { getTVById } from '../../../util/apis/TvAPI';
export default function TvRatingHeader({content}) {
  return (
    <>
      {content && content.genres ? (
        <div className="flex justify-center lg:justify-start lg:ml-48 gap-x-14 p-10 flex-wrap lg:flex-nowrap">
          <img
            src={`https://image.tmdb.org/t/p/original/${content.poster_path}`}
            className={'rounded-xl border-2 aspect-poster w-64 sm:w-80'}
            loading="lazy"
          />
          <div>
            <div className="grid gap-y-4">
              <h2 className="text-white font-bold text-4xl sm:text-5xl font-hind text-center lg:text-left pt-6 lg:pt-0">
                {content.name}
              </h2>
              <h3 className="lg:w-2/3 text-faded font-hind text-sm sm:text-lg text-center lg:text-left">
                {content.overview}
              </h3>
              <h4 className="lg:w-2/3 text-faded font-hind text-lg text-center lg:text-left">
                <span className="font-bold">Release Date</span>:{' '}
                {content.first_air_date}
              </h4>

              <>
                <h5 className="lg:w-2/3 text-faded font-hind text-lg text-center lg:text-left">
                  <span className="font-bold">Seasons</span>:{' '}
                  {content.number_of_seasons}
                </h5>
                <h5 className="lg:w-2/3 text-faded font-hind text-lg text-center lg:text-left">
                  <span className="font-bold">Episodes</span>:{' '}
                  {content.number_of_episodes}
                </h5>
              </>

              <div className="flex gap-x-6 justify-center lg:justify-start">
                {content.genres.map((genre) => (
                  <div className="rounded-3xl grid place-items-center text-center text-sm font-hind text-faded p-3 bg-blue-950">
                    {genre.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Circles />
      )}
    </>
  );
}
