import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import Button from '../../../components/Button';

import './register.css';

function AcademicUserRegistration({ registerBtn }) {
  const [formValues, setFormValues] = useState({
    role: 0,
    subject: '',
    school: '',
    userEmail: '',
    userPassword: '',
    userConfPassword: '',
  });

  const [schools, setSchools] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const navigate = useNavigate();
  const server_url = process.env.REACT_APP_API_URI;

  useEffect(() => {
    async function fetchData() {
      try {
        const [schoolResponse, subjectResponse] = await Promise.all([
          axios.get(`${server_url}/universities`),
          axios.get(`${server_url}/context/get-all-subject`),
        ]);
        setSchools(schoolResponse.data);
        setSubjects(subjectResponse.data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData().then(() => {});
  }, [server_url]);

  const updateFormValue = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleRegisterFormSubmit = async (e) => {
    e.preventDefault();

    // Submission logic
    const pwd = formValues.userPassword;
    const cpwd = formValues.userConfPassword;
    const email = formValues.userEmail;
    const emailSuffix = email.split('@')[1];
    let selectedSchool;

    const getSchoolEmailDomains = () => {
      const selectedSchoolDomain = schools.find((school) =>
        school.domains.includes(emailSuffix),
      );
      if (selectedSchoolDomain) {
        selectedSchool = selectedSchoolDomain.name;
        return selectedSchoolDomain.domains;
      }
      return [];
    };

    const schoolEmailDomains = getSchoolEmailDomains();

    if (pwd === cpwd) {
      if (schoolEmailDomains.includes(emailSuffix)) {
        const newUser = { ...formValues, school: selectedSchool };
        try {
          const response = await axios.post(
            `${server_url}/user/register`,
            newUser,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          );
          alert(response.data);
          navigate('/login');
        } catch (err) {
          console.log(err);
        }
        // setFormValues({
        //   role: 0,
        //   subject: '',
        //   school: '',
        //   userEmail: '',
        //   userPassword: '',
        //   userConfPassword: '',
        // });
      } else {
        alert(
          'Invalid School Email Address, please contact us to add it to our list',
        );
      }
    } else {
      alert("Password doesn't match with confirm password.");
    }
  };

  return (
    <div className="flex flex-col lg:flex-row items-center justify-center gap-8 p-10">
      <h1 className="text-secondary text-5xl font-bold">
        Academic User Registration
      </h1>
      <div className="bg-darkblue rounded-xl p-10 mx-5 flex flex-col items-center gap-8">
        <Button variant="button" value={1} onClick={registerBtn}>
          Back to User Registration
        </Button>

        <Form
          onSubmit={handleRegisterFormSubmit}
          className="flex flex-col items-center lg:items-start gap-4"
        >
          <Form.Group
            className="text-faded flex flex-col lg:flex-row gap-2 items-center"
            controlId="role"
          >
            <Form.Label>I'm a</Form.Label>
            <select
              name="role"
              className="text-black"
              required
              onChange={updateFormValue}
              value={formValues.role}
            >
              <option value="">--- Select Role ---</option>
              <option value={2}>Student</option>
              <option value={3}>Faculty Member</option>
            </select>
          </Form.Group>
          <Form.Group
            className="text-faded flex flex-col lg:flex-row gap-2 items-center"
            controlId="subject"
          >
            <Form.Label>Select your area of study</Form.Label>
            <select
              name="subject"
              className="text-black"
              required
              onChange={updateFormValue}
              value={formValues.subject}
            >
              <option value="">--- Select Subject ---</option>
              {subjects.map((subject) => (
                <option key={subject._id} value={subject.description}>
                  {subject.description}
                </option>
              ))}
            </select>
          </Form.Group>
          <Form.Group
            className="text-faded flex flex-col lg:flex-row gap-2 items-center"
            controlId="email"
          >
            <Form.Label>
              Enter your <b>school</b> email address
            </Form.Label>
            <Form.Control
              required
              type="email"
              // id='email'
              placeholder="Enter email"
              name="userEmail"
              value={formValues.userEmail}
              onChange={updateFormValue}
              autoComplete="true"
            />
            <Form.Text className="text-faded">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Form.Group
            className="text-faded flex flex-col lg:flex-row gap-2 items-center"
            controlId="userPassword"
          >
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              type="password"
              // id='userPassword'
              placeholder="Password"
              autoComplete="true"
              name="userPassword"
              value={formValues.userPassword}
              onChange={updateFormValue}
            />
          </Form.Group>
          <Form.Group
            className="text-faded flex gap-2 flex-col lg:flex-row items-center"
            controlId="userConfPassword"
          >
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              required
              type="password"
              // id='userPassword'
              placeholder="Confirm Password"
              autoComplete="true"
              name="userConfPassword"
              value={formValues.userConfPassword}
              onChange={updateFormValue}
            />
          </Form.Group>
          <Form.Group className="text-faded" controlId="formBasicCheckbox">
            <Form.Check
              required
              type="checkbox"
              label=" I accept the Terms of Use and Privacy Policy"
            />
          </Form.Group>
          <Form.Text className="text-faded text-center lg:text-start">
            We need to use your school email address to ensure that only
            students and professors / instructors use the platform. If your
            email is different, or doesn't use your school's email suffix,
            Contact Us and we will help!
          </Form.Text>
          <br />
          <Button variant="button" type="submit">
            Register
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default AcademicUserRegistration;
