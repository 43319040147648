import {
  AccessibilityNew,
  MailOutline,
  SchoolOutlined,
  SubjectRounded,
} from '@mui/icons-material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './user.css';

function User() {
  const server_url = process.env.REACT_APP_API_URI; // development
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [userForm, setUserForm] = useState({
    email: '',
    school: '',
    subject: '',
    role: '',
    password: '',
  });

  useEffect(() => {
    const getUserById = async () => {
      try {
        const url = `${server_url}/user/get-user`;
        const response = await axios.post(url, {
          id,
        });
        setUser(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    getUserById();
  }, [id, server_url]);

  const updateUserForm = (value) =>
    setUserForm((prev) => ({ ...prev, ...value }));

  const handleUserFormSubmit = async (e) => {
    e.preventDefault();
    const updatedUser = { ...userForm };
    try {
      const response = await axios.post(
        `${server_url}/update-user`,
        JSON.stringify(updatedUser),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.status) {
        alert(response.data.msg);
        navigate('/admin/users');
      } else {
        alert(response.data.msg);
      }
    } catch (err) {
      console.log(err);
    }
    setUserForm({
      id: '',
      name: '',
      phoneNumber: '',
      password: '',
    });
  };

  return (
    <div className="user">
      <div className="userTitleContainer">
        <h1 className="userTitle">User Info</h1>
      </div>
      <div className="userContainer">
        <div className="userShow">
          <div className="userShowTop">
            <img
              src="https://www.citypng.com/public/uploads/preview/png-round-blue-contact-user-profile-icon-11639786938sxvzj5ogua.png"
              alt=""
              className="userShowImg"
            />
            <div className="userShowTopTitle">
              <span className="userShowUsername">{user?.email}</span>
            </div>
          </div>
          <div className="userShowBottom">
            <span className="userShowTitle">Personal Details</span>
            <div className="userShowInfo">
              <SchoolOutlined className="userShowIcon" />
              <span className="userShowInfoTitle">{user?.school}</span>
            </div>
            <div className="userShowInfo">
              <SubjectRounded className="userShowIcon" />
              <span className="userShowInfoTitle">{user?.subject}</span>
            </div>
            <div className="userShowInfo">
              <AccessibilityNew className="userShowIcon" />
              <span className="userShowInfoTitle">
                {user?.role === 1
                  ? 'Admin'
                  : user?.role === '2'
                    ? 'Student'
                    : user?.role === '3'
                      ? 'Faculty'
                      : user?.role === '4'
                        ? 'Guest User'
                        : ''}
              </span>
            </div>
            <span className="userShowTitle">Contact Details</span>
            <div className="userShowInfo">
              <MailOutline className="userShowIcon" />
              <span className="userShowInfoTitle">{user?.email}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default User;
