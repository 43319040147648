import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import React, { useContext, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { ROLES } from '../../../App';
import { AuthenticationContext } from '../../../context/Authentication';
import useAuth from '../../../hooks/useAuth';
import './login.css';

function Login() {
  const authentication = useContext(AuthenticationContext);
  const { setAuth } = useAuth();
  const [userInfo, setUserInfo] = useState({});
  const [showloginButton, setShowloginButton] = useState(true);
  const server_url = process.env.REACT_APP_API_URI;
  const googleClientID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

  const [loginForm, setLoginForm] = useState({
    userEmail: '',
    userPassword: '',
  });
  const navigate = useNavigate();

  const updateLoginForm = (value) =>
    setLoginForm((prev) => ({ ...prev, ...value }));

  // Google Login check
  const checkLogin = async (user) => {
    try {
      console.log("user", user);
      const response = await axios.post(
        `${server_url}/user/google-login`,
        JSON.stringify({
          name: user.name,
          email: user.email,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      console.log('login res', response);
      if (response.data.isLoggedIn === true) {
        const data = jwtDecode(response.data.token);
        const { token } = response.data;
        const roles = data.role;
        const { email } = data;
        setAuth({ email, roles, token });
        authentication.login();
        localStorage.setItem('loginToken', response.data.token);
        if (roles === ROLES.Admin) {
          navigate('/admin');
        } else {
          navigate('/');
        }
      } else {
        alert(response.data.msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleLoginFormSubmit = async (e) => {
    // by default it will submit the form, so prevent it using preventDefault method
    e.preventDefault();
    const loginUser = { ...loginForm };
    try {
      const response = await axios.post(
        `${server_url}/user/login`,
        JSON.stringify(loginUser),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.isLoggedIn === true) {
        const data = jwtDecode(response.data.token);
        const { token } = response.data;
        const roles = data.role;
        const { email } = data;
        setAuth({ email, roles, token });
        authentication.login();
        localStorage.setItem('loginToken', response.data.token);
        if (data.role === ROLES.Admin) {
          navigate('/admin');
        } else {
          navigate('/');
        }
      } else {
        alert(response.data.msg);
      }
    } catch (err) {
      console.log(err);
    }
    setLoginForm({
      userEmail: '',
      userPassword: '',
    });
  };

  return (
    <div className="relative font-montserrat w-full h-full flex justify-center items-center flex-wrap gap-4 sm:gap-4 lg:gap-20 p-20">
      <div className="w-96 flex items-center text-6xl font-extrabold lg:h-96">
        <h2 className="grid gap-2 mb-4 text-secondary text-center md:text-left">
          Welcome.
          <span className="d-block">Let's Login.</span>
        </h2>
      </div>
      <div className="grid gap-y-4">
        <div className="login-btn">
          <GoogleOAuthProvider clientId={googleClientID}>
            {showloginButton ? (
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  const user = jwtDecode(credentialResponse.credential);
                  setUserInfo(user);
                  setShowloginButton(false);
                  checkLogin(user);
                  // setShowlogoutButton(true);
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            ) : null}

            {/* <div id='signINData'>
                        {userInfo && (
                        <div>
                            <img src={userInfo.picture}></img>
                            <h1>{userInfo.name}</h1>
                        </div>
                        )}
                    </div> */}
            {/* {showlogoutButton ?
                    <button></button>
                    googleLogout() : null
                } */}
          </GoogleOAuthProvider>
        </div>
        <hr className="hr-text" data-content="Or" />
        <Form onSubmit={handleLoginFormSubmit} className="relative grid">
          <Form.Group className="mb-6" controlId="userEmail">
            <Form.Control
              required
              type="email"
              placeholder="Enter email"
              value={loginForm.userEmail}
              onChange={(e) => updateLoginForm({ userEmail: e.target.value })}
              className="bg-primary w-80 border-0 border-b-2 border-secondary text-white placeholder:text-faded focus:outline-none"
            />
          </Form.Group>

          <Form.Group className="mb-6" controlId="userPassword">
            <Form.Control
              required
              type="password"
              placeholder="Password"
              autoComplete="true"
              value={loginForm.userPassword}
              onChange={(e) =>
                updateLoginForm({ userPassword: e.target.value })
              }
              className="bg-primary w-80 border-0 border-b-2 text-white border-secondary placeholder:text-faded focus:outline-none"
            />
          </Form.Group>
          <Form.Text className="text-center text-faded">
            We'll never share your email with anyone else.
          </Form.Text>
          <Button
            className="bg-secondary place-self-center w-20 border-2 border-secondary relative rounded-lg mb-2 font-bold text-white py-1 px-3 mt-3"
            variant="primary"
            type="submit"
          >
            Login
          </Button>
        </Form>
        <div className="place-self-center">
          <span className="text-faded">No account?</span>
          <Link to="/register" className="text-secondary ml-2">
            Let's Register.
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
